import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const CoursesListCard = ({ get_courses_endpoint }) => {
	const [
		courses,
		setCourses
	] = useState([]);

	useEffect(
		() => {
			const fetchCourses = async () => {
				const response = await fetch(get_courses_endpoint);
				if (response.ok) {
					const data = await response.json();
					setCourses(data.data.slice(0, 4) || []);
				}
			};

			fetchCourses();
		},
		[
			get_courses_endpoint
		]
	);

	return (
		<div className="bg-white border border-gray-200 shadow-lg rounded-xl">
			<div className="mx-4 py-4 border-b border-gray-200">
				<h2 className="text-lg font-bold text-gray-800">
					<i className="fa-solid fa-chalkboard-teacher text-lg mr-3" />
					<Link to="/courses" className="hover:underline">
						Courses
					</Link>
				</h2>
			</div>
			<ul className="divide-y divide-gray-200 px-4">
				{courses.length > 0 ? (
					courses.map((course) => (
						<li
							key={course.id}
							className="py-3 cursor-pointer flex justify-between items-center"
						>
							<Link
								to={`/courses/${course.id}`}
								className="block text-sm font-medium text-gray-800 truncate hover:underline"
							>
								{course.title}
							</Link>
						</li>
					))
				) : (
					<li>No courses available</li>
				)}
			</ul>
			<div className="mx-4 py-3 border-t border-gray-200 text-center">
				<Link
					to="/courses"
					className="text-sm text-blue-500 hover:underline"
				>
					See all courses →
				</Link>
			</div>
		</div>
	);
};

export default CoursesListCard;
