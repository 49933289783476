import React from 'react';
import GlobalErrorsBox from '../ErrorComponents/GlobalErrorBox/GlobalErrorsBox';
import FormSection from '../FormElements/FormSection';
import FormSubmit from '../FormElements/FormSubmit';
import Label from '../FormElements/Label';
import TextInput from '../FormElements/TextInput';
import PhoneNumberInput from '../FormElements/PhoneNumberInput';

const UserEditForm = ({
	formData,
	isSubmitted,
	closeModal,
	onChange,
	onSubmit,
	errorsList,
	fieldErrors,
	modalRef
}) => {
	return (
		<div
			className="fixed inset-0 z-50 overflow-auto flex w-full"
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
		>
			<div
				className="relative px-8 pt-2 pb-4 bg-white m-auto flex-col flex rounded-lg shadow-lg w-full max-w-md"
				ref={modalRef}
			>
				<button
					className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-200 transition duration-300"
					onClick={closeModal}
				>
					<svg
						className="h-6 w-6 text-gray-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
				<div className="py-4">
					<div className="justify-self-center w-full">
						{!isSubmitted && (
							<div className="text-2xl font-bold text-gray-800 pb-6">
								Edit your user profile
							</div>
						)}
						<GlobalErrorsBox globalErrors={errorsList} />
						{isSubmitted ? (
							<div className="flex flex-col items-center justify-center h-36">
								{/* Success Icon */}
								<i className="fa-regular fa-circle-check text-green-500 text-3xl" />
								{/* Success Message */}
								<h2 className="text-xl text-green-600">Saved.</h2>
							</div>
						) : (
							<FormSection>
								<Label htmlFor="first_name">First Name *</Label>
								<TextInput
									type="text"
									id="first_name"
									name="first_name"
									value={formData.first_name}
									onChange={onChange}
									fieldErrors={fieldErrors}
								/>
								<Label htmlFor="last_name">Last Name *</Label>
								<TextInput
									type="text"
									id="last_name"
									name="last_name"
									value={formData.last_name}
									onChange={onChange}
									fieldErrors={fieldErrors}
								/>
								<Label htmlFor="phone_number">Phone *</Label>
								<PhoneNumberInput
									id="phone_number"
									name="phone_number"
									value={formData.phone_number}
									onChange={onChange}
									fieldErrors={fieldErrors}
								/>
								{!isSubmitted && (
									<div className="grid">
										<div className="mt-4">
											<FormSubmit
												onSubmit={onSubmit}
												label={'Update profile'}
											/>
										</div>
									</div>
								)}
							</FormSection>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserEditForm;
