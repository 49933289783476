import React, { Fragment } from 'react';
import MobileDataTableCard from '../../../../../DataTables/MobileDataTableCard';
import AdvancedDataTable from '../../../../../DataTables/AdvancedDataTable';
import getFormattedNumberWithComma from '../../../../../../utils/getFormattedNumberWithComma';

export default function QuarterbackProBasicPassingStatsTable(props) {
	return (
		<div>
			<div className="text-xl py-2">
				<h4>{props.title}</h4>
			</div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={props.data}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Category',
								apiName: 'category',
								alignment: 'left'
							},
							{
								displayName: 'Games Played',
								apiName: 'pro_games_played',
								alignment: 'left'
							},
							{
								displayName: 'Completions',
								apiName: 'pro_pass_completions',
								alignment: 'left'
							},
							{
								displayName: 'Attempts',
								apiName: 'pro_pass_attempts',
								alignment: 'left'
							},
							{
								displayName: 'Comp %',
								apiName: 'pro_pass_completion_percentage',
								alignment: 'left'
							},
							{
								displayName: 'Passing Yards',
								apiName: 'pro_passing_yards',
								alignment: 'left'
							},
							{
								displayName: 'Passing TDs',
								apiName: 'pro_passing_touchdowns',
								alignment: 'left'
							},
							{
								displayName: 'INTs',
								apiName: 'pro_interceptions',
								alignment: 'left'
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div className="flex flex-wrap">
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[1].displayName}
									</div>
									<div className="w-1/2 mb-2">{row.pro_games_played}</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[2].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{getFormattedNumberWithComma(row.pro_pass_completions)}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[3].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{getFormattedNumberWithComma(row.pro_pass_attempts)}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[4].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_pass_completion_percentage}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[5].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{getFormattedNumberWithComma(row.pro_passing_yards)}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[6].displayName}
									</div>
									<div className="w-1/2 mb-2">{row.pro_passing_touchdowns}</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[7].displayName}
									</div>
									<div className="w-1/2">{row.pro_interceptions}</div>
								</div>
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={props.data}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					columns={[
						{
							displayName: 'Category',
							apiName: 'category',
							alignment: 'left'
						},
						{
							displayName: 'Games Played',
							apiName: 'pro_games_played',
							alignment: 'left'
						},
						{
							displayName: 'Completions',
							apiName: 'pro_pass_completions',
							alignment: 'left'
						},
						{
							displayName: 'Attempts',
							apiName: 'pro_pass_attempts',
							alignment: 'left'
						},
						{
							displayName: 'Comp %',
							apiName: 'pro_pass_completion_percentage',
							alignment: 'left'
						},
						{
							displayName: 'Passing Yards',
							apiName: 'pro_passing_yards',
							alignment: 'left'
						},
						{
							displayName: 'Passing TDs',
							apiName: 'pro_passing_touchdowns',
							alignment: 'left'
						},
						{
							displayName: 'INTs',
							apiName: 'pro_interceptions',
							alignment: 'left'
						}
					]}
					rowRenderer={(row) => {
						return (
							<Fragment>
								<tr key={row.id} className="bg-white border-t text-gray-800">
									<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10">
										2024
									</td>
									<td className="pl-8 py-3 text-left">
										{row.current_year_pro_games_played ? (
											row.current_year_pro_games_played
										) : (
											0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{getFormattedNumberWithComma(
											row.current_year_pro_pass_completions
												? row.current_year_pro_pass_completions
												: 0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{getFormattedNumberWithComma(
											row.current_year_pro_pass_attempts
												? row.current_year_pro_pass_attempts
												: 0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{row.current_year_pro_pass_completion_percentage ? (
											row.current_year_pro_pass_completion_percentage
										) : (
											(0.0).toFixed(1)
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{getFormattedNumberWithComma(
											row.current_year_pro_passing_yards
												? row.current_year_pro_passing_yards
												: 0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{row.current_year_pro_passing_touchdowns ? (
											row.current_year_pro_passing_touchdowns
										) : (
											0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{row.current_year_pro_interceptions ? (
											row.current_year_pro_interceptions
										) : (
											0
										)}
									</td>
								</tr>
								<tr key={row.id} className="bg-white border-t text-gray-800">
									<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10">
										Career
									</td>
									<td className="pl-8 py-3 text-left">
										{row.pro_games_played ? row.pro_games_played : 0}
									</td>
									<td className="pl-8 py-3 text-left">
										{getFormattedNumberWithComma(
											row.pro_pass_completions ? row.pro_pass_completions : 0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{getFormattedNumberWithComma(
											row.pro_pass_attempts ? row.pro_pass_attempts : 0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{row.pro_pass_completion_percentage ? (
											row.pro_pass_completion_percentage
										) : (
											0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{getFormattedNumberWithComma(
											row.pro_passing_yards ? row.pro_passing_yards : 0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{row.pro_passing_touchdowns ? (
											row.pro_passing_touchdowns
										) : (
											0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{row.pro_interceptions ? row.pro_interceptions : 0}
									</td>
								</tr>
							</Fragment>
						);
					}}
				/>
			</div>
		</div>
	);
}
