import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import ContentDisplay from '../CourseContentDisplay/CourseContentDisplay';
import getDifficultyLevel from '../../../utils/getDifficultyLevel';

export default function CourseProfileCard({ get_course_endpoint }) {
	const { id: paramId } = useParams();
	const [
		course,
		setCourse
	] = useState(null);
	const [
		isCourseContentVisible,
		setCourseContentVisible
	] = useState(true);
	const [
		openSections,
		setOpenSections
	] = useState({});
	const [
		selectedContent,
		setSelectedContent
	] = useState('');
	const id = (course && course.id) || paramId;

	useEffect(() => {
		fetchCourse();
	}, []);

	const fetchCourse = () => {
		fetch(get_course_endpoint.replace('id', id))
			.then((response) => response.json())
			.then((result) => {
				const courseData = result.data || null;
				setCourse(courseData);
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});

				if (
					courseData &&
					courseData.course_sections &&
					courseData.course_sections.length > 0
				) {
					const firstSection = courseData.course_sections[0];

					// Automatically open the first section
					setOpenSections({ [firstSection.id]: true });

					// Set the first content item URL by default (if available)
					if (
						firstSection.content_items &&
						firstSection.content_items.length > 0
					) {
						setSelectedContent(firstSection.content_items[0]);
					}
				}
			})
			.catch((error) => {
				console.error('Error fetching course:', error);
			});
	};

	const handleSectionClick = (section) => {
		setOpenSections((prevOpenSections) => ({
			...prevOpenSections,
			[section.id]: !prevOpenSections[section.id] // Toggle the accordion open/close
		}));
	};

	const handleContentItemClick = (content_item) => {
		setSelectedContent(content_item);
	};

	const handleCloseContent = () => {
		setCourseContentVisible(false);
	};

	const handleOpenContent = () => {
		setCourseContentVisible(true);
	};

	const [
		isHovered,
		setIsHovered
	] = useState(false);

	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	const handlePreviousContent = () => {
		if (course && course.course_sections && course.course_sections.length > 0) {
			for (const section of course.course_sections) {
				const currentIndex = section.content_items.findIndex(
					(item) => item.id === selectedContent.id
				);

				if (currentIndex > 0) {
					setSelectedContent(section.content_items[currentIndex - 1]);
					return;
				} else if (currentIndex === 0) {
					// Move to the last item of the previous section
					const previousSectionIndex =
						course.course_sections.findIndex((s) => s.id === section.id) - 1;

					if (previousSectionIndex >= 0) {
						const previousSection =
							course.course_sections[previousSectionIndex];

						// Close all other sections and open the previous one
						setOpenSections(() => ({
							[previousSection.id]: true
						}));

						setSelectedContent(
							previousSection.content_items[
								previousSection.content_items.length - 1
							]
						);
					}
					return;
				}
			}
		}
	};

	const handleNextContent = () => {
		if (course && course.course_sections && course.course_sections.length > 0) {
			for (const section of course.course_sections) {
				const currentIndex = section.content_items.findIndex(
					(item) => item.id === selectedContent.id
				);

				if (
					currentIndex >= 0 &&
					currentIndex < section.content_items.length - 1
				) {
					setSelectedContent(section.content_items[currentIndex + 1]);
					return;
				} else if (currentIndex === section.content_items.length - 1) {
					// Move to the first item of the next section
					const nextSectionIndex =
						course.course_sections.findIndex((s) => s.id === section.id) + 1;

					if (nextSectionIndex < course.course_sections.length) {
						const nextSection = course.course_sections[nextSectionIndex];

						// Close all other sections and open the next one
						setOpenSections(() => ({
							[nextSection.id]: true
						}));

						setSelectedContent(nextSection.content_items[0]);
					}
					return;
				}
			}
		}
	};

	return (
		<Fragment>
			<div className="mx-auto">
				<div className="grid grid-cols-12 relative">
					{/* Left Column: Course Title, Section Content */}
					<div
						className={`col-span-12 ${isCourseContentVisible
							? 'md:col-span-9'
							: 'md:col-span-12'}`}
					>
						{/* Dynamic Section Content Area */}
						<div
							className="bg-white shadow-inner border-b relative"
							style={{
								minHeight: 'calc(100vh - 20rem)',
								display: 'flex',
								flexDirection: 'column'
							}}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
						>
							{selectedContent && (
								<div className="flex-grow flex items-center justify-center relative">
									{/* Previous Button */}
									<button
										onClick={() => handlePreviousContent()}
										className={`absolute top-1/2 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-10 border border-gray-300 transition-opacity duration-300 ${isHovered
											? 'md:opacity-100'
											: 'md:opacity-0'} block`} // always block on small screens, hidden on larger
										style={{ left: '-7px' }}
									>
										{'<'}
									</button>

									{/* Content Display */}
									<ContentDisplay
										selectedContent={selectedContent}
										course_id={id}
									/>

									{/* Next Button */}
									<button
										onClick={() => handleNextContent()}
										className={`absolute top-1/2 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-10 border border-gray-300 transition-opacity duration-300 ${isHovered
											? 'md:opacity-100'
											: 'md:opacity-0'} block`} // always block on small screens, hidden on larger
										style={{ right: '-7px' }}
									>
										{'>'}
									</button>
								</div>
							)}
						</div>

						<div className="container">
							{course && (
								<Fragment>
									<h1 className="text-2xl md:text-4xl font-semibold mt-6 mb-3 lg:mb-6 px-6 lg:px-12">
										{course.title}
									</h1>

									<div className="px-6 lg:px-12 mb-4 space-y-4">
										<div className="flex flex-col md:flex-row md:space-x-10 space-y-2 md:space-y-0">
											{course.level !== undefined && (
												<p className="flex items-center text-gray-700">
													<i className="fa-solid fa-stairs mr-2" />
													<span className="pr-2">Difficulty level:</span>
													<span>{getDifficultyLevel(course.level)}</span>
												</p>
											)}
											{course.total_duration && (
												<p className="flex items-center text-gray-700">
													<i className="fa-solid fa-clock mr-2" />
													<span>
														Total duration: {course.total_duration} minutes
													</span>
												</p>
											)}
											{course.updated_at && (
												<p className="flex items-center text-gray-700">
													<i className="fa-solid fa-circle-exclamation mr-2" />
													<span>
														Last updated:{' '}
														{new Date(
															course.updated_at
														).toLocaleDateString('en-US', {
															year: 'numeric',
															month: 'long',
															day: 'numeric'
														})}
													</span>
												</p>
											)}
										</div>
									</div>

									{/* Black delineation line */}
									<hr className="border-gray-10 my-6 mx-8" />

									{/* Description Row */}
									<div className="px-6 lg:px-12 mb-4">
										{course.description && (
											<div className="flex items-start text-gray-700">
												<div className="flex-shrink-0 flex items-center">
													<i className="fas fa-book-open mr-2 mt-1" />
												</div>
												<p className="text-gray-700">
													<span className="mr-2">Description:</span>
													{course.description}
												</p>
											</div>
										)}
									</div>
								</Fragment>
							)}
						</div>
					</div>

					{/* Right Column: Flyout Section (Course Content List) */}
					{isCourseContentVisible && (
						<div className="col-span-12 md:col-span-3">
							<div className="bg-gray-50 border-l border-r mb-6 h-full relative">
								{/* Close Button */}
								<button
									className="absolute top-4 right-5 text-gray-600 hover:text-gray-800"
									onClick={handleCloseContent}
								>
									<i className="fa-solid fa-x" /> {/* Font Awesome "X" icon */}
								</button>

								<div className="flex justify-between mb-4">
									<h2 className="pt-4 pl-5 text-lg font-bold text-gray-800">
										Course content
									</h2>
								</div>

								{/* Course sections list with accordions */}
								<div className="border-gray-300 border-t">
									{course &&
									course.course_sections &&
									course.course_sections.length > 0 ? (
										course.course_sections.map((section) => (
											<div key={section.id}>
												{/* Accordion Header */}
												<button
													className="w-full text-left py-4 border-b border-gray-300 flex pl-5 items-center justify-between text-black hover:text-black transition"
													onClick={() => handleSectionClick(section)}
												>
													{section.title}
													{/* Chevron Icon */}
													<i
														className={`pr-5 fa-solid ${openSections[section.id]
															? 'fa-chevron-up'
															: 'fa-chevron-down'}`}
													/>
												</button>
												{/* Accordion Content */}
												{openSections[section.id] && (
													<div className="p-2 bg-gray-100">
														{/* Displaying content items */}
														{section.content_items &&
														section.content_items.length > 0 ? (
															<ol className="list-decimal pl-8">
																{section.content_items.map((item) => (
																	<div key={item.id}>
																		<li
																			className={`text-gray-700 cursor-pointer py-3 ${selectedContent.id ===
																			item.id
																				? 'font-bold'
																				: ''}`}
																			onClick={() =>
																				handleContentItemClick(item)}
																		>
																			{item.title}
																		</li>
																	</div>
																))}
															</ol>
														) : (
															<p className="text-gray-600">
																No content available for this section.
															</p>
														)}
													</div>
												)}
											</div>
										))
									) : (
										<p className="text-gray-600">No sections available.</p>
									)}
								</div>
							</div>
						</div>
					)}

					{/* Open Button when content panel is closed */}
					{!isCourseContentVisible && (
						<div className="absolute top-7 right-0 z-50 flex flex-col items-center space-y-2">
							<button
								className="bg-white hover:bg-gray-100 text-gray-800 shadow-md border border-gray-300 p-4 rounded-l-lg"
								onClick={handleOpenContent}
							>
								<i className="fa-solid fa-chevron-left" />{' '}
								{/* Font Awesome "Caret Left" icon */}
							</button>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
}
