export default function getInjuryStatusColor(status) {
	if (!status) return '#000000'; // Default: Black for unknown status

	const statusLower = status.toLowerCase();

	if (statusLower.includes('healthy') || statusLower === 'active') {
		return '#1ABE10'; // Bright green
	} else if (statusLower.includes('probable')) {
		return '#7DD220'; // Light green
	} else if (statusLower.includes('questionable')) {
		return '#EDCC2E'; // Yellow-orange
	} else if (statusLower.includes('doubtful')) {
		return '#E57820'; // Orange
	} else if (statusLower.includes('out') || statusLower.includes('inactive')) {
		return '#DC2413'; // Bright red
	} else if (statusLower.includes('ir')) {
		return '#8B1A10'; // Dark crimson
	} else if (statusLower.includes('suspended')) {
		return '#333333'; // Dark gray
	}

	return '#000000'; // Default fallback
}
