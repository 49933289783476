import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

function YahooCallbackPage() {
	const navigate = useNavigate();

	useEffect(
		() => {
			// Extract query parameters
			const params = new URLSearchParams(window.location.search);
			const code = params.get('code'); // Authorization code from the query string
			const state = params.get('state'); // Get the state parameter

			// Log the URL parameters
			console.log('URL Search Params:', window.location.search);
			console.log('Code:', code);
			console.log('State:', state);

			// Find the rosterId in the state string after '?rosterId='
			const rosterIdMatch = state && state.match(/rosterId=(\d+)/);

			// Log the matched result
			console.log('Roster ID Match:', rosterIdMatch);

			// First, check if the code is present and send it to the backend to exchange for a token
			if (code) {
				console.log('Code received for token exchange:', code);

				// Csrf token
				const csrfToken = document.querySelector('meta[name="csrf-token"]')
					.content;
				console.log('CSRF Token:', csrfToken);

				// Send the code to the back-end to exchange it for an access token
				fetch('/api/v1/yahoo/token', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'X-CSRF-Token': csrfToken
					},
					body: JSON.stringify({ code: code }) // Send the code to Rails API
				})
					.then((response) => response.json())
					.then((data) => {
						console.log('Token received from backend:', data);
						// Here you would store the token or any other relevant data

						// Now, check for the rosterId and navigate after saving the token
						if (rosterIdMatch) {
							const rosterId = rosterIdMatch[1]; // Extract the rosterId from the match
							console.log('Redirecting to /rosters/' + rosterId); // Log the redirect action
							navigate(`/rosters/${rosterId}?yahooSync=true`); // Redirect to the roster page
						}
					})
					.catch((error) => {
						console.error('Error fetching token:', error);
					});
			} else {
				console.error('No code found.');
			}
		},
		[
			navigate
		]
	);

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<LoadingSpinner />
		</div>
	);
}

export default YahooCallbackPage;
