import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import getRosterPlatformUrl from '../../../utils/getRosterPlatformUrl';
import AdvancedDataTable from '../../DataTables/AdvancedDataTable';
import MobileDataTableCard from '../../DataTables/MobileDataTableCard';
import RosterFormModal from '../../Roster/RosterFormModal/RosterFormModal';
import PricingTable from '../../PricingTable/PricingTable';
import FilterByPlatform from '../../Filters/FilterByPlatform';
import FilterByScoringType from '../../Filters/FilterByScoringType';
import FilterByFormat from '../../Filters/FilterByFormat';
import FilterBySearchInput from '../../Filters/FilterBySearchInput';
import ClearFiltersButton from '../../Filters/ClearFilterButton/ClearFiltersButton';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

const RostersIndexCard = ({
	get_rosters_by_user_endpoint,
	create_roster_by_user_endpoint,
	update_roster_by_user_endpoint,
	delete_roster_by_user_endpoint,
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) => {
	const { user } = useAuth();
	const { subscription } = useAuth();
	const navigate = useNavigate();
	const modalRef = useRef();
	const [
		rosters,
		setRosters
	] = useState([]);
	const [
		isCreateRosterModalOpen,
		setIsCreateRosterModalOpen
	] = useState(false);
	const [
		isDeleteRosterModalOpen,
		setIsDeleteRosterModalOpen
	] = useState(false);
	const [
		rosterToDelete,
		setRosterToDelete
	] = useState(null);
	const [
		editingRoster,
		setEditingRoster
	] = useState(null);
	const [
		rosterTeamName,
		setRosterTeamName
	] = useState('');
	const [
		rosterLeagueName,
		setRosterLeagueName
	] = useState('');
	const [
		rosterPlatform,
		setRosterPlatform
	] = useState('');
	const [
		rosterUsername,
		setRosterUsername
	] = useState('');
	const [
		rosterUrl,
		setRosterUrl
	] = useState('');
	const [
		rosterFormat,
		setRosterFormat
	] = useState('');
	const [
		rosterScoring,
		setRosterScoring
	] = useState('');
	const [
		additionalEmails,
		setAdditionalEmails
	] = useState([
		user.email
	]);
	const [
		errors,
		setErrors
	] = useState({});

	// Filter and sort state variables
	const [
		filterByPlatform,
		setFilterByPlatform
	] = useState({ platform: '' });
	const [
		filterByScoringType,
		setFilterByScoringType
	] = useState({ scoringType: '' });
	const [
		filterByFormat,
		setFilterByFormat
	] = useState({ format: '' });
	const [
		sortBy,
		setSortBy
	] = useState('format');
	const [
		sortOrder,
		setSortOrder
	] = useState('asc');
	const [
		search,
		setSearch
	] = useState('');

	useEffect(
		() => {
			fetchRostersByUser();
		},
		[
			filterByPlatform,
			filterByScoringType,
			filterByFormat,
			sortBy,
			sortOrder,
			search
		]
	);

	const clearErrors = () => {
		setErrors({});
	};

	// Pricing Table
	const [
		isPricingTableModalOpen,
		setIsPricingTableModalOpen
	] = useState(false);

	const fetchRostersByUser = () => {
		window.scrollTo(0, 0); // Scroll to the top of the window when the component mounts

		if (get_rosters_by_user_endpoint && user) {
			let endpoint = get_rosters_by_user_endpoint.replace('user_id', user.id);
			const params = new URLSearchParams({
				sortBy: sortBy,
				sortOrder: sortOrder,
				platform: filterByPlatform.platform || '',
				scoringType: filterByScoringType.scoringType || '',
				format: filterByFormat.format || '',
				query: search || ''
			});
			endpoint += `?${params.toString()}`;

			fetch(endpoint)
				.then((response) => response.json())
				.then((data) => {
					setRosters(data.data || []);
				})
				.catch((error) => {
					console.error('Error fetching rosters:', error);
				});
		}
	};

	const checkRosterCreationEligibility = () => {
		if (!subscription || Object.keys(subscription).length === 0) {
			setIsPricingTableModalOpen(true);
			setIsCreateRosterModalOpen(false);
			setIsDeleteRosterModalOpen(false);
			return;
		} else if (subscription && subscription.status !== 'active') {
			setIsPricingTableModalOpen(true);
			setIsCreateRosterModalOpen(false);
			setIsDeleteRosterModalOpen(false);
			return;
		} else if (
			rosters &&
			rosters.length >= 5 &&
			subscription &&
			subscription.status === 'active' &&
			subscription.product_name === 'Essential Fantasy Fitness'
		) {
			setIsPricingTableModalOpen(true);
			setIsCreateRosterModalOpen(false);
			return;
		} else if (
			rosters &&
			rosters.length >= 20 &&
			subscription &&
			subscription.status === 'active' &&
			subscription.product_name === 'Advanced Fantasy Fitness'
		) {
			setIsPricingTableModalOpen(true);
			setIsCreateRosterModalOpen(false);
			return;
		} else if (
			rosters &&
			rosters.length >= 100 &&
			subscription &&
			subscription.status === 'active' &&
			subscription.product_name === 'Ultimate Fantasy Fitness'
		) {
			setIsPricingTableModalOpen(true);
			setIsCreateRosterModalOpen(false);
			return;
		} else return;
	};

	const checkRosterEditEligibility = () => {
		if (!subscription || Object.keys(subscription).length === 0) {
			setIsPricingTableModalOpen(true);
			setIsCreateRosterModalOpen(false); // Need to be renamed to setIsEditRosterModalOpen as this is more accepetable for create and edit
			setIsDeleteRosterModalOpen(false);
			return;
		} else if (subscription && subscription.status !== 'active') {
			setIsPricingTableModalOpen(true);
			setIsCreateRosterModalOpen(false); // Need to be renamed to setIsEditRosterModalOpen as this is more accepetable for create and edit
			setIsDeleteRosterModalOpen(false);
			return;
		} else if (
			subscription &&
			subscription.status === 'active' &&
			subscription.product_name === 'Essential Fantasy Fitness'
		) {
			setIsCreateRosterModalOpen(true);
			return;
		} else if (
			subscription &&
			subscription.status === 'active' &&
			subscription.product_name === 'Advanced Fantasy Fitness'
		) {
			setIsCreateRosterModalOpen(true);
			return;
		} else if (
			subscription &&
			subscription.status === 'active' &&
			subscription.product_name === 'Ultimate Fantasy Fitness'
		) {
			setIsCreateRosterModalOpen(true);
			return;
		} else return;
	};

	const checkRosterDeleteEligibility = () => {
		if (!subscription || Object.keys(subscription).length === 0) {
			setIsPricingTableModalOpen(true);
			setIsCreateRosterModalOpen(false); // Need to be renamed to setIsEditRosterModalOpen as this is more accepetable for create and edit
			setIsDeleteRosterModalOpen(false);
			return;
		} else if (subscription && subscription.status !== 'active') {
			setIsPricingTableModalOpen(true);
			setIsCreateRosterModalOpen(false); // Need to be renamed to setIsEditRosterModalOpen as this is more accepetable for create and edit
			setIsDeleteRosterModalOpen(false);
			return;
		} else if (
			subscription &&
			subscription.status === 'active' &&
			subscription.product_name === 'Essential Fantasy Fitness'
		) {
			setIsDeleteRosterModalOpen(true);
			return;
		} else if (
			subscription &&
			subscription.status === 'active' &&
			subscription.product_name === 'Advanced Fantasy Fitness'
		) {
			setIsDeleteRosterModalOpen(true);
			return;
		} else if (
			subscription &&
			subscription.status === 'active' &&
			subscription.product_name === 'Ultimate Fantasy Fitness'
		) {
			setIsDeleteRosterModalOpen(true);
			return;
		} else return;
	};

	const handleEditClick = (roster) => {
		setEditingRoster(roster);
		setRosterTeamName(roster.team_name);
		setRosterLeagueName(roster.league_name);
		setRosterPlatform(roster.platform);
		setRosterUsername(roster.username);
		setRosterUrl(roster.roster_url);
		setRosterFormat(roster.format);
		setRosterScoring(roster.scoring);
		setAdditionalEmails(roster.users.map((user) => user.email));
		setIsCreateRosterModalOpen(true);
		checkRosterEditEligibility(); // Hack: Setting at end to ensure it runs last but I don't like this and think it should be refactored
	};

	const handleCreateClick = () => {
		setEditingRoster(null); // Reset editing state
		setIsCreateRosterModalOpen(true);
		setRosterTeamName('');
		setRosterLeagueName('');
		setRosterPlatform('');
		setRosterUsername('');
		setRosterUrl('');
		setRosterFormat('');
		setRosterScoring('');
		setAdditionalEmails([
			user.email
		]);
		checkRosterCreationEligibility(); // Hack: Setting at end to ensure it runs last but I don't like this and think it should be refactored
	};

	const closeModal = () => {
		setIsCreateRosterModalOpen(false);
		setIsDeleteRosterModalOpen(false);
		setRosterToDelete(null);
		setEditingRoster(null);
	};

	const handleClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			closeModal();
		}
	};

	useEffect(
		() => {
			if (isCreateRosterModalOpen || isDeleteRosterModalOpen) {
				document.addEventListener('mousedown', handleClickOutside);
			} else {
				document.removeEventListener('mousedown', handleClickOutside);
			}
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		},
		[
			isCreateRosterModalOpen,
			isDeleteRosterModalOpen
		]
	);

	const handleSaveRoster = (navigateToNewRoster = false) => {
		const payload = {
			user_id: user.id,
			team_name: rosterTeamName,
			league_name: rosterLeagueName,
			platform: rosterPlatform,
			username: rosterUsername,
			roster_url: rosterUrl,
			format: rosterFormat,
			scoring: rosterScoring,
			emails: additionalEmails,
			filter_by_platform: filterByPlatform.platform,
			filter_by_scoring_type: filterByScoringType.scoringType,
			filter_by_format: filterByFormat.format,
			sort_by: sortBy,
			sort_order: sortOrder
		};

		const endpoint = editingRoster
			? update_roster_by_user_endpoint
					.replace('user_id', user.id)
					.replace('roster_id', editingRoster.id)
			: create_roster_by_user_endpoint;

		const method = editingRoster ? 'PUT' : 'POST';

		fetch(endpoint, {
			method: method,
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(payload)
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setRosters(data.data);
					setErrors({});
					// console.log(
					// 	editingRoster ? 'Roster updated:' : 'Roster created:',
					// 	data.data
					// );
					if (navigateToNewRoster) {
						const newOrUpdatedRosterId = data.new_or_updated_roster.id;
						navigate(`/rosters/${newOrUpdatedRosterId}`);
					}
					fetchRostersByUser();
					closeModal();
				} else {
					console.error(
						editingRoster
							? 'Failed to update roster:'
							: 'Failed to create roster:',
						data.message
					);
					setErrors(data.field_errors || {});
				}
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	const handleDeleteRoster = () => {
		const endpoint = delete_roster_by_user_endpoint
			.replace('user_id', user.id)
			.replace('roster_id', rosterToDelete.id);

		const queryParams = new URLSearchParams({
			filter_by_platform: filterByPlatform.platform,
			filter_by_scoring_type: filterByScoringType.scoringType,
			filter_by_format: filterByFormat.format,
			sort_by: sortBy,
			sort_order: sortOrder
		});

		fetch(`${endpoint}?${queryParams.toString()}`, {
			method: 'DELETE'
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					setRosters(data.data);
					fetchRostersByUser();
					closeModal();
				} else {
					console.error('Failed to delete roster:', data.message);
				}
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	const confirmDeleteRoster = (roster) => {
		setRosterToDelete(roster);
		setIsDeleteRosterModalOpen(true);
		checkRosterDeleteEligibility(); // Hack: Setting at end to ensure it runs last but I don't like this anf think it should be refactored
	};

	const handleEmailChange = (index, value) => {
		const updatedEmails = [
			...additionalEmails
		];
		updatedEmails[index] = value;
		setAdditionalEmails(updatedEmails);
	};

	const handleAddEmail = () => {
		setAdditionalEmails([
			...additionalEmails,
			''
		]);
	};

	const handleRemoveEmail = (index) => {
		const updatedEmails = [
			...additionalEmails
		];
		updatedEmails.splice(index, 1);
		setAdditionalEmails(updatedEmails);
	};

	const closePricingTableModal = () => {
		setIsCreateRosterModalOpen(false);
		setIsPricingTableModalOpen(false);
	};

	const closePricingTableModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsCreateRosterModalOpen(false);
			setIsPricingTableModalOpen(false);
		}
	};

	useEffect(
		() => {
			if (isPricingTableModalOpen) {
				document.addEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			};
		},
		[
			isPricingTableModalOpen
		]
	);

	// Filter and sort state variables
	const handleFilterChange = (filterKey, value) => {
		switch (filterKey) {
			case 'format':
				setFilterByFormat({ format: value });
				break;
			case 'platform':
				setFilterByPlatform({ platform: value });
				break;
			case 'scoringType':
				setFilterByScoringType({ scoringType: value });
				break;
			default:
				break;
		}
	};

	const handleSearchInputChange = (event) => {
		setSearch(event.target.value);
		fetchRostersByUser();
	};

	const handleSortOrderChange = (sortColumn, sortOrder) => {
		if (sortOrder === '' || sortOrder === 'desc') {
			setSortBy(sortColumn);
			setSortOrder('asc');
		} else if (sortOrder === 'asc') {
			setSortBy(sortColumn);
			setSortOrder('desc');
		}
	};

	const handleClearFilters = () => {
		setFilterByPlatform({ platform: '' });
		setFilterByScoringType({ scoringType: '' });
		setFilterByFormat({ format: '' });
		setSearch('');
	};

	if (!rosters) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<div>
			<div className="flex flex-col lg:flex-row lg:mb-6">
				<div className="md:mr-6">
					<FilterByFormat
						filter={filterByFormat}
						onChange={(filterKey, value) =>
							handleFilterChange(filterKey, value)}
					/>
				</div>
				<div className="md:mr-6">
					<FilterByPlatform
						filter={filterByPlatform}
						onChange={(filterKey, value) =>
							handleFilterChange(filterKey, value)}
					/>
				</div>
				<div className="md:mr-6">
					<FilterByScoringType
						filter={filterByScoringType}
						onChange={(filterKey, value) =>
							handleFilterChange(filterKey, value)}
					/>
				</div>
				<div className="md:mr-6">
					<FilterBySearchInput
						label="Filter By Team or League"
						value={search}
						placeholder="Search for a team or league"
						onChange={handleSearchInputChange}
					/>
				</div>
				<div className="hidden lg:contents">
					<ClearFiltersButton onClick={handleClearFilters} />
				</div>
			</div>
			<div className="p-6 lg:p-8 border border-gray-300 rounded-xl shadow-lg bg-white">
				{rosters && rosters.length === 0 ? (
					<div className="text-center">
						<p>No results found. Create a new one to get started.</p>
						<button
							className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
							onClick={handleCreateClick}
						>
							Create roster
						</button>
					</div>
				) : (
					<Fragment>
						<div className="contents lg:hidden">
							<MobileDataTableCard
								data={rosters}
								style="text-gray-800 border-b border-gray-300 font-semibold"
								rowRenderer={(row) => {
									const labels = [
										{
											displayName: 'Team',
											apiName: 'team_name',
											alignment: 'left'
										}
									];
									return (
										<div
											key={`${row.id} - RostersIndexCard - MobileDataTableCard`}
											className="p-5 bg-white border-t text-gray-800"
										>
											<div className="flex justify-between items-center">
												<Link
													to={`/rosters/${row.id}`}
													className="text-blue-500 hover:text-blue-700"
												>
													<div
														style={{ display: 'flex', alignItems: 'center' }}
													>
														{`${row.team_name}`}
													</div>
												</Link>
												<div className="w-20">
													<button
														className="text-sm text-blue-500 hover:underline mr-4"
														onClick={() => handleEditClick(row)}
													>
														Edit
													</button>
													<button
														className="text-sm text-blue-500 hover:underline"
														onClick={() => confirmDeleteRoster(row)}
													>
														Delete
													</button>
												</div>
											</div>
										</div>
									);
								}}
							/>
						</div>
						<div className="hidden lg:contents">
							<AdvancedDataTable
								data={rosters}
								style="text-gray-800 border-b border-gray-300 font-semibold"
								sortOrder={sortOrder}
								sortColumn={sortBy ? sortBy : ''}
								onChangeSortOrder={handleSortOrderChange}
								sortingEnabled={true}
								columns={[
									{
										displayName: 'Team',
										apiName: 'team_name',
										alignment: 'left'
									},
									{
										displayName: 'League',
										apiName: 'league_name',
										alignment: 'left'
									},
									{
										displayName: 'Platform',
										apiName: 'platform',
										alignment: 'left'
									},
									{
										displayName: 'Format',
										apiName: 'format',
										alignment: 'left'
									},
									{
										displayName: 'Scoring',
										apiName: 'scoring',
										alignment: 'left'
									},
									{ displayName: 'Actions', alignment: 'right' }
								]}
								rowRenderer={(row) => (
									<tr key={row.id} className="bg-white border-t">
										<td className="pl-8 py-3 text-left">
											<Link
												className="text-blue-500 hover:underline cursor:pointer"
												to={`/rosters/${row.id}`}
											>
												{row.team_name}
											</Link>
										</td>
										<td className="pl-8 py-3 text-left">{row.league_name}</td>
										<td className="pl-8 py-3 text-left">
											<a
												className="text-blue-500 hover:underline"
												href={getRosterPlatformUrl(
													row.platform,
													row.roster_url
												)}
												target="_blank"
												rel="noreferrer"
											>
												{row.platform}
												<i className="fa-solid fa-arrow-up-right-from-square ml-2 text-blue-500" />
											</a>
										</td>
										<td className="pl-8 py-3 text-left">{row.format}</td>
										<td className="pl-8 py-3 text-left">{row.scoring}</td>
										<td className="pl-8 py-3 text-right">
											<button
												className="text-sm text-blue-500 hover:underline mr-4"
												onClick={() => handleEditClick(row)}
											>
												Edit
											</button>
											<button
												className="text-sm text-blue-500 hover:underline mr-4"
												onClick={() => confirmDeleteRoster(row)}
											>
												Delete
											</button>
										</td>
									</tr>
								)}
							/>
						</div>
						<div className="text-center my-6 lg:mb-0">
							<button
								className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
								onClick={handleCreateClick}
							>
								Create roster
							</button>
						</div>
					</Fragment>
				)}

				{/* Create/Edit Roster Modal */}
				{isCreateRosterModalOpen && (
					<RosterFormModal
						isOpen={isCreateRosterModalOpen}
						closeModal={closeModal}
						editingRoster={editingRoster}
						handleSaveRoster={handleSaveRoster}
						rosterTeamName={rosterTeamName}
						setRosterTeamName={setRosterTeamName}
						rosterLeagueName={rosterLeagueName}
						setRosterLeagueName={setRosterLeagueName}
						rosterPlatform={rosterPlatform}
						setRosterPlatform={setRosterPlatform}
						rosterUsername={rosterUsername}
						setRosterUsername={setRosterUsername}
						rosterUrl={rosterUrl}
						setRosterUrl={setRosterUrl}
						rosterFormat={rosterFormat}
						setRosterFormat={setRosterFormat}
						rosterScoring={rosterScoring}
						setRosterScoring={setRosterScoring}
						additionalEmails={additionalEmails}
						handleEmailChange={handleEmailChange}
						handleAddEmail={handleAddEmail}
						handleRemoveEmail={handleRemoveEmail}
						errors={errors}
						clearErrors={clearErrors}
					/>
				)}

				{/* Delete Roster Modal */}
				{isDeleteRosterModalOpen && (
					<div
						className="fixed inset-0 z-50 overflow-auto flex w-full"
						style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
					>
						<div
							className="relative p-8 bg-white m-auto flex-col flex rounded-lg"
							ref={modalRef}
						>
							<p className="mb-4">
								Are you sure you want to delete the roster, "{rosterToDelete.team_name}"?
							</p>
							<div className="flex justify-end">
								<button
									className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 mr-4"
									onClick={closeModal}
								>
									Cancel
								</button>
								<button
									className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
									onClick={handleDeleteRoster}
								>
									Yes, delete
								</button>
							</div>
						</div>
					</div>
				)}

				{/* Pricing Table Modal */}
				{isPricingTableModalOpen && (
					<div
						className="fixed inset-0 z-50 flex justify-center items-center"
						style={{
							backgroundColor: 'rgba(0, 0, 0, 0.5)'
						}}
					>
						<div
							ref={modalRef}
							className="relative bg-white rounded-lg p-6 shadow-lg"
							style={{
								width: 'auto',
								height: 'auto',
								maxHeight: '90%',
								overflowY: 'auto'
							}}
						>
							<button
								className="absolute top-0 right-0 p-4"
								onClick={closePricingTableModal}
							>
								<svg
									className="h-6 w-6 text-gray-500"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
							<h2 className="text-2xl font-bold mb-4">Our Pricing</h2>
							<div className="overflow-x-auto">
								<div className="flex flex-col block" style={{ height: '60%' }}>
									<PricingTable
										create_checkout_session_endpoint={
											create_checkout_session_endpoint
										}
										stripe_public_key={stripe_public_key}
										stripe_product_essential_id={stripe_product_essential_id}
										stripe_product_advanced_id={stripe_product_advanced_id}
										stripe_product_ultimate_id={stripe_product_ultimate_id}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default RostersIndexCard;
