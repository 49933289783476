import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { Fragment } from 'react/cjs/react.production.min';
import PlayerSearchBar from '../../SearchBars/PlayerSearchBar/PlayerSearchBar';
import PlayerProfileCard from '../../Player/PlayerProfileCard/PlayerProfileCard';
import TeamProfileCard from '../../Team/TeamProfileCard/TeamProfileCard';
import RosterPlayerCard from '../RosterPlayerCard/RosterPlayerCard';
import RosterFormModal from '../RosterFormModal/RosterFormModal';
import RosterPlayerNoteModal from '../RosterPlayerNoteModal/RosterPlayerNoteModal';
import RosterPortraitCard from '../RosterPortraitCard/RosterPortraitCard';
import FixedRosterBanner from '../FixedRosterBanner/FixedRosterBanner';
import RosterMetricsCard from '../RosterMetricsCard/RosterMetricsCard';
import Week17OpponentsModal from '../../Player/Week17OpponentsModal/Week17OpponentsModal';
import SyncWithYahooModal from '../../Yahoo/SyncWithYahooModal/SyncWithYahooModal';
import SuccessModal from '../../SuccessModal/SuccessModal';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

const RosterProfileCard = ({
	get_roster_by_user_endpoint,
	get_player_endpoint,
	get_players_endpoint,
	get_team_endpoint,
	update_roster_by_user_endpoint,
	create_roster_player_by_roster_endpoint,
	delete_roster_player_by_roster_endpoint,
	get_roster_player_note_endpoint,
	create_roster_player_note_endpoint,
	update_roster_player_note_endpoint,
	get_notes_by_roster_endpoint,
	update_notes_by_roster_endpoint,
	get_draft_notes_by_roster_endpoint,
	update_draft_notes_by_roster_endpoint,
	get_lessons_learned_notes_by_roster_endpoint,
	update_lessons_learned_notes_by_roster_endpoint,
	get_prospects_endpoint,
	scrape_and_update_ffpc_roster_endpoint
}) => {
	const { user } = useAuth();
	const { id } = useParams();
	const location = useLocation();
	const modalRef = useRef();
	const [
		roster,
		setRoster
	] = useState(null);
	const [
		selectedPlayer,
		setSelectedPlayer
	] = useState([]);
	const [
		isPlayerModalOpen,
		setIsPlayerModalOpen
	] = useState(false);
	const [
		selectedTeam,
		setSelectedTeam
	] = useState([]);
	const [
		isTeamModalOpen,
		setIsTeamModalOpen
	] = useState(false);
	const [
		isRosterPlayerNoteModalOpen,
		setIsRosterPlayerNoteModalOpen
	] = useState(false);
	const [
		scrolledPastBanner,
		setScrolledPastBanner
	] = useState(false);
	const [
		isPlayerWeek17OpponentsModalOpen,
		setIsPlayerWeek17OpponentsModalOpen
	] = useState(false);
	const [
		isDeleteRosterPlayerModalOpen,
		setIsDeleteRosterPlayerModalOpen
	] = useState(false);

	// Edit Roster Modal
	const [
		isCreateRosterModalOpen,
		setIsCreateRosterModalOpen
	] = useState(false);
	const [
		editingRoster,
		setEditingRoster
	] = useState(null);
	const [
		rosterTeamName,
		setRosterTeamName
	] = useState('');
	const [
		rosterLeagueName,
		setRosterLeagueName
	] = useState('');
	const [
		rosterPlatform,
		setRosterPlatform
	] = useState('');
	const [
		rosterUsername,
		setRosterUsername
	] = useState('');
	const [
		rosterUrl,
		setRosterUrl
	] = useState('');
	const [
		rosterFormat,
		setRosterFormat
	] = useState('');
	const [
		rosterScoring,
		setRosterScoring
	] = useState('');
	const [
		additionalEmails,
		setAdditionalEmails
	] = useState([
		user.email
	]);
	const [
		errors,
		setErrors
	] = useState({});

	// Yahoo Sync Modal
	const [
		showYahooModal,
		setShowYahooModal
	] = useState(false);
	const [
		yahooAuthUrl,
		setYahooAuthUrl
	] = useState('');

	// Position Visibility
	const [
		positionVisibility,
		setPositionVisibility
	] = useState({
		Quarterbacks: true,
		'Running Backs': true,
		'Wide Receivers': true,
		'Tight Ends': true
	});
	const [
		allSectionsCollapsed,
		setAllSectionsCollapsed
	] = useState(false);

	const toggleAllSections = () => {
		setAllSectionsCollapsed(!allSectionsCollapsed);
		setPositionVisibility({
			Quarterbacks: allSectionsCollapsed,
			'Running Backs': allSectionsCollapsed,
			'Wide Receivers': allSectionsCollapsed,
			'Tight Ends': allSectionsCollapsed
		});
	};

	const [
		successModalVisible,
		setSuccessModalVisible
	] = useState(false);

	const closeSuccessModal = () => {
		setSuccessModalVisible(false);
		// Redirect to /rosters if the logged-in user is no longer part of the roster
		const shouldRedirect = localStorage.getItem('redirectToRosters') === 'true';
		if (shouldRedirect) {
			localStorage.removeItem('redirectToRosters');
			window.location.href = '/rosters';
		}
	};

	const handleEditClick = (roster) => {
		setEditingRoster(roster);
		setRosterTeamName(roster.team_name);
		setRosterLeagueName(roster.league_name);
		setRosterPlatform(roster.platform);
		setRosterUsername(roster.username);
		setRosterUrl(roster.roster_url);
		setRosterFormat(roster.format);
		setRosterScoring(roster.scoring);
		setAdditionalEmails(roster.users.map((user) => user.email));
		setIsCreateRosterModalOpen(true);
	};

	// Prevent background scrolling when the team modal is open
	useEffect(
		() => {
			if (isTeamModalOpen) {
				document.body.style.overflow = 'hidden';
			}

			return () => {
				// Cleanup on modal close or component unmount
				document.body.style.overflow = '';
			};
		},
		[
			isTeamModalOpen
		]
	);

	const clearErrors = () => {
		setErrors({});
	};

	const [
		isYahooSyncSuccessModalOpen,
		setIsYahooSyncSuccessModalOpen
	] = useState(false);

	useEffect(
		() => {
			const params = new URLSearchParams(location.search);
			if (params.get('yahooSync') === 'true') {
				setIsYahooSyncSuccessModalOpen(true);
			}
		},
		[
			location.search
		]
	);

	const closeYahooSyncSuccessModal = () => {
		setIsYahooSyncSuccessModalOpen(false);

		const url = new URL(window.location);
		const params = new URLSearchParams(url.search);

		if (params.has('yahooSync') && params.get('yahooSync') === 'true') {
			params.delete('yahooSync');

			const newUrl = params.toString()
				? `${url.pathname}?${params.toString()}`
				: url.pathname;
			window.history.replaceState({}, '', newUrl);
		}
	};

	const fetchRoster = async () => {
		window.scrollTo(0, 0); // Scroll to the top of the window when the component mounts

		if (get_roster_by_user_endpoint) {
			const endpoint = get_roster_by_user_endpoint
				.replace('user_id', user.id)
				.replace('roster_id', id);

			const csrfToken = document.querySelector('[name="csrf-token"]').content;

			try {
				const response = await fetch(endpoint);
				const data = await response.json();

				// Check if the platform is "FFPC"
				if (data.data.platform === 'FFPC' && data.data.roster_url) {
					// Call scrape_and_update_ffpc_roster_endpoint
					const updateResponse = await fetch(
						scrape_and_update_ffpc_roster_endpoint.replace('roster_id', id),
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
								'X-CSRF-Token': csrfToken
							},
							body: JSON.stringify({
								roster_url: data.data.roster_url,
								roster_id: id
							})
						}
					);

					const updateData = await updateResponse.json();

					// If success === true, re-fetch the roster data
					if (updateData.success === true) {
						const refreshedResponse = await fetch(endpoint);
						const refreshedData = await refreshedResponse.json();
						setRoster(refreshedData.data);
					} else {
						console.error('Failed to update the roster:', updateData.message);
					}
				} else if (data.data.platform === 'Yahoo' && data.data.roster_url) {
					// Parse the roster_url to extract league_id and team_id
					const rosterUrl = data.data.roster_url;
					const urlParts = rosterUrl.match(/f1\/(\d+)\/(\d+)/);

					if (urlParts) {
						const leagueId = urlParts[1]; // Extracted league ID
						const teamId = urlParts[2]; // Extracted team ID

						const formattedLeagueId = `nfl.l.${leagueId}`;
						const formattedTeamId = `nfl.l.${leagueId}.t.${teamId}`;

						// Call the fetch_and_update_yahoo_roster_with_oauth endpoint
						const updateResponse = await fetch(
							`/api/v1/rosters/${id}/fetch_and_update_yahoo_roster_with_oauth`,
							{
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
									'X-CSRF-Token': csrfToken
								},
								body: JSON.stringify({
									roster_url: rosterUrl,
									roster_id: id,
									league_id: formattedLeagueId,
									team_id: formattedTeamId
								})
							}
						);

						const updateData = await updateResponse.json();

						if (updateData.auth_url) {
							setYahooAuthUrl(updateData.auth_url);
							setShowYahooModal(true);

							// If the update was successful, re-fetch the roster data
							const refreshedResponse = await fetch(endpoint);
							const refreshedData = await refreshedResponse.json();
							setRoster(refreshedData.data);
						} else if (updateData.success === true) {
							const refreshedResponse = await fetch(endpoint);
							const refreshedData = await refreshedResponse.json();
							setRoster(refreshedData.data);
						} else {
							console.error('Failed to update the roster:', updateData.error);
						}
					} else {
						console.error('Failed to parse roster URL:', rosterUrl);
					}
				} else if (
					data.data.platform === 'Sleeper' &&
					data.data.username &&
					data.data.roster_url
				) {
					const rosterUrl = data.data.roster_url;
					const leagueId = (rosterUrl.match(/leagues\/(\d+)\/team/) || [])[1];

					// Call fetch_and_update_sleeper_roster_endpoint
					const updateResponse = await fetch(
						`/api/v1/rosters/${id}/fetch_and_update_sleeper_roster`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
								'X-CSRF-Token': csrfToken
							},
							body: JSON.stringify({
								roster_id: id,
								username: data.data.username,
								league_id: leagueId
							})
						}
					);

					const updateData = await updateResponse.json();

					// If success === true, re-fetch the roster data
					if (updateData.success === true) {
						const refreshedResponse = await fetch(endpoint);
						const refreshedData = await refreshedResponse.json();
						setRoster(refreshedData.data);
					} else {
						console.error('Failed to update the roster:', updateData.message);
					}
				} else {
					// If platform is not "FFPC" or "Yahoo" or "Sleeper", set the roster as is
					setRoster(data.data);
				}
			} catch (error) {
				console.error('Error fetching roster:', error);
			}
		}
	};

	useEffect(
		() => {
			fetchRoster();
		},
		[
			get_roster_by_user_endpoint,
			user.id,
			id
		]
	);

	const handleSaveRoster = () => {
		const payload = {
			user_id: user.id,
			team_name: rosterTeamName,
			league_name: rosterLeagueName,
			platform: rosterPlatform,
			username: rosterUsername,
			roster_url: rosterUrl,
			format: rosterFormat,
			scoring: rosterScoring,
			emails: additionalEmails
		};

		const endpoint = update_roster_by_user_endpoint
			.replace('user_id', user.id)
			.replace('roster_id', editingRoster.id);

		fetch(endpoint, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(payload)
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					// This feels like a lot going on here, may be worth refactoring
					// Need to be particularly careful with the roster object and users array
					// Test this thoroughly if you make any changes
					setRoster((prevRoster) => {
						const updatedRoster = {
							...prevRoster,
							...payload,
							users: payload.emails.map((email) => ({ email }))
						};

						// Check if the logged-in user is still part of the roster
						const userInRoster = updatedRoster.users.some(
							(rosterUser) => rosterUser.email === user.email
						);

						// If the logged-in user is no longer part of the roster, set a flag to redirect to /rosters
						if (!userInRoster) {
							localStorage.setItem('redirectToRosters', 'true');
						}

						return updatedRoster;
					});

					setErrors({});
					closeModal();
					setSuccessModalVisible(true);
				} else if (data.message) {
					console.log('Failed to update roster:', data.field_errors);
					setErrors(data.field_errors || {});
				}
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	const handleEmailChange = (index, value) => {
		const updatedEmails = [
			...additionalEmails
		];
		updatedEmails[index] = value;
		setAdditionalEmails(updatedEmails);
	};

	const handleAddEmail = () => {
		setAdditionalEmails([
			...additionalEmails,
			''
		]);
	};

	const handleRemoveEmail = (index) => {
		const updatedEmails = [
			...additionalEmails
		];
		updatedEmails.splice(index, 1);
		setAdditionalEmails(updatedEmails);
	};

	const handleAddPlayer = (position, playerId) => {
		if (create_roster_player_by_roster_endpoint) {
			const endpoint =
				create_roster_player_by_roster_endpoint
					.replace('user_id', user.id)
					.replace('roster_id', id)
					.replace('player_id', playerId) +
				'?position=' +
				position;

			fetch(endpoint, {
				method: 'POST'
			})
				.then((response) => response.json())
				.then((data) => {
					setRoster(data.data);
				});
		}
	};

	const handleRemovePlayer = (position, playerId) => {
		if (delete_roster_player_by_roster_endpoint) {
			const endpoint =
				delete_roster_player_by_roster_endpoint
					.replace('user_id', user.id)
					.replace('roster_id', id)
					.replace('player_id', playerId) +
				'?position=' +
				position;

			fetch(endpoint, {
				method: 'DELETE'
			})
				.then((response) => response.json())
				.then((data) => {
					setRoster(data.data);
					setIsDeleteRosterPlayerModalOpen(false);
				});
		}
	};

	const confirmDeleteRosterPlayer = (player) => {
		setSelectedPlayer(player);
		setIsDeleteRosterPlayerModalOpen(true);
	};

	const handleOpenRosterPlayerNote = (player) => {
		setSelectedPlayer(player);
		setIsRosterPlayerNoteModalOpen(true);
	};

	const handleRosterPlayerClick = (player) => {
		setSelectedPlayer(player);
		setIsPlayerModalOpen(true);
	};

	const handleTeamClick = (team) => {
		setSelectedTeam(team);
		setIsTeamModalOpen(true);
	};

	const handleOpenPlayerWeek17OpponentsModal = (player) => {
		setSelectedPlayer(player);
		setIsPlayerWeek17OpponentsModalOpen(true);
	};

	const closePlayerModal = () => {
		setIsPlayerModalOpen(false);
		setSelectedPlayer(null);
	};

	const closeTeamModal = () => {
		setIsTeamModalOpen(false);
		setSelectedTeam(null);
	};

	const closeWeek17OpponentsModal = () => {
		setIsPlayerWeek17OpponentsModalOpen(false);
	};

	const closePlayerModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerModalOpen(false);
			setSelectedPlayer(null);
		}
	};

	const closeTeamModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsTeamModalOpen(false);
			setSelectedTeam(null);
		}
	};

	const closeRosterPlayerNoteModalOnClickOutside = (event) => {
		if (!modalRef.current.contains(event.target)) {
			setIsRosterPlayerNoteModalOpen(false);
		}
	};

	const closeWeek17OpponentsModalOnClickOutside = (event) => {
		if (!modalRef.current.contains(event.target)) {
			setIsPlayerWeek17OpponentsModalOpen(false);
		}
	};

	const closeDeleteRosterPayerModalOnClickOutside = (event) => {
		if (!modalRef.current.contains(event.target)) {
			setIsDeleteRosterPlayerModalOpen(false);
		}
	};

	useEffect(
		() => {
			if (isPlayerModalOpen) {
				document.addEventListener('mousedown', closePlayerModalOnClickOutside);
			} else {
				document.removeEventListener(
					'mousedown',
					closePlayerModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closePlayerModalOnClickOutside
				);
			};
		},
		[
			isPlayerModalOpen
		]
	);

	useEffect(
		() => {
			if (isTeamModalOpen) {
				document.addEventListener('mousedown', closeTeamModalOnClickOutside);
			} else {
				document.removeEventListener('mousedown', closeTeamModalOnClickOutside);
			}
			return () => {
				document.removeEventListener('mousedown', closeTeamModalOnClickOutside);
			};
		},
		[
			isTeamModalOpen
		]
	);

	useEffect(
		() => {
			if (isRosterPlayerNoteModalOpen) {
				document.addEventListener(
					'mousedown',
					closeRosterPlayerNoteModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeRosterPlayerNoteModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeRosterPlayerNoteModalOnClickOutside
				);
			};
		},
		[
			isRosterPlayerNoteModalOpen
		]
	);

	useEffect(
		() => {
			if (isPlayerWeek17OpponentsModalOpen) {
				document.addEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			};
		},
		[
			isPlayerWeek17OpponentsModalOpen
		]
	);

	useEffect(
		() => {
			if (isDeleteRosterPlayerModalOpen) {
				document.addEventListener(
					'mousedown',
					closeDeleteRosterPayerModalOnClickOutside
				);
			} else {
				closeDeleteRosterPayerModalOnClickOutside;
				document.removeEventListener(
					'mousedown',
					closeDeleteRosterPayerModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeDeleteRosterPayerModalOnClickOutside
				);
			};
		},
		[
			isDeleteRosterPlayerModalOpen
		]
	);

	useEffect(() => {
		const handleScroll = () => {
			const bannerHeight = document.querySelector('.banner-section')
				.offsetHeight;
			const scrolled = window.scrollY > bannerHeight;
			setScrolledPastBanner(scrolled);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const rankModalRef = useRef(null);
	const currentPlayerRef = useRef(null);
	const [
		gradeRanks,
		setGradeRanks
	] = useState([]);
	const [
		visibleRankModal,
		setVisibleRankModal
	] = useState(null);
	const [
		loading,
		setLoading
	] = useState(false);

	// Handle rank modal click
	const handleRankClick = async (gradeType, position) => {
		if (visibleRankModal === gradeType) {
			setVisibleRankModal(null);
		} else {
			setLoading(true);
			setVisibleRankModal(gradeType);

			try {
				const response = await fetch(
					`/api/v1/players/position-ranks/${encodeURIComponent(position)}`
				);
				if (!response.ok) throw new Error('Network response was not ok');
				const data = await response.json();
				setLoading(false);
				setGradeRanks(data);
			} catch (error) {
				console.error('Failed to fetch grade ranks:', error);
			}
		}
	};
	// Handle player click
	const handlePlayerClick = (playerId, playerPosition) => {
		setVisibleRankModal(null);
		window.location.href = `/players/${playerId}?position=${playerPosition}`;
	};

	const closeModal = () => {
		setIsCreateRosterModalOpen(false);
		setEditingRoster(null);
	};

	const closeDeleteRosterPayerModal = () => {
		setIsDeleteRosterPlayerModalOpen(false);
	};

	// Scroll to the selected player in the modal
	useEffect(
		() => {
			setTimeout(() => {
				if (visibleRankModal && currentPlayerRef.current) {
					currentPlayerRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					});
				}
			}, 500);
		},
		[
			visibleRankModal,
			currentPlayerRef
		]
	);

	// Handle clicks outside the rank modal to close it
	const handleClickOutside = (event) => {
		if (rankModalRef.current && !rankModalRef.current.contains(event.target)) {
			setVisibleRankModal(null);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const togglePositionVisibility = (position) => {
		setPositionVisibility((prevVisibility) => ({
			...prevVisibility,
			[position]: !prevVisibility[position]
		}));
	};

	const handleClickOutsideRosterEditModal = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			closeModal();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideRosterEditModal);
		return () => {
			document.removeEventListener(
				'mousedown',
				handleClickOutsideRosterEditModal
			);
		};
	}, []);

	if (!roster) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	const renderPlayers = (position, players) => (
		<div className="mt-10">
			<div className="flex items-center mb-2">
				<h3 className="text-xl text-gray-800 font-bold mb-0">
					{position.toUpperCase()}
				</h3>
				<i
					className={`fas fa-chevron-${positionVisibility[position]
						? 'up'
						: 'down'} text-gray-500 cursor-pointer ml-2`}
					onClick={() => togglePositionVisibility(position)}
				/>
			</div>

			{/* Players List */}
			{positionVisibility[position] && (
				<div>
					{players.length === 0 ? (
						<p className="text-gray-500 text-center py-6">
							No {position.toLowerCase()} on this roster.
						</p>
					) : (
						players.map((player) => (
							<RosterPlayerCard
								player={player}
								loading={loading}
								gradeRanks={gradeRanks}
								handleRosterPlayerClick={handleRosterPlayerClick}
								handleTeamClick={handleTeamClick}
								handleOpenRosterPlayerNote={handleOpenRosterPlayerNote}
								handleOpenPlayerWeek17OpponentsModal={
									handleOpenPlayerWeek17OpponentsModal
								}
								handleRankClick={handleRankClick}
								visibleRankModal={visibleRankModal}
								rankModalRef={rankModalRef}
								currentPlayerRef={currentPlayerRef}
								handlePlayerClick={handlePlayerClick}
								currentPlayerId={player.id}
								confirmDeleteRosterPlayer={confirmDeleteRosterPlayer}
							/>
						))
					)}
				</div>
			)}
		</div>
	);

	return (
		<div>
			<div className="banner-section">
				<RosterPortraitCard
					roster={roster}
					get_notes_by_roster_endpoint={get_notes_by_roster_endpoint}
					update_notes_by_roster_endpoint={update_notes_by_roster_endpoint}
					get_draft_notes_by_roster_endpoint={
						get_draft_notes_by_roster_endpoint
					}
					update_draft_notes_by_roster_endpoint={
						update_draft_notes_by_roster_endpoint
					}
					get_lessons_learned_notes_by_roster_endpoint={
						get_lessons_learned_notes_by_roster_endpoint
					}
					update_lessons_learned_notes_by_roster_endpoint={
						update_lessons_learned_notes_by_roster_endpoint
					}
					get_prospects_endpoint={get_prospects_endpoint}
					handleEditClick={handleEditClick}
				/>
			</div>

			{/* Fixed Roster Banner  - Displays after scrolling past the Roster Portrait Card Section */}
			{scrolledPastBanner && (
				<FixedRosterBanner
					scrolledPastBanner={scrolledPastBanner}
					roster={roster}
				/>
			)}

			{/* Expand/Collapse All Link */}
			<div className="flex mt-4 relative">
				<button
					className="text-blue-500 text-sm font-medium hover:underline absolute right-0 top-14"
					onClick={toggleAllSections}
				>
					{allSectionsCollapsed ? 'Expand All' : 'Collapse All'}
				</button>
			</div>

			{/* Roster Metrics Card */}
			<div className="mt-14">
				<RosterMetricsCard
					roster={roster}
					allSectionsCollapsed={allSectionsCollapsed}
				/>
			</div>

			{/* Roster Players */}
			<div className="mt-14 relative">
				<div className="absolute" style={{ top: -28, right: -15 }}>
					<PlayerSearchBar
						getPlayersEndpoint={get_players_endpoint}
						handleAddPlayer={handleAddPlayer}
						isRosterBuilder={true}
						isVisibleByDefault={true}
						isCompareModal={false}
						isDarkMode={true}
						allSectionsCollapsed={allSectionsCollapsed}
					/>
				</div>
				{roster.players && (
					<Fragment>
						{renderPlayers('Quarterbacks', roster.players.quarterbacks)}
						{renderPlayers('Running Backs', roster.players.running_backs)}
						{renderPlayers('Wide Receivers', roster.players.wide_receivers)}
						{renderPlayers('Tight Ends', roster.players.tight_ends)}
					</Fragment>
				)}
			</div>

			{/* Player Modal */}
			{isPlayerModalOpen &&
			selectedPlayer && (
				<div
					className="fixed inset-0 z-50 overflow-auto flex w-full"
					style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
				>
					<div
						className="relative p-8 bg-white m-auto flex-col flex rounded-lg w-full lg:w-3/4"
						ref={modalRef}
					>
						<button
							className="absolute top-0 right-0 p-2 text-gray-700 hover:text-gray-900"
							onClick={closePlayerModal}
						>
							<svg
								className="h-6 w-6 fill-current"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6.225 4.811l.084.073L12 10.587l5.691-5.691a1 1 0 011.497 1.32l-.083.094L13.413 12l5.692 5.692a1 1 0 01-1.32 1.497l-.094-.083L12 13.413l-5.691 5.691a1 1 0 01-1.497-1.32l.083-.094L10.587 12 4.811 6.225a1 1 0 011.32-1.497z" />
							</svg>
						</button>
						<PlayerProfileCard
							player={selectedPlayer}
							get_player_endpoint={get_player_endpoint}
							get_players_endpoint={get_players_endpoint}
						/>
					</div>
				</div>
			)}

			{/* Team Modal */}
			{isTeamModalOpen &&
			selectedTeam && (
				<div
					className="fixed inset-0 z-50 overflow-auto flex w-full"
					style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
				>
					<div
						className="relative p-8 bg-white m-auto flex-col flex rounded-lg w-15/16 lg:w-3/4"
						ref={modalRef}
					>
						<button
							className="absolute top-0 right-0 p-2 text-gray-700 hover:text-gray-900"
							onClick={closeTeamModal}
						>
							<svg
								className="h-6 w-6 fill-current"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6.225 4.811l.084.073L12 10.587l5.691-5.691a1 1 0 011.497 1.32l-.083.094L13.413 12l5.692 5.692a1 1 0 01-1.32 1.497l-.094-.083L12 13.413l-5.691 5.691a1 1 0 01-1.497-1.32l.083-.094L10.587 12 4.811 6.225a1 1 0 011.32-1.497z" />
							</svg>
						</button>
						<TeamProfileCard
							team={selectedTeam}
							get_team_endpoint={get_team_endpoint}
						/>
					</div>
				</div>
			)}

			{/* Roster Player Note Modal */}
			{isRosterPlayerNoteModalOpen && (
				<RosterPlayerNoteModal
					userId={user.id}
					rosterId={id}
					player={selectedPlayer}
					isRosterPlayerNoteModalOpen={isRosterPlayerNoteModalOpen}
					closeRosterPlayerNoteModal={() =>
						setIsRosterPlayerNoteModalOpen(false)}
					get_roster_player_note_endpoint={get_roster_player_note_endpoint}
					create_roster_player_note_endpoint={
						create_roster_player_note_endpoint
					}
					update_roster_player_note_endpoint={
						update_roster_player_note_endpoint
					}
					modalRef={modalRef}
				/>
			)}

			{/* Delete Roster Modal */}
			{isDeleteRosterPlayerModalOpen && (
				<div
					className="fixed inset-0 z-50 overflow-auto flex w-full"
					style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
				>
					<div
						className="relative p-8 bg-white m-auto flex-col flex rounded-lg"
						ref={modalRef}
					>
						<p className="mb-4">
							Are you sure you want to remove {selectedPlayer.first_name}{' '}
							{selectedPlayer.last_name} from your roster?
							<p className="my-4">
								Note: You can always add him back later and your player notes
								will be preserved.
							</p>
						</p>
						<div className="flex justify-end">
							<button
								className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 mr-4"
								onClick={closeDeleteRosterPayerModal}
							>
								Cancel
							</button>
							<button
								className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
								onClick={() =>
									handleRemovePlayer(
										selectedPlayer.position,
										selectedPlayer.id
									)}
							>
								Yes, remove
							</button>
						</div>
					</div>
				</div>
			)}

			{/* Week 17 Opponents Modal */}
			{isPlayerWeek17OpponentsModalOpen && (
				<Week17OpponentsModal
					player={selectedPlayer}
					isPlayerWeek17OpponentsModalOpen={isPlayerWeek17OpponentsModalOpen}
					closeWeek17OpponentsModal={closeWeek17OpponentsModal}
					modalRef={modalRef}
				/>
			)}

			{/* Create/Edit Roster Modal */}
			{isCreateRosterModalOpen && (
				<RosterFormModal
					isOpen={isCreateRosterModalOpen}
					closeModal={closeModal}
					isRosterProfileUpdate={true}
					editingRoster={editingRoster}
					handleSaveRoster={handleSaveRoster}
					rosterTeamName={rosterTeamName}
					setRosterTeamName={setRosterTeamName}
					rosterLeagueName={rosterLeagueName}
					setRosterLeagueName={setRosterLeagueName}
					rosterPlatform={rosterPlatform}
					setRosterPlatform={setRosterPlatform}
					rosterUsername={rosterUsername}
					setRosterUsername={setRosterUsername}
					rosterUrl={rosterUrl}
					setRosterUrl={setRosterUrl}
					rosterFormat={rosterFormat}
					setRosterFormat={setRosterFormat}
					rosterScoring={rosterScoring}
					setRosterScoring={setRosterScoring}
					additionalEmails={additionalEmails}
					handleEmailChange={handleEmailChange}
					handleAddEmail={handleAddEmail}
					handleRemoveEmail={handleRemoveEmail}
					errors={errors}
					clearErrors={clearErrors}
				/>
			)}

			{/* Success Modal */}
			<SuccessModal visible={successModalVisible} onClose={closeSuccessModal} />

			{/* Yahoo Sync Modal */}
			<SyncWithYahooModal
				rosterId={id}
				show={showYahooModal}
				onClose={() => setShowYahooModal(false)}
				authUrl={yahooAuthUrl}
			/>

			{/* Yahoo Sync Success Modal */}
			<SuccessModal
				visible={isYahooSyncSuccessModalOpen}
				onClose={() => closeYahooSyncSuccessModal()}
				message="Your roster automatically updates when you make changes on Yahoo."
			/>
		</div>
	);
};

export default RosterProfileCard;
