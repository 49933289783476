import React from 'react';
import GlossaryTermIndexCard from '../../Glossary/GlossaryTermsIndexCard/GlossaryTermsIndexCard';

export default function GlossaryIndexPage({ get_glossary_terms_endpoint }) {
	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="mt-4">
				<GlossaryTermIndexCard
					get_glossary_terms_endpoint={get_glossary_terms_endpoint}
				/>
			</div>
		</div>
	);
}
