// PricingCard.js
import React from 'react';
import PropTypes from 'prop-types';

const PricingCard = ({
	title,
	description,
	price,
	billingFrequency,
	buttonText,
	features,
	isDarkBg = false,
	priceId,
	handleSubscriptionClick,
	isMostPopular,
	buttonColor
}) => {
	return (
		<div
			className={`border border-gray-200 rounded-lg p-10 pt-20 w-80 shadow-md inline-block h-[600px] ${isDarkBg
				? 'bg-gray-100'
				: 'bg-white'}`}
		>
			<div
				style={{
					position: 'relative',
					height: '150px'
				}}
			>
				{isMostPopular && (
					<span
						className="text-xs text-black bg-white border border-gray-300 rounded py-1 px-1.5 mb-2 inline-block"
						style={{
							position: 'absolute',
							top: '-40px'
						}}
					>
						Most popular
					</span>
				)}
				<h2 className="text-xl font-bold mb-2">{title}</h2>
				<p className="text-sm text-gray-600 mb-10">{description}</p>
			</div>
			<div className="flex items-baseline mb-4 ">
				<span className="text-4xl font-bold text-black mr-3">{price}</span>
				<span className="text-gray-600">{billingFrequency}</span>
			</div>
			<button
				className={`py-2 px-4 rounded my-2 w-full ${buttonColor}`}
				onClick={() => handleSubscriptionClick(priceId)}
			>
				{buttonText}
			</button>
			<div className="mt-2 text-sm font-light">
				<h3 className="mb-2">This includes:</h3>
				<ul className="list-disc ml-5 text-gray-700 space-y-3">
					{features.map((feature, index) => <li key={index}>{feature}</li>)}
				</ul>
			</div>
		</div>
	);
};

// Define prop types
PricingCard.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	price: PropTypes.string,
	billingFrequency: PropTypes.string,
	buttonText: PropTypes.string,
	features: PropTypes.arrayOf(PropTypes.string),
	isDarkBg: PropTypes.bool,
	priceId: PropTypes.string,
	buttonColor: PropTypes.string
};

export default PricingCard;
