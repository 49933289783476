import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const RostersListCard = ({ user, get_rosters_by_user_endpoint }) => {
	const [
		rosters,
		setRosters
	] = useState([]);

	useEffect(
		() => {
			const fetchRostersByUser = async () => {
				if (get_rosters_by_user_endpoint && user) {
					const endpoint = get_rosters_by_user_endpoint.replace(
						'user_id',
						user.id
					);
					const response = await fetch(endpoint);
					if (response.ok) {
						const data = await response.json();
						setRosters(data.data.slice(0, 4) || []);
					}
				}
			};

			fetchRostersByUser();
		},
		[
			user,
			get_rosters_by_user_endpoint
		]
	);

	return (
		<div className="bg-white border border-gray-200 shadow-lg rounded-xl">
			<div className="mx-4 py-4 border-b border-gray-200">
				<h2 className="text-lg font-bold text-gray-800">
					<i className="fa-solid fa-clipboard-list text-lg mr-3" />
					<Link to="/rosters" className="hover:underline">
						Rosters
					</Link>
				</h2>
			</div>
			{rosters.length > 0 ? (
				<ul className="divide-y divide-gray-200 px-4">
					{rosters.map((roster) => (
						<li
							key={roster.id}
							className="py-3 flex justify-between items-center"
						>
							<Link
								to={`/rosters/${roster.id}`}
								className="block text-sm font-medium text-gray-800 truncate hover:underline"
							>
								{roster.team_name}{' '}
								<span className="text-gray-500 text-xs">
									({roster.league_name.toUpperCase()})
								</span>
							</Link>
							<span className="text-xs text-gray-500">{roster.platform}</span>
						</li>
					))}
				</ul>
			) : (
				<div className="text-center py-6 px-4">
					<p className="text-sm text-gray-500">
						No results found. Click {' '}
						<Link
							to="/rosters"
							className="text-blue-500 hover:underline"
						>
							here
						</Link>{' '}
						to create a roster.
					</p>
				</div>
			)}
			{rosters.length > 0 && (
				<div className="mx-4 py-3 border-t border-gray-200 text-center">
					<Link
						to="/rosters"
						className="text-sm text-blue-500 hover:underline"
					>
						See all rosters →
					</Link>
				</div>
			)}
		</div>
	);
};

export default RostersListCard;
