import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ByeWeekCountCard = ({
	byeWeekPlayerCount,
	players,
	isDraftModeEnabled
}) => {
	// Group players by their bye week
	const playersByWeek = players.reduce((acc, player) => {
		const week = player.bye_week; // Get the player's bye week
		if (week) {
			if (!acc[week]) acc[week] = [];
			acc[week].push(`${player.first_name} ${player.last_name}`);
		}
		return acc;
	}, {});

	// Prepare data for the treemap chart
	const treemapData = Object.entries(byeWeekPlayerCount).map(([ week, count
	]) => ({
		x: `Week ${week}`,
		y: count,
		week: week // Store the week in the data for tooltip access
	}));

	// Chart configuration for the treemap
	const chartData = {
		series: [
			{
				data: treemapData
			}
		],
		options: {
			chart: {
				type: 'treemap',
				toolbar: {
					show: false
				},
				height: 208
			},
			legend: {
				show: false
			},
			tooltip: !isDraftModeEnabled
				? {
						custom: ({ seriesIndex, dataPointIndex, w }) => {
							const week =
								w.config.series[seriesIndex].data[dataPointIndex].week; // Access the 'week' from the data
							const playerList = playersByWeek[week] || []; // Get the player names for that bye week
							const count = byeWeekPlayerCount[week] || 0; // Get the count for the bye week

							return `
      <div class="px-2 py-1 bg-white shadow-lg border border-gray-300 rounded text-black">
        <strong>Week ${week} (${count})</strong> <!-- Display count next to the week -->
        <ul>
          ${playerList
						.map((player) => `<li style="color: black;">${player}</li>`)
						.join('')}
        </ul>
      </div>
    `;
						}
					}
				: {} // Default behavior if !isDraftModeEnabled is true
		}
	};

	return (
		<div className="relative bg-white p-4 rounded-lg shadow-lg border border-gray-300 cursor-pointer">
			{/* Title */}
			<h3 className="text-sm font-medium text-gray-800 text-center">
				Bye Week Breakdown
			</h3>
			{/* Card Content */}
			<div className="flex justify-center items-center">
				<div className="flex flex-col items-center">
					<ReactApexChart
						options={chartData.options}
						series={chartData.series}
						type="treemap"
						height={208}
					/>
				</div>
			</div>
		</div>
	);
};

export default ByeWeekCountCard;
