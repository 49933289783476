import React, { useState, useEffect, useRef, Fragment } from 'react';
import CircularBarChart from '../../../Charts/CircularBarChart';
import getPositionAbbreviation from '../../../../utils/getPositionAbbreviation';

const ComparePlayerProspectScores = ({
	selectedPlayers,
	isProspectProfileCard
}) => {
	const [
		isPSHovered,
		setIsPSHovered
	] = useState(false);
	const [
		isStrengthsHovered,
		setIsStrengthsHovered
	] = useState(false);
	const [
		isWeaknessesHovered,
		setIsWeaknessesHovered
	] = useState(false);

	const rankModalRef = useRef(null);
	const currentPlayerRef = useRef(null);
	const [
		gradeRanks,
		setGradeRanks
	] = useState([]);
	const [
		visibleRankModal,
		setVisibleRankModal
	] = useState(null);
	const [
		loading,
		setLoading
	] = useState(false);

	// Handle rank modal click
	const handleRankClick = async (gradeType, position) => {
		if (visibleRankModal === gradeType) {
			setVisibleRankModal(null);
		} else {
			setLoading(true);
			setVisibleRankModal(gradeType);

			try {
				const response = await fetch(
					`/api/v1/players/position-ranks/${encodeURIComponent(
						position
					)}?is_college_player=${isProspectProfileCard}`
				);
				if (!response.ok) throw new Error('Network response was not ok');
				const data = await response.json();
				setLoading(false);
				setGradeRanks(data);
			} catch (error) {
				console.error('Failed to fetch grade ranks:', error);
			}
		}
	};

	// Handle player click
	const handlePlayerClick = (playerId, playerPosition) => {
		setVisibleRankModal(null);
		const url = `/prospects/${playerId}?position=${playerPosition}`;
		window.open(url, '_blank');
	};

	// Scroll to the selected player in the modal
	useEffect(
		() => {
			setTimeout(() => {
				if (visibleRankModal && currentPlayerRef.current) {
					currentPlayerRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					});
				}
			}, 500);
		},
		[
			visibleRankModal,
			currentPlayerRef
		]
	);

	// Handle clicks outside the rank modal to close it
	const handleClickOutside = (event) => {
		if (rankModalRef.current && !rankModalRef.current.contains(event.target)) {
			setVisibleRankModal(null);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const tooltipStyles = {
		position: 'absolute',
		top: '100%',
		left: '125px',
		transform: 'translateX(-50%)',
		whiteSpace: 'wrap',
		width: '250px'
	};

	const tooltipContent = {
		ps:
			"This is the player's Fantasy Fitness Prospect Score™. It attempts to estimate the player's potential value in the NFL based on their college performance and athleticism.",
		strengths:
			"This is the player's Fantasy Fitness Qualitative Strengths — a bulleted breakdown of the player's strengths, from a scouting perspective.",
		weaknesses:
			"This is the player's Fantasy Fitness Qualitative Weaknesses — a bulleted breakdown of the player's weaknesses, from a scouting perspective."
	};

	const renderTooltip = (isHovered, content) =>
		isHovered && (
			<div
				className="p-2 bg-white border border-gray-300 shadow-lg rounded-lg text-sm z-10"
				style={tooltipStyles}
			>
				{content}
			</div>
		);

	return (
		<Fragment>
			{/* Fantasy Fitness Score */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Prospect Score™
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isPSHovered
								? 'text-gray-700'
								: ''}`}
							onMouseEnter={() => setIsPSHovered(true)}
							onMouseLeave={() => setIsPSHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isPSHovered, tooltipContent.ps)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-55px' }}>
							<CircularBarChart
								loading={loading}
								chartSize="md"
								value={
									player && player.prospect_grade ? player.prospect_grade : 0
								}
								maxPossible={10}
								rank={
									player && player.prospect_grade_position_rank ? (
										player.prospect_grade_position_rank
									) : (
										999
									)
								}
								abbr_position={getPositionAbbreviation(
									player && player.position ? player.position : ''
								)}
								gradeRanks={
									gradeRanks && gradeRanks.prospect_grades ? (
										gradeRanks.prospect_grades
									) : (
										[]
									)
								}
								handleRankClick={() =>
									handleRankClick(
										`fantasy_fitness_prospect_score_${player.id}`,
										player.position
									)}
								isRankVisible={
									visibleRankModal ===
									`fantasy_fitness_prospect_score_${player.id}`
								}
								rankModalRef={rankModalRef}
								currentPlayerRef={currentPlayerRef}
								handlePlayerClick={handlePlayerClick}
								currentPlayerId={player && player.id ? player.id : 0}
								hasTitle={false}
								hasLabel={true}
								hasRankModalEnabled={true}
								renderOnComparePlayerModal={true}
							/>
						</div>
					</td>
				))}
			</tr>
			{/* Prospect Strengths */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Strengths
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isStrengthsHovered
								? 'text-gray-700'
								: ''}`}
							onMouseEnter={() => setIsStrengthsHovered(true)}
							onMouseLeave={() => setIsStrengthsHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isStrengthsHovered, tooltipContent.strengths)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`strengths-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							{/* Strengths Section */}
							<div className="flex-1 bg-green-50 rounded-lg p-4 shadow-md w-96">
								<div
									className="text-gray-700 ml-4"
									dangerouslySetInnerHTML={{
										__html:
											player && player.strengths
												? player.strengths
												: '<p>No strengths listed for this player.</p>'
									}}
								/>
							</div>
						</div>
					</td>
				))}
			</tr>
			{/* Prospect Weaknesses */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Weaknesses
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isWeaknessesHovered
								? 'text-gray-700'
								: ''}`}
							onMouseEnter={() => setIsWeaknessesHovered(true)}
							onMouseLeave={() => setIsWeaknessesHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isWeaknessesHovered, tooltipContent.weaknesses)}
					</div>
				</td>
				{selectedPlayers.map(
					(player, index) => (
						console.log('player', player),
						(
							<td key={`weaknesses-${index}`} className="px-6 py-4">
								<div style={{ marginLeft: '-45px' }}>
									{/* Weaknesses Section */}
									<div className="flex-1 bg-red-50 rounded-lg p-4 shadow-md w-96">
										<div
											className="text-gray-700 ml-4"
											dangerouslySetInnerHTML={{
												__html:
													player && player.weaknesses
														? player.weaknesses
														: '<p>No weaknesses listed for this player.</p>'
											}}
										/>
									</div>
								</div>
							</td>
						)
					)
				)}
			</tr>
		</Fragment>
	);
};

export default ComparePlayerProspectScores;
