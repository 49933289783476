import React, { useRef, useEffect, useState } from 'react';

const FilterToggle = ({
	label,
	icon,
	isChecked,
	onToggle,
	options,
	onOptionChange
}) => {
	const dropdownRef = useRef(null);
	const [
		isDropdownOpen,
		setIsDropdownOpen
	] = useState(false);

	const closeDropdownOnClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsDropdownOpen(false);
		}
	};

	useEffect(
		() => {
			if (isDropdownOpen) {
				document.addEventListener('mousedown', closeDropdownOnClickOutside);
			} else {
				document.removeEventListener('mousedown', closeDropdownOnClickOutside);
			}
			return () => {
				document.removeEventListener('mousedown', closeDropdownOnClickOutside);
			};
		},
		[
			isDropdownOpen
		]
	);

	useEffect(
		() => {
			const allOptionsFalse = options.every((option) => !option.value);
			if (allOptionsFalse) {
				setIsDropdownOpen(false);
			}
		},
		[
			options
		]
	);

	const handleIconClick = () => {
		if (!isDropdownOpen) {
			setIsDropdownOpen(true);
		}
	};

	const handleCheckboxChange = () => {
		const newCheckedState = !isChecked;
		onToggle(newCheckedState);
		if (newCheckedState) {
			setIsDropdownOpen(true);
		} else {
			setIsDropdownOpen(false);
		}
	};

	return (
		<div className="relative flex items-center mr-4 my-3 md:my-4">
			{/* Icon/Label for Toggle Switch */}
			<span
				className="mr-2 md:text-lg cursor-pointer"
				onClick={handleIconClick}
			>
				{label && icon ? icon : label}
			</span>

			{/* Toggle Switch */}
			<input
				type="checkbox"
				checked={isChecked}
				onChange={handleCheckboxChange}
				className="hidden"
				id={`${label}Checkbox`}
			/>
			<label
				htmlFor={`${label}Checkbox`}
				className="cursor-pointer select-none relative"
			>
				<div
					className={`w-8 h-4 md:w-12 md:h-6 rounded-full shadow-inner ${isChecked
						? 'bg-gray-800'
						: 'bg-gray-300'}`}
				/>
				<div
					className={`dot absolute top-0 left-0 w-4 h-4 md:w-6 md:h-6 bg-white rounded-full shadow transition transform ${isChecked
						? 'translate-x-full'
						: ''}`}
				/>
			</label>

			{/* Dropdown Menu */}
			{isDropdownOpen && (
				<div
					ref={dropdownRef}
					className="absolute top-8 left-0 mt-2 bg-white shadow-lg rounded-md w-32 px-4 py-2 z-10"
				>
					{options.map((option, index) => (
						<div
							key={option.label}
							className={`flex items-center ${index === options.length - 1
								? ''
								: 'mb-2'}`}
						>
							<input
								type="checkbox"
								checked={option.value}
								onChange={() => onOptionChange(option.key)}
								id={`option-${option.key}`}
								className="mr-2"
							/>
							<label
								htmlFor={`option-${option.key}`}
								className="cursor-pointer"
							>
								{option.label}
							</label>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default FilterToggle;
