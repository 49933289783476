import React, { useState, Fragment } from 'react';
import CircularBarChart from '../../../Charts/CircularBarChart';

const FantasyFitnessSlides = ({
	avgFantasyFitnessScore,
	avgFantasyFitnessTalentScore,
	avgFantasyFitnessTeamScore,
	players
}) => {
	const [
		activeSlide,
		setActiveSlide
	] = useState(0); // Track the active slide
	const [
		showPlayerList,
		setShowPlayerList
	] = useState(false); // Track whether to show the player list

	// Handle slide change
	const handleSlideChange = (direction) => {
		setActiveSlide((prev) => {
			const newIndex = prev + direction;
			if (newIndex < 0) return 2; // Wrap to last slide (index 3)
			if (newIndex > 2) return 0; // Wrap to first slide (index 0)
			return newIndex;
		});
	};

	const togglePlayerList = () => {
		setShowPlayerList(!showPlayerList); // Toggle visibility of the player list
	};

	const getTopAndBottomPlayers = (gradeType) => {
		// Sorting players based on the grade type
		const sortedPlayers = players.sort((a, b) => b[gradeType] - a[gradeType]);
		return {
			topPlayers: sortedPlayers.slice(0, 3),
			bottomPlayers: sortedPlayers.slice(-3)
		};
	};

	const gradeTypes = [
		{ label: 'Fantasy Fitness Grade', gradeKey: 'fantasy_fitness_grade' },
		{ label: 'Talent Grade', gradeKey: 'talent_grade' },
		{ label: 'Team Grade', gradeKey: 'team_grade' }
	];

	return (
		<div className="relative bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			{/* Title */}
			<h3 className="text-sm font-medium text-gray-800 mb-3 text-center">
				Fantasy Fitness Metrics
			</h3>
			{/* Switch Icons */}
			<button
				className="absolute p-1 rounded-full text-gray-500 text-white transform transition-transform z-20"
				style={{
					right: '1rem',
					top: '2.55rem',
					transition: 'transform 1.0s ease',
					transform: showPlayerList ? 'rotate(0deg)' : 'rotate(360deg)'
				}}
				onClick={togglePlayerList}
			>
				{showPlayerList ? (
					<i className="fa-solid fa-chart-pie" aria-label="Chart view" />
				) : (
					<i className="fas fa-list-ol" aria-label="Ranked view" />
				)}
			</button>

			{/* Navigation Controls */}
			<div className="absolute bottom-1 left-0 right-0 flex justify-between items-center w-full px-1 z-10">
				<button
					className="relative text-xl text-gray-700 focus:outline-none px-2 transition-all duration-300 hover:bg-gray-100 rounded-md"
					onClick={() => handleSlideChange(-1)} // Navigate to previous slide
					aria-label="Previous Slide"
				>
					&lt;
				</button>
				<button
					className="relative text-xl text-gray-700 focus:outline-none px-2 transition-all duration-300 hover:bg-gray-100 rounded-md"
					onClick={() => handleSlideChange(1)} // Navigate to next slide
					aria-label="Next Slide"
				>
					&gt;
				</button>
			</div>

			{/* Slide content */}
			<div className="flex flex-col items-center">
				{/* If player list is shown, display it */}
				<div className="w-full max-w-md mt-2">
					{/* Conditional rendering based on active slide */}
					{gradeTypes.map(
						(gradeType, index) =>
							// Render only for the active slide
							activeSlide === index &&
							showPlayerList && (
								<div key={gradeType.gradeKey}>
									{/* Top Players */}
									<h3 className="text-xs uppercase font-medium text-gray-700 mb-1">
										{`${gradeType.label === 'Fantasy Fitness Grade'
											? 'Top Overall Grades'
											: gradeType.label === 'Talent Grade'
												? 'Top Talent Grades'
												: gradeType.label === 'Team Grade'
													? 'Top Team Grades'
													: 'Top Players'}`}
									</h3>

									<ul className="mb-4">
										{getTopAndBottomPlayers(
											gradeType.gradeKey
										).topPlayers.map((player) => (
											<li
												key={player.id}
												className="flex justify-between items-center"
											>
												<span className="text-sm">{`${player.first_name} ${player.last_name}`}</span>
												<span className="text-sm">
													{player[gradeType.gradeKey]}
												</span>
											</li>
										))}
									</ul>

									{/* Bottom Players */}
									<h3 className="text-xs uppercase font-medium text-gray-700 mb-1">
										{`${gradeType.label === 'Fantasy Fitness Grade'
											? 'Bottom Overall Grades'
											: gradeType.label === 'Talent Grade'
												? 'Bottom Talent Grades'
												: gradeType.label === 'Team Grade'
													? 'Bottom Team Grades'
													: 'Bottom Players'}`}
									</h3>
									<ul>
										{getTopAndBottomPlayers(
											gradeType.gradeKey
										).bottomPlayers.map((player) => (
											<li
												key={player.id}
												className="flex justify-between items-center"
											>
												<span className="text-sm">{`${player.first_name} ${player.last_name}`}</span>
												<span className="text-sm">
													{player[gradeType.gradeKey]}
												</span>
											</li>
										))}
									</ul>
								</div>
							)
					)}
				</div>
				{/* Only show chart when player list is not visible */}
				<div className="flex flex-col items-center">
					{/* Show chart when player list is not visible */}
					{!showPlayerList && (
						<Fragment>
							{activeSlide === 0 && (
								<div className="relative flex flex-col items-center justify-center">
									<div
										className="absolute left-0 right-0 text-xs font-medium text-gray-500 text-center px-4 uppercase"
										style={{ top: '-0.5rem' }}
									>
										Overall Score
									</div>
									<CircularBarChart
										chartSize="sm"
										value={avgFantasyFitnessScore}
										maxPossible={10}
										hasTitle={false}
										hasLabel={false}
										hasRankModalEnabled={false}
									/>
									<div
										className="text-xs font-medium text-gray-500 text-center px-6"
										style={{ marginTop: '-0.6rem', marginBottom: '1.25rem' }}
									>
										{`Overall Score ranks in the top 18% of all dynasty rosters.`}
									</div>
								</div>
							)}
							{activeSlide === 1 && (
								<div className="relative flex flex-col items-center justify-center">
									<div
										className="absolute left-0 right-0 text-xs font-medium text-gray-500 text-center px-4 uppercase"
										style={{ top: '-0.5rem' }}
									>
										Talent Score
									</div>
									<CircularBarChart
										chartSize="sm"
										value={avgFantasyFitnessTalentScore}
										maxPossible={10}
										hasTitle={false}
										hasLabel={false}
										hasRankModalEnabled={false}
									/>
									<div
										className="text-xs font-medium text-gray-500 text-center px-6 mb-2"
										style={{ marginTop: '-0.6rem', marginBottom: '1.25rem' }}
									>
										{`Talent Score ranks in the top 23% of all dynasty rosters.`}
									</div>
								</div>
							)}
							{activeSlide === 2 && (
								<div className="relative flex flex-col items-center justify-center">
									<div
										className="absolute left-0 right-0 text-xs font-medium text-gray-500 text-center px-4 uppercase"
										style={{ top: '-0.5rem' }}
									>
										Team Score
									</div>
									<CircularBarChart
										chartSize="sm"
										value={avgFantasyFitnessTeamScore}
										maxPossible={10}
										hasTitle={false}
										hasLabel={false}
										hasRankModalEnabled={false}
									/>
									<div
										className="text-xs font-medium text-gray-500 text-center px-6 mb-2"
										style={{ marginTop: '-0.6rem', marginBottom: '1.25rem' }}
									>
										{`Team Score ranks in the top 45% of all dynasty rosters.`}
									</div>
								</div>
							)}
						</Fragment>
					)}
				</div>
			</div>
			{/* Slide Indicator */}
			<div className="absolute bottom-3.5 left-0 right-0 flex justify-center">
				{[
					0,
					1,
					2
				].map((index) => (
					<div
						key={index}
						className={`w-2 h-2 rounded-full mx-1 ${activeSlide === index
							? 'bg-gray-800'
							: 'bg-gray-300'}`}
					/>
				))}
			</div>
		</div>
	);
};

export default FantasyFitnessSlides;
