import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

export default function HelpCenterIndexCard({ get_articles_endpoint }) {
	const [
		articles,
		setArticles
	] = useState([]);
	const [
		loading,
		setLoading
	] = useState(true);
	const [
		error,
		setError
	] = useState(null);

	useEffect(
		() => {
			const fetchData = async () => {
				try {
					const url = `${get_articles_endpoint}?article_type=Help Desk`;

					const response = await fetch(url);
					if (!response.ok) {
						throw new Error('Failed to fetch articles');
					}
					const result = await response.json();
					setArticles(result.data || []);
				} catch (error) {
					setError(
						error.message || 'An error occurred while fetching articles.'
					);
				} finally {
					setLoading(false);
				}
			};

			fetchData();
		},
		[
			get_articles_endpoint
		]
	);

	return (
		<div>
			<h1 className="text-5xl mb-4 text-center">Hi, how can we help?</h1>
			{loading && <LoadingSpinner />}
			{error && <div>Error: {error}</div>}
			{!loading &&
			!error && (
				<div className="py-6 md:py-14">
					<div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
						{articles.map((article) => (
							<div
								key={article.id}
								className="rounded-lg border border-gray-300 bg-white shadow-lg hover:shadow-xl transition-shadow p-6 flex flex-col"
							>
								<div>
									{/* Title */}
									<Link to={`/help-center/${article.id}`} className="block">
										<h3 className="text-lg font-semibold text-gray-800 hover:text-gray-800 hover:underline pb-1">
											{article.title}
										</h3>
									</Link>

									{/* Author */}
									<p className="text-sm text-gray-500 pb-3">
										By {article.author || 'Unknown'}
									</p>

									{/* Synopsis */}
									<p className="text-gray-700 text-sm line-clamp-3">
										{article.synopsis}
									</p>
								</div>

								{/* Action Section */}
								<div className="mt-auto flex justify-end">
									<Link
										to={`/help-center/${article.id}`}
										className="text-blue-500 font-medium text-sm hover:underline"
									>
										Read more →
									</Link>
									{/* <button 
										className="text-gray-500 hover:text-gray-700 text-sm flex items-center"
										onClick={() => shareArticle(article.id)}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-4 w-4 mr-1"
											viewBox="0 0 20 20"
											fill="currentColor"
										>
											<path d="M15 8a3 3 0 10-1.878-5.374L5.84 5.537a3 3 0 100 8.926l7.282 2.906A3 3 0 1015 8z" />
										</svg>
										Share
									</button> 
									*/}
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
}

HelpCenterIndexCard.propTypes = {
	get_articles_endpoint: PropTypes.string.isRequired
};
