import React from 'react';
import HorizontalBarChart from '../../Charts/HorizontalBarChart';
import CircularBarChart from '../../Charts/CircularBarChart';

const AthleticismScoreCard = ({
	player,
	playerPosition,
	bar2Label,
	bar2Value,
	lowerRange,
	upperRange
}) => {
	return (
		<div className="flex flex-wrap xl:flex-nowrap border border-gray-300 rounded-xl shadow-lg bg-white">
			<div className="pt-6 pb-4 md:py-6 md:pl-12 md:pr-3 flex flex-wrap items-center justify-between w-full">
				<div className="flex items-center justify-center w-full lg:w-auto">
					<HorizontalBarChart
						title="40 Time"
						position={playerPosition}
						bar1Label={
							player[0] && player[0].last_name ? player[0].last_name : ''
						}
						bar1Value={
							player[0] && player[0].forty_time ? player[0].forty_time : ''
						}
						bar2Label={bar2Label}
						bar2Value={bar2Value}
						lowerRange={lowerRange}
						upperRange={upperRange}
					/>
				</div>
				<div className="pt-16 px-5 xl:pt-0 md:pl-0 md:pr-1.5 flex flex-wrap items-center justify-center lg:justify-between xl:flex-row space-y-6 sm:space-y-0 xl:space-x-6">
					<CircularBarChart
						title={playerPosition === 'quarterback' ? 'Accuracy' : 'Size/Speed'}
						chartSize="md"
						maxPossible={10}
						value={
							player[0] ? player[0].position === 'quarterback' ? (
								player[0].accuracy_grade || 0
							) : (
								player[0].speed_grade || 0
							) : (
								0
							)
						}
						infoIconText="This score is calculated based on the combination of the player's height, weight, and 40 time. It provides context to the raw 40 time by considering the player's size, resulting in a more accurate representation of their speed."
					/>
					<CircularBarChart
						title={
							playerPosition === 'quarterback' ? 'Mobility' : 'Explosiveness'
						}
						chartSize="md"
						value={
							player[0] ? player[0].position === 'quarterback' ? (
								player[0].mobility_grade || 0
							) : (
								player[0].burst_grade || 0
							) : (
								0
							)
						}
						maxPossible={10}
						infoIconText="This score is calculated based on a combination of the player's vertical jump and broad jump, which are widely accepted measures of a player's explosiveness."
					/>
					<CircularBarChart
						title={playerPosition === 'quarterback' ? 'Playmaking' : 'Agility'}
						chartSize="md"
						value={
							player[0] ? player[0].position === 'quarterback' ? (
								player[0].quarterbacking_grade || 0
							) : (
								player[0].agility_grade || 0
							) : (
								0
							)
						}
						maxPossible={10}
						infoIconText="This score is calculated based on a combination of the player's 3-cone drill and shuttle times, which are widely accepted measures of a player's lateral quickness and change of direction ability."
					/>
				</div>
			</div>
		</div>
	);
};

export default AthleticismScoreCard;
