import React, { forwardRef } from 'react';
import PricingTable from '../PricingTable/PricingTable';

const PricingTableModal = forwardRef(
	(
		{
			isOpen,
			onClose,
			create_checkout_session_endpoint,
			stripe_public_key,
			stripe_product_essential_id,
			stripe_product_advanced_id,
			stripe_product_ultimate_id,
			isProspectProfileCard
		},
		ref
	) => {
		if (!isOpen) return null;

		return (
			<div
				className="fixed inset-0 flex justify-center items-center"
				style={{
					zIndex: 1000,
					backgroundColor: 'rgba(0, 0, 0, 0.5)'
				}}
			>
				<div
					ref={ref}
					className="relative bg-white rounded-lg p-6 shadow-lg"
					style={{ width: 'auto', maxHeight: '90%', overflowY: 'auto' }}
				>
					{isProspectProfileCard ? null : (
						<button className="absolute top-0 right-0 p-4" onClick={onClose}>
							<svg
								className="h-6 w-6 text-gray-500"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					)}
					<h2 className="text-2xl font-bold mb-4">Our Pricing</h2>
					<div className="overflow-x-auto">
						<div className="flex flex-col">
							<PricingTable
								create_checkout_session_endpoint={
									create_checkout_session_endpoint
								}
								stripe_public_key={stripe_public_key}
								stripe_product_essential_id={stripe_product_essential_id}
								stripe_product_advanced_id={stripe_product_advanced_id}
								stripe_product_ultimate_id={stripe_product_ultimate_id}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
);

export default PricingTableModal;
