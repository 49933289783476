import React from 'react';

export default class FormSubmit extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="FormSubmit">
				<button
					onClick={this.props.onSubmit}
					className="w-full md:w-68 text-center text-lg rounded-md bg-blue-500 hover:bg-blue-700 text-white"
					disabled={this.props.disabled}
				>
					<p className="text-lg py-2 px-8">
						{this.props.label || 'Save'}
					</p>
				</button>
			</div>
		);
	}
}
