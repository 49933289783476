import React from 'react';
import NewsCenterIndexCard from '../../News/NewsCenter/NewsCenterIndexCard/NewsCenterIndexCard';

export default function NewsCenterIndexPage({ get_articles_endpoint }) {
	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="mt-4">
				<div className="grid grid-cols-1">
					<NewsCenterIndexCard get_articles_endpoint={get_articles_endpoint} />
				</div>
			</div>
		</div>
	);
}
