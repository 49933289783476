import React, { Fragment } from 'react';
import MobileDataTableCard from '../../../../../DataTables/MobileDataTableCard';
import AdvancedDataTable from '../../../../../DataTables/AdvancedDataTable';
import getFormattedNumberWithComma from '../../../../../../utils/getFormattedNumberWithComma';

export default function QuarterbackProBasicRushingStatsTable(props) {
	return (
		<div>
			<div className="text-xl py-2">
				<h4>{props.title}</h4>
			</div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={props.data}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Category',
								apiName: 'category',
								alignment: 'left'
							},
							{
								displayName: 'Games Played',
								apiName: 'pro_games_played',
								alignment: 'left'
							},
							{
								displayName: 'Rush Attempts',
								apiName: 'pro_rush_attempts',
								alignment: 'left'
							},
							{
								displayName: 'Rushing Yards',
								apiName: 'pro_rushing_yards',
								alignment: 'left'
							},
							{
								displayName: 'Yards Per Attempt',
								apiName: 'pro_yards_per_attempt',
								alignment: 'left'
							},
							{
								displayName: 'Rushing TDs',
								apiName: 'pro_rushing_touchdowns',
								alignment: 'left'
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div className="flex flex-wrap">
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[1].displayName}
									</div>
									<div className="w-1/2 mb-2">{row.pro_games_played}</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[2].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{getFormattedNumberWithComma(row.pro_rush_attempts)}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[3].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{getFormattedNumberWithComma(row.pro_rushing_yards)}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[4].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_rushing_yards_per_attempt}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[5].displayName}
									</div>
									<div className="w-1/2">{row.pro_rushing_touchdowns}</div>
								</div>
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={props.data}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					columns={[
						{
							displayName: 'Category',
							apiName: 'category',
							alignment: 'left'
						},
						{
							displayName: 'Games Played',
							apiName: 'pro_games_played',
							alignment: 'left'
						},
						{
							displayName: 'Rush Attempts',
							apiName: 'pro_rush_attempts',
							alignment: 'left'
						},
						{
							displayName: 'Rushing Yards',
							apiName: 'pro_rushing_yards',
							alignment: 'left'
						},
						{
							displayName: 'Yards Per Attempt',
							apiName: 'pro_yards_per_attempt',
							alignment: 'left'
						},
						{
							displayName: 'Rushing Touchdowns',
							apiName: 'pro_rushing_touchdowns',
							alignment: 'left'
						}
					]}
					rowRenderer={(row) => {
						return (
							<Fragment>
								<tr key={row.id} className="bg-white border-t text-gray-800">
									<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10">
										2024
									</td>
									<td className="pl-8 py-3 text-left">
										{row.current_year_pro_games_played ? (
											row.current_year_pro_games_played
										) : (
											0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{getFormattedNumberWithComma(
											row.current_year_pro_rush_attempts
												? row.current_year_pro_rush_attempts
												: 0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{getFormattedNumberWithComma(
											row.current_year_pro_rushing_yards
												? row.current_year_pro_rushing_yards
												: 0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{row.current_year_pro_rushing_yards_per_attempt ? (
											row.current_year_pro_rushing_yards_per_attempt
										) : (
											0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{row.current_year_pro_rushing_touchdowns ? (
											row.current_year_pro_rushing_touchdowns
										) : (
											0
										)}
									</td>
								</tr>
								<tr key={row.id} className="bg-white border-t text-gray-800">
									<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10">
										Career
									</td>
									<td className="pl-8 py-3 text-left">
										{row.pro_games_played ? row.pro_games_played : 0}
									</td>
									<td className="pl-8 py-3 text-left">
										{getFormattedNumberWithComma(
											row.pro_rush_attempts ? row.pro_rush_attempts : 0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{getFormattedNumberWithComma(
											row.pro_rushing_yards ? row.pro_rushing_yards : 0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{row.pro_rushing_yards_per_attempt ? (
											row.pro_rushing_yards_per_attempt
										) : (
											0
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{row.pro_rushing_touchdowns ? (
											row.pro_rushing_touchdowns
										) : (
											0
										)}
									</td>
								</tr>
							</Fragment>
						);
					}}
				/>
			</div>
		</div>
	);
}
