import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import FilterByPosition from '../../Filters/FilterByPosition';
import FilterBySearchInput from '../../Filters/FilterBySearchInput';
import FilterToggle from '../../Filters/FilterToggle';
import ClearFiltersButton from '../../Filters/ClearFilterButton/ClearFiltersButton';
import SortByCategoryDropdown from '../../Sorts/SortByCategory/SortByCategoryDropdown';
import CollegeStatsButtons from '../../Buttons/CollegeStatsButtons/CollegeStatsButtons';
import ProspectsProfileStatsTable from '../../Players/DataTables/ProspectsProfileStatsTable/ProspectsProfileStatsTable';
import QuarterbacksCollegeBasicStatsTable from '../../Players/DataTables/Quarterbacks/QuarterbacksCollegeBasicStatsTable/QuarterbacksCollegeBasicStatsTable';
import QuarterbacksCollegeAdvancedStatsTable from '../../Players/DataTables/Quarterbacks/QuarterbacksCollegeAdvancedStatsTable/QuarterbacksCollegeAdvancedStatsTable';
import RunningBacksCollegeBasicStatsTable from '../../Players/DataTables/RunningBacks/RunningBacksCollegeBasicStatsTable/RunningBacksCollegeBasicStatsTable';
import RunningBacksCollegeAdvancedStatsTable from '../../Players/DataTables/RunningBacks/RunningBacksCollegeAdvancedStatsTable/RunningBacksCollegeAdvancedStatsTable';
import WideReceiversCollegeBasicStatsTable from '../../Players/DataTables/WideReceivers/WideReceiversCollegeBasicStatsTable/WideReceiversCollegeBasicStatsTable';
import WideReceiversCollegeAdvancedStatsTable from '../../Players/DataTables/WideReceivers/WideReceiversCollegeAdvancedStatsTable/WideReceiversCollegeAdvancedStatsTable';
import TightEndsCollegeBasicStatsTable from '../../Players/DataTables/TightEnds/TightEndsCollegeBasicStatsTable/TightEndsCollegeBasicStatsTable';
import TightEndsCollegeAdvancedStatsTable from '../../Players/DataTables/TightEnds/TightEndsCollegeAdvancedStatsTable/TightEndsCollegeAdvancedStatsTable';

export default function ProspectsIndexCard({ get_prospects_endpoint }) {
	// State variable for prospects
	const [
		prospects,
		setProspects
	] = useState([]);

	// State variables for selected college stats type ('basic' or 'advanced')
	const [
		selectedCollegeStatsType,
		setSelectedCollegeStatsType
	] = useState(null);

	// State variables for filtering players
	const [
		isOnlyQuarterbackSelected,
		setIsOnlyQuarterbackSelected
	] = useState(false);
	const [
		isOnlyRunningBackSelected,
		setIsOnlyRunningBackSelected
	] = useState(false);
	const [
		isOnlyWideReceiverSelected,
		setIsOnlyWideReceiverSelected
	] = useState(false);
	const [
		isOnlyTightEndSelected,
		setIsOnlyTightEndSelected
	] = useState(false);
	const [
		isHeatmapEnabled,
		setIsHeatmapEnabled
	] = useState(false);
	const [
		isHeatmapAgeCategoryEnabled,
		setIsHeatmapAgeCategoryEnabled
	] = useState(true);
	const [
		isHeatmap40TimeCategoryEnabled,
		setIsHeatmap40TimeCategoryEnabled
	] = useState(true);
	const [
		isHeatmapFFScoresCategoryEnabled,
		setIsHeatmapFFScoresCategoryEnabled
	] = useState(true);

	const handleHeatmapToggle = () => {
		setIsHeatmapEnabled((prevHeatmapState) => !prevHeatmapState);
	};

	const handleOptionChange = (key) => {
		switch (key) {
			case 'age':
				setIsHeatmapAgeCategoryEnabled(!isHeatmapAgeCategoryEnabled);
				break;
			case 'time':
				setIsHeatmap40TimeCategoryEnabled(!isHeatmap40TimeCategoryEnabled);
				break;
			case 'scores':
				setIsHeatmapFFScoresCategoryEnabled(!isHeatmapFFScoresCategoryEnabled);
				break;
			default:
				break;
		}
	};

	useEffect(
		() => {
			const allOptionsDisabled =
				!isHeatmapAgeCategoryEnabled &&
				!isHeatmap40TimeCategoryEnabled &&
				!isHeatmapFFScoresCategoryEnabled;
			if (allOptionsDisabled && isHeatmapEnabled) {
				setIsHeatmapEnabled(false);
				setIsHeatmapAgeCategoryEnabled(true);
				setIsHeatmap40TimeCategoryEnabled(true);
				setIsHeatmapFFScoresCategoryEnabled(true);
			}
		},
		[
			isHeatmapAgeCategoryEnabled,
			isHeatmap40TimeCategoryEnabled,
			isHeatmapFFScoresCategoryEnabled
		]
	);

	const heatmapOptions = [
		{ key: 'age', label: 'Age', value: isHeatmapAgeCategoryEnabled },
		{ key: 'time', label: '40 Time', value: isHeatmap40TimeCategoryEnabled },
		{
			key: 'scores',
			label: 'FF Scores',
			value: isHeatmapFFScoresCategoryEnabled
		}
	];

	// Filter and sort state variables
	const [
		filter,
		setFilter
	] = useState({ position: [] });
	const [
		sortBy,
		setSortBy
	] = useState('prospect_grade');
	const [
		sortOrder,
		setSortOrder
	] = useState('desc');
	const [
		search,
		setSearch
	] = useState('');

	useEffect(
		() => {
			fetchProspects();

			if (
				selectedCollegeStatsType === 'basic' ||
				selectedCollegeStatsType === 'advanced'
			) {
				fetchProspects();
			}
		},
		[
			filter,
			sortBy,
			sortOrder,
			search
		]
	);

	const fetchProspects = () => {
		window.scrollTo(0, 0); // Scroll to the top of the window when the component mounts

		let url = `${get_prospects_endpoint}?position=${filter.position.join(
			','
		)}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`;
		fetch(url)
			.then((response) => response.json())
			.then((result) => {
				setProspects(result.players || []);
			})
			.catch((error) => {
				console.error('Error fetching prospects:', error);
			});
	};

	// Need to fetch players from the backend when the player is selected
	const handleFilterChange = (filterKey, value) => {
		setFilter((prevFilter) => ({ ...prevFilter, [filterKey]: value }));
	};

	const handleSearchInputChange = (event) => {
		setSearch(event.target.value);
	};

	const handleSortChange = (event) => {
		const { value } = event.target;
		setSortBy(value);
		// If sorting on a different column, reset default sort order to 'asc'
		if (value !== sortBy) {
			setSortOrder('asc');
		}
	};

	const handleSortOrderChange = (sortColumn, sortOrder) => {
		if (sortOrder === '' || sortOrder === 'desc') {
			setSortBy(sortColumn);
			setSortOrder('asc');
		} else if (sortOrder === 'asc') {
			setSortBy(sortColumn);
			setSortOrder('desc');
		}
	};

	const handleToggleSortOrder = () => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	};

	const handleCollegeBasicStatsClick = () => {
		setSelectedCollegeStatsType(
			(prevType) => (prevType === 'basic' ? null : 'basic')
		);
	};

	const handleCollegeAdvancedStatsClick = () => {
		setSelectedCollegeStatsType(
			(prevType) => (prevType === 'advanced' ? null : 'advanced')
		);
	};

	const handleClearFilters = () => {
		setFilter({ position: [] });
		setSortBy('prospect_grade');
		setSortOrder('desc');
		setSearch('');
		setSelectedCollegeStatsType(null);
		setIsOnlyQuarterbackSelected(false);
		setIsOnlyRunningBackSelected(false);
		setIsOnlyWideReceiverSelected(false);
		setIsOnlyTightEndSelected(false);
		setIsHeatmapEnabled(false);
		setIsHeatmapAgeCategoryEnabled(true);
		setIsHeatmap40TimeCategoryEnabled(true);
		setIsHeatmapFFScoresCategoryEnabled(true);
	};

	useEffect(
		() => {
			if (
				filter.position.length === 1 &&
				filter.position[0] === 'quarterback'
			) {
				setIsOnlyQuarterbackSelected(true);
			} else {
				setIsOnlyQuarterbackSelected(false);
				setSelectedCollegeStatsType(null);
			}
		},
		[
			filter.position
		]
	);

	useEffect(
		() => {
			if (
				filter.position.length === 1 &&
				filter.position[0] === 'running back'
			) {
				setIsOnlyRunningBackSelected(true);
			} else {
				setIsOnlyRunningBackSelected(false);
				setSelectedCollegeStatsType(null);
			}
		},
		[
			filter.position
		]
	);

	useEffect(
		() => {
			if (
				filter.position.length === 1 &&
				filter.position[0] === 'wide receiver'
			) {
				setIsOnlyWideReceiverSelected(true);
			} else {
				setIsOnlyWideReceiverSelected(false);
				setSelectedCollegeStatsType(null);
			}
		},
		[
			filter.position
		]
	);

	useEffect(
		() => {
			if (filter.position.length === 1 && filter.position[0] === 'tight end') {
				setIsOnlyTightEndSelected(true);
			} else {
				setIsOnlyTightEndSelected(false);
				setSelectedCollegeStatsType(null);
			}
		},
		[
			filter.position
		]
	);

	return (
		<div>
			<div className="flex flex-col lg:flex-row lg:mb-4">
				{/* Filter components */}
				<FilterByPosition
					filter={filter}
					onChange={handleFilterChange}
					positionButtons={[
						{ label: 'All', value: 'all' },
						{ label: 'QB', value: 'quarterback' },
						{ label: 'RB', value: 'running back' },
						{ label: 'WR', value: 'wide receiver' },
						{ label: 'TE', value: 'tight end' }
					]}
				/>
				<div className="hidden lg:contents">
					<FilterBySearchInput
						value={search}
						label="Filter By Name"
						placeholder="Search for a prospect"
						onChange={handleSearchInputChange}
						onEnter={fetchProspects}
					/>
					<div className="md:ml-4 md:mr-4">
						<FilterToggle
							label="Heatmap"
							icon={<i className="fa-solid fa-map" />}
							isChecked={isHeatmapEnabled}
							onToggle={handleHeatmapToggle}
							options={heatmapOptions}
							onOptionChange={handleOptionChange}
						/>
					</div>
				</div>

				{/* Mobile Filter components */}
				<div className="lg:hidden contents">
					<FilterBySearchInput
						value={search}
						label="Filter by Name"
						placeholder="Search for a player"
						onChange={handleSearchInputChange}
						onEnter={fetchProspects}
					/>
					<SortByCategoryDropdown
						sortBy={sortBy}
						handleSortChange={handleSortChange}
						handleToggleSortOrder={handleToggleSortOrder}
						sortOrder={sortOrder}
					/>
				</div>
				<div className="hidden lg:contents">
					<ClearFiltersButton onClick={handleClearFilters} />
				</div>
			</div>

			<div className="p-6 lg:p-8 border border-gray-300 rounded-xl shadow-lg bg-white">
				{/* College Stats Buttons */}
				{(isOnlyQuarterbackSelected ||
					isOnlyRunningBackSelected ||
					isOnlyWideReceiverSelected ||
					isOnlyTightEndSelected) && (
					<CollegeStatsButtons
						selectedCollegeStatsType={selectedCollegeStatsType}
						handleCollegeBasicStatsClick={handleCollegeBasicStatsClick}
						handleCollegeAdvancedStatsClick={handleCollegeAdvancedStatsClick}
					/>
				)}

				{/* PlayersProfileStatsTable */}
				{selectedCollegeStatsType === null && (
					<ProspectsProfileStatsTable
						prospects={prospects}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
						isHeatmapEnabled={isHeatmapEnabled}
						isHeatmapAgeCategoryEnabled={isHeatmapAgeCategoryEnabled}
						isHeatmap40TimeCategoryEnabled={isHeatmap40TimeCategoryEnabled}
						isHeatmapFFScoresCategoryEnabled={isHeatmapFFScoresCategoryEnabled}
					/>
				)}

				{/* QuarterbacksCollegeBasicStatsTable */}
				{selectedCollegeStatsType === 'basic' &&
				isOnlyQuarterbackSelected && (
					<QuarterbacksCollegeBasicStatsTable
						players={prospects}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* QuarterbacksCollegeAdvancedStatsTable */}
				{selectedCollegeStatsType === 'advanced' &&
				isOnlyQuarterbackSelected && (
					<QuarterbacksCollegeAdvancedStatsTable
						players={prospects}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* RunningBacksCollegeBasicStatsTable */}
				{selectedCollegeStatsType === 'basic' &&
				isOnlyRunningBackSelected && (
					<RunningBacksCollegeBasicStatsTable
						players={prospects}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* RunningBacksCollegeAdvancedStatsTable */}
				{selectedCollegeStatsType === 'advanced' &&
				isOnlyRunningBackSelected && (
					<RunningBacksCollegeAdvancedStatsTable
						players={prospects}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* WideReceiversCollegeBasicStatsTable */}
				{selectedCollegeStatsType === 'basic' &&
				isOnlyWideReceiverSelected && (
					<WideReceiversCollegeBasicStatsTable
						players={prospects}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* WideReceiversCollegeAdvancedStatsTable */}
				{selectedCollegeStatsType === 'advanced' &&
				isOnlyWideReceiverSelected && (
					<WideReceiversCollegeAdvancedStatsTable
						players={prospects}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* TightEndsCollegeBasicStatsTable */}
				{selectedCollegeStatsType === 'basic' &&
				isOnlyTightEndSelected && (
					<TightEndsCollegeBasicStatsTable
						players={prospects}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* TightEndsCollegeAdvancedStatsTable */}
				{selectedCollegeStatsType === 'advanced' &&
				isOnlyTightEndSelected && (
					<TightEndsCollegeAdvancedStatsTable
						players={prospects}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}
			</div>
		</div>
	);
}
