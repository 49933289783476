import React from 'react';

function CoachesCard({ team }) {
	return (
		<div className="p-6 lg:p-8 border border-gray-300 rounded-xl shadow-lg bg-white">
			<div className="grid lg:grid-cols-2 gap-6 bg-gray-50 px-4 lg:px-8 pt-4 rounded-xl border">
				<div className="lg:py-3">
					<p className="text-xl font-semibold mb-2">{team.head_coach}</p>
					<h2 className="text-gray-600 mb-4">Head Coach</h2>
					<div className="text-gray-600 mb-2">{team.head_coach_summary}</div>
				</div>
				<div className="lg:py-3">
					<p className="text-xl font-semibold mb-2">
						{team.offensive_coordinator}
					</p>
					<h2 className="text-gray-600 mb-4">Offensive Coordinator</h2>
					<div className="text-gray-600 mb-2">
						{team.offensive_coordinator_summary}
					</div>
				</div>
			</div>
		</div>
	);
}

export default CoachesCard;
