import React from 'react';
import ReactApexChart from 'react-apexcharts';

const Week17TeamStacksCard = ({ players }) => {
	// Group players by team
	const playersByTeam = players.reduce((acc, player) => {
		const teamName = player.team.name; // Assuming player.team.name gives the team name

		if (teamName) {
			if (!acc[teamName]) acc[teamName] = [];
			acc[teamName].push(`${player.first_name} ${player.last_name}`);
		}

		return acc;
	}, {});

	// Filter out teams with fewer than two players
	const filteredTeams = Object.entries(playersByTeam).filter(
		(
			[
				teamName,
				players
			]
		) => players.length >= 2
	);

	// Prepare data for the treemap chart
	const treemapData = filteredTeams.map(([ teamName, players
	]) => ({
		x: `${teamName}`, // Team name with player count (without 'players' label)
		y: players.length, // Number of players
		players: players.join(', ') // Join player names for tooltip
	}));

	// Chart configuration for the treemap
	const chartData = {
		series: [
			{
				data: treemapData
			}
		],
		options: {
			chart: {
				type: 'treemap',
				toolbar: {
					show: false
				},
				height: 208
			},
			legend: {
				show: false
			},
			tooltip: {
				custom: ({ seriesIndex, dataPointIndex, w }) => {
					const teamInfo = w.config.series[seriesIndex].data[dataPointIndex];
					return `
						<div class="px-2 py-1 bg-white shadow-lg border border-gray-300 rounded text-black">
							<strong>${teamInfo.x}</strong>
							<ul>
								${teamInfo.players
									.split(', ')
									.map((player) => `<li style="color: black;">${player}</li>`)
									.join('')}
							</ul>
						</div>
					`;
				}
			}
		}
	};

	return (
		<div className="relative bg-white p-4 rounded-lg shadow-lg border border-gray-300 cursor-pointer">
			{/* Title */}
			<h3 className="text-sm font-medium text-gray-800 text-center">
				Week 17 Team Stacks
			</h3>
			{/* Card Content */}
			<div className="flex justify-center items-center">
				<ReactApexChart
					options={chartData.options}
					series={chartData.series}
					type="treemap"
					height={208}
				/>
			</div>
		</div>
	);
};

export default Week17TeamStacksCard;
