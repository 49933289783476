import React, { useEffect, useRef } from 'react';

const SuccessModal = ({ visible, onClose, message }) => {
	const modalRef = useRef(null);

	const handleClickOutsideModal = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutsideModal);
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideModal);
		};
	}, []);

	if (!visible) return null;

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
			<div
				ref={modalRef}
				className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center relative transition-all duration-300 w-80"
			>
				{/* Close Button */}
				<button
					className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-100 transition duration-300"
					onClick={onClose}
				>
					<svg
						className="h-6 w-6 text-gray-400 hover:text-gray-600"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>

				{/* Success Icon */}
				<i className="fa-regular fa-circle-check text-green-500 text-3xl" />
				{/* Success Message */}
				<h2 className="text-xl text-green-600">
					{message ? 'Success.' : 'Saved.'}
				</h2>
				{message ? (
					<p className="text-gray-600 text-center mt-6">{message}</p>
				) : null}
			</div>
		</div>
	);
};

export default SuccessModal;
