import React from 'react';
import CircularBarChart from '../../Charts/CircularBarChart';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';

const ProspectScoreCard = ({
	player,
	gradeRanks,
	handleRankClick,
	visibleRankModal,
	handlePlayerClick,
	rankModalRef,
	currentPlayerRef,
	loading
}) => {
	return (
		<div className="flex flex-wrap border border-gray-300 rounded-xl shadow-lg bg-white">
			<div className="pt-6 px-5 md:px-14 flex flex-col md:flex-row items-start justify-center lg:justify-between w-full">
				<div className="w-full md:w-1/3 lg:w-auto flex flex-col items-center">
					<CircularBarChart
						loading={loading}
						title="Prospect Score™"
						chartSize="xl"
						value={
							player[0] && player[0].prospect_grade ? (
								player[0].prospect_grade
							) : (
								0
							)
						}
						maxPossible={10}
						infoIconText="This is the player's Fantasy Fitness Prospect Score™. It attempts to estimate the player's potential value in the NFL based on their college performance and athleticism."
						rank={
							player[0] && player[0].prospect_grade_position_rank ? (
								player[0].prospect_grade_position_rank
							) : (
								999
							)
						}
						abbr_position={getPositionAbbreviation(
							player[0] && player[0].position ? player[0].position : ''
						)}
						gradeRanks={
							gradeRanks && gradeRanks.prospect_grades ? (
								gradeRanks.prospect_grades
							) : (
								[]
							)
						}
						handleRankClick={() =>
							handleRankClick(`fantasy_fitness_prospect_score_${player[0].id}`)}
						isRankVisible={
							visibleRankModal ===
							`fantasy_fitness_prospect_score_${player[0].id}`
						}
						rankModalRef={rankModalRef}
						currentPlayerRef={currentPlayerRef}
						handlePlayerClick={handlePlayerClick}
						currentPlayerId={player[0] && player[0].id ? player[0].id : 0}
						hasLabel={true}
						hasRankModalEnabled={true}
					/>
				</div>

				{/* Prospects strength and weaknesses bullet points */}
				<div className="flex flex-col xl:flex-row w-full md:w-2/3 space-x-0 xl:space-x-8 space-y-8 xl:space-y-0 py-4 md:pb-12 mt-0">
					{/* Strengths Section */}
					<div className="flex-1 bg-green-50 rounded-lg p-4 shadow-md">
						<h3 className="font-semibold text-lg mb-2 text-green-700">
							Strengths
						</h3>
						<div
							className="text-gray-700"
							dangerouslySetInnerHTML={{
								__html:
									player[0] && player[0].strengths
										? player[0].strengths
										: '<p>No strengths listed for this player.</p>'
							}}
						/>
					</div>
					{/* Weaknesses Section */}
					<div className="flex-1 bg-red-50 rounded-lg p-4 shadow-md">
						<h3 className="font-semibold text-lg mb-2 text-red-700">
							Weaknesses
						</h3>
						<div
							className="text-gray-700"
							dangerouslySetInnerHTML={{
								__html:
									player[0] && player[0].weaknesses
										? player[0].weaknesses
										: '<p>No weaknesses listed for this player.</p>'
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProspectScoreCard;
