import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const TopHeadlinesCard = ({ get_articles_endpoint }) => {
	const [
		topArticles,
		setTopArticles
	] = useState([]);

	useEffect(
		() => {
			const fetchArticles = async () => {
				const url = `${get_articles_endpoint}?article_type=News`;
				const response = await fetch(url);
				if (response.ok) {
					const data = await response.json();
					setTopArticles(data.data.slice(0, 8));
				}
			};

			fetchArticles();
		},
		[
			get_articles_endpoint
		]
	);

	return (
		<div className="bg-white border border-gray-200 shadow-lg rounded-xl">
			<div className="mx-4 py-4 border-b border-gray-200">
				<h2 className="text-lg font-bold text-gray-800">
					<i className="fa-solid fa-newspaper text-lg mr-3" />
					<Link to="/news" className="hover:underline">
						Top Headlines
					</Link>
				</h2>
			</div>
			<ul className="divide-y divide-gray-200 px-4">
				{topArticles.map((article, index) => (
					<li
						key={index}
						className="py-3 cursor-pointer flex justify-between items-center"
					>
						<a
							href={article.url}
							className="block text-sm font-medium text-gray-800 truncate hover:underline"
							title={article.title}
							target="_blank"
							rel="noopener noreferrer"
						>
							{article.title}
						</a>
						<span className="text-xs text-gray-500">{article.publisher}</span>
					</li>
				))}
			</ul>
			<div className="mx-4 py-3 border-t border-gray-200 text-center">
				<Link
					to="/news"
					className="text-sm text-blue-500 hover:underline"
				>
					See all news →
				</Link>
			</div>
		</div>
	);
};

export default TopHeadlinesCard;
