import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PlayerPortraitCard from '../../Player/PlayerPortraitCard/PlayerPortraitCard';
import TeamPortraitCard from '../../Team/TeamPortraitCard/TeamPortraitCard';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

const DashboardSpotlightCarousel = ({
	get_player_endpoint,
	get_team_endpoint,
	get_prospect_endpoint
}) => {
	const [
		spotlightPlayer,
		setSpotlightPlayer
	] = useState(null);
	const [
		spotlightTeam,
		setSpotlightTeam
	] = useState(null);
	const [
		spotlightProspect,
		setSpotlightProspect
	] = useState(null);
	const [
		currentSpotlight,
		setCurrentSpotlight
	] = useState('player');
	const [
		temperature,
		setTemperature
	] = useState({ temp: null, weather: null });
	const [
		rotationTimer,
		setRotationTimer
	] = useState(null);

	useEffect(() => {
		const fetchSpotlightData = async () => {
			try {
				const spotlightResponse = await fetch('/api/v1/spotlights');
				if (!spotlightResponse.ok) {
					throw new Error('Failed to fetch spotlight data');
				}

				const { data: spotlightData } = await spotlightResponse.json();

				if (spotlightData) {
					const {
						player_id,
						team_id,
						prospect_id,
						player_position,
						prospect_position
					} = spotlightData;

					if (player_id && player_position) {
						const playerResponse = await fetch(
							`${get_player_endpoint.replace(
								'id',
								player_id
							)}?position=${player_position}`
						);
						if (playerResponse.ok) {
							const playerResult = await playerResponse.json();
							setSpotlightPlayer([
								playerResult.data
							]);
						}
					}

					if (team_id) {
						const teamResponse = await fetch(
							get_team_endpoint.replace('id', team_id)
						);
						if (teamResponse.ok) {
							const teamResult = await teamResponse.json();
							if (teamResult && teamResult.data && teamResult.data.team) {
								setSpotlightTeam(teamResult.data.team); // Explicit check for team
							}
						}
					}

					if (prospect_id && prospect_position) {
						const prospectResponse = await fetch(
							`${get_prospect_endpoint.replace(
								'id',
								prospect_id
							)}?position=${prospect_position}`
						);
						if (prospectResponse.ok) {
							const prospectResult = await prospectResponse.json();
							setSpotlightProspect([
								prospectResult.data
							]);
						}
					}
				} else {
					console.error('Spotlight data is missing or malformed');
				}
			} catch (error) {
				console.error('Error fetching spotlight data:', error);
			}
		};

		fetchSpotlightData();
	}, []);

	const fetchTemperature = (location) => {
		let city = location.split(',')[0].trim();
		if (city === 'Tampa Bay') {
			city = 'Tampa';
		}
		fetch(
			`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${'336d6d9ed7689fcfacbc7a9cd10f4250'}`
		)
			.then((response) => response.json())
			.then((data) => {
				if (
					data.main &&
					data.main.temp &&
					data.weather &&
					data.weather.length > 0
				) {
					// Convert temperature to Fahrenheit
					const tempInFahrenheit = Math.round(
						(data.main.temp - 273.15) * 9 / 5 + 32
					);
					// Get weather description
					const weatherDescription = data.weather[0].main;
					setTemperature({
						temp: tempInFahrenheit,
						weather: weatherDescription
					});
				}
			})
			.catch((error) => {
				console.error('Error fetching temperature:', error);
			});
	};

	useEffect(
		() => {
			if (spotlightTeam && spotlightTeam.location) {
				fetchTemperature(spotlightTeam.location);
			}
		},
		[
			spotlightTeam
		]
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentSpotlight(
				(prev) =>
					prev === 'player' ? 'team' : prev === 'team' ? 'prospect' : 'player'
			);
		}, 8000);

		setRotationTimer(interval);

		return () => {
			clearInterval(interval);
		};
	}, []);

	const handleNext = () => {
		setCurrentSpotlight(
			(prev) =>
				prev === 'player' ? 'team' : prev === 'team' ? 'prospect' : 'player'
		);
		clearInterval(rotationTimer);
	};

	const handlePrevious = () => {
		setCurrentSpotlight(
			(prev) =>
				prev === 'player' ? 'prospect' : prev === 'team' ? 'player' : 'team'
		);
		clearInterval(rotationTimer);
	};

	return (
		<div className="bg-white shadow-lg rounded-xl flex flex-col h-full border border-gray-200 hover:shadow-xl transition-shadow duration-300 ease-in-out">
			<div className="mx-4 py-4 border-b border-gray-200">
				<h2 className="text-lg font-semibold text-gray-800">
					<i className="fa-solid fa-star mr-3" />
					<Link
						className="hover:underline cursor-pointer"
						to={
							currentSpotlight === 'player' && spotlightPlayer ? (
								`/players/${spotlightPlayer[0].id}?position=${spotlightPlayer[0]
									.position}`
							) : currentSpotlight === 'team' && spotlightTeam ? (
								`/teams/${spotlightTeam.id}`
							) : currentSpotlight === 'prospect' && spotlightProspect ? (
								`/prospects/${spotlightProspect[0]
									.id}?position=${spotlightProspect[0].position}`
							) : (
								'#'
							)
						}
					>
						Today's{' '}
						{currentSpotlight === 'player' ? (
							'Player'
						) : currentSpotlight === 'team' ? (
							'Team'
						) : (
							'Prospect'
						)}{' '}
						Spotlight
					</Link>
				</h2>
			</div>

			<div className="flex-grow flex items-center justify-center relative">
				<button
					onClick={handlePrevious}
					className="absolute top-1/2 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-10 border border-gray-300"
					style={{ left: '-15px' }}
				>
					{'<'}
				</button>

				{currentSpotlight === 'player' ? spotlightPlayer ? (
					<PlayerPortraitCard player={spotlightPlayer} isDashboard={true} />
				) : (
					<div className="mt-3 text-sm text-gray-600">
						<LoadingSpinner />
					</div>
				) : currentSpotlight === 'team' ? spotlightTeam ? (
					<TeamPortraitCard
						team={spotlightTeam}
						temperature={temperature}
						isDashboard={true}
					/>
				) : (
					<div className="mt-3 text-sm text-gray-600">
						<LoadingSpinner />
					</div>
				) : currentSpotlight === 'prospect' ? spotlightProspect ? (
					<PlayerPortraitCard player={spotlightProspect} isDashboard={true} />
				) : (
					<div className="mt-3 text-sm text-gray-600">
						<LoadingSpinner />
					</div>
				) : null}

				<button
					onClick={handleNext}
					className="absolute top-1/2 transform -translate-y-1/2 bg-gray-200 p-3 rounded-md z-10 border border-gray-300"
					style={{ right: '-15px' }}
				>
					{'>'}
				</button>
			</div>

			<div className="mx-4 py-3 border-t border-gray-200 text-center">
				<Link
					to={
						currentSpotlight === 'player' ? (
							'/players'
						) : currentSpotlight === 'team' ? (
							'/teams'
						) : (
							'/prospects'
						)
					}
					className="text-blue-500 text-sm px-4 self-center mt-auto"
				>
					See all{' '}
					{currentSpotlight === 'player' ? (
						'players'
					) : currentSpotlight === 'team' ? (
						'teams'
					) : (
						'prospects'
					)}{' '}
					→
				</Link>
			</div>
		</div>
	);
};

export default DashboardSpotlightCarousel;
