import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts'; // Ensure this is installed

const TeamPlayerCountCard = ({
	playerTeamCount,
	players,
	isDraftModeEnabled
}) => {
	const [
		showChart,
		setShowChart
	] = useState(true); // Start with chart visible

	// Group players by team
	const playersByTeam = players.reduce((acc, player) => {
		const teamName = player.team ? player.team.name : 'Unknown'; // Get the player's team name
		if (!acc[teamName]) acc[teamName] = [];
		acc[teamName].push(`${player.first_name} ${player.last_name}`);
		return acc;
	}, {});

	// Log the players grouped by team for debugging
	// console.log('Players grouped by team: ', playersByTeam);

	// Prepare data for the treemap chart, using only the last word of each team name
	const treemapData = Object.entries(playerTeamCount).map(([ team, count
	]) => {
		// Get the last word of the team name
		const lastWord = team.split(' ').pop();
		return {
			x: lastWord, // Use the last word as the team name
			y: count,
			teamName: team // Store the full team name for tooltip access
		};
	});

	// Log the treemap data for debugging
	// console.log('Treemap data: ', treemapData);

	// Chart configuration for the treemap
	const chartData = {
		series: [
			{
				data: treemapData
			}
		],
		options: {
			chart: {
				type: 'treemap',
				toolbar: {
					show: false
				},
				height: 208
			},
			legend: {
				show: false
			},
			tooltip: !isDraftModeEnabled
				? {
						custom: ({ seriesIndex, dataPointIndex, w }) => {
							const teamName =
								w.config.series[seriesIndex].data[dataPointIndex].teamName; // Get the full team name
							const playerList = playersByTeam[teamName] || []; // Get the player names for that team

							// Log the tooltip information for debugging
							// console.log(`Hovering over team: ${teamName}`);
							// console.log('Players in this team: ', playerList);

							return `
			      <div class="px-2 py-1 bg-white shadow-lg border border-gray-300 rounded text-black">
							<strong>${teamName}</strong> <!-- Display full team name -->
							<ul>
								${playerList
									.map((player) => `<li style="color: black;">${player}</li>`)
									.join('')}
							</ul>
						</div>
					`;
						}
					}
				: {} // Default behavior if !isDraftModeEnabled is true
		}
	};

	return (
		<div className="relative bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			{/* Title */}
			<h3 className="text-sm font-medium text-gray-800 text-center">
				Team Player Breakdown
			</h3>
			{/* Card Content */}
			<div className="flex justify-center items-center">
				<div className="flex flex-col items-center">
					<ReactApexChart
						options={chartData.options}
						series={chartData.series}
						type="treemap"
						height={208}
					/>
				</div>
			</div>
		</div>
	);
};

export default TeamPlayerCountCard;
