
export default function RbAgeValueToColorHelper(age) {
	if (age <= 21) {
		return '#1ABE10';
	} else if (age <= 23) {
		return '#4BC818';
	} else if (age <= 25) {
		return '#7DD220';
	} else if (age <= 26) {
		return '#AFDC28';
	} else if (age <= 27) {
		return '#E1E630';
	} else if (age <= 28) {
		return '#EDCC2E';
	} else if (age <= 29) {
		return '#E9A227';
	} else if (age <= 30) {
		return '#E57820';
	} else if (age <= 31) {
		return '#E04E1A';
	} else if (age > 31) {
		return '#DC2413';
	}
}