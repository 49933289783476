import React, { useState } from 'react';
import getInjuryStatusColor from '../../utils/getInjuryStatusColor';

export default function StatusWidget({
	status,
	description,
	hideLabel,
	is50pXThumbnail,
	is75pxThumbnail
}) {
	// State for managing mobile tooltip visibility
	const [
		isTooltipVisible,
		setTooltipVisible
	] = useState(false);

	// Toggle tooltip visibility on click for mobile
	const handleTooltipToggle = () => {
		setTooltipVisible((prev) => !prev);
	};

	// Tooltip content
	const tooltipContent =
		status && description ? `${status} (${description})` : status;

	return (
		<div className="relative flex items-center">
			{/* Tooltip on hover or click (for mobile) */}
			{status && (
				<div
					className={`${is50pXThumbnail
						? 'h-2 w-2'
						: is75pxThumbnail
							? 'h-2.5 w-2.5'
							: 'h-3 w-3'} rounded-full inline-block cursor-pointer`}
					style={{ backgroundColor: getInjuryStatusColor(status) }}
					onMouseEnter={() => setTooltipVisible(true)} // Show tooltip on hover
					onMouseLeave={() => setTooltipVisible(false)} // Hide tooltip when hover ends
					onClick={handleTooltipToggle} // Toggle tooltip visibility on click (mobile)
				>
					{/* Tooltip box */}
					{isTooltipVisible && (
						<div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max rounded-sm bg-black text-white text-xs px-2 py-1 whitespace-nowrap z-10">
							{tooltipContent}
						</div>
					)}
				</div>
			)}
			{/* Text label */}
			<span className="ml-1.5">
				<span className="text-gray-500">
					{status && description && !hideLabel ? (
						`${status} (${description})`
					) : status && !hideLabel ? (
						status
					) : (
						''
					)}
				</span>
			</span>
		</div>
	);
}
