import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function CoursesIndexCard({ get_courses_endpoint }) {
	const [
		courses,
		setCourses
	] = useState([]);
	const [
		difficulty,
		setDifficulty
	] = useState([]);
	const [
		topics,
		setTopics
	] = useState([]);

	useEffect(
		() => {
			fetchCourses();
		},
		[
			difficulty,
			topics
		]
	);

	const fetchCourses = () => {
		window.scrollTo(0, 0); // Scroll to the top of the window when the component mounts

		let queryParams = '';

		if (difficulty.length) queryParams += `difficulty=${difficulty.join(',')}`;
		if (topics.length) queryParams += `&topics=${topics.join(',')}`;

		fetch(`${get_courses_endpoint}?${queryParams}`)
			.then((response) => response.json())
			.then((result) => {
				setCourses(result.data || []);
			})
			.catch((error) => {
				console.error('Error fetching courses:', error);
			});
	};

	const getDifficultyLevel = (level) => {
		const levels = { 0: 'Beginner', 1: 'Intermediate', 2: 'Advanced' };
		return levels[level] || 'Unknown';
	};

	return (
		<main>
			{/* Filter Section */}
			<div className="mb-6">
				<div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-start md:space-x-10">
					{/* Filter by Difficulty */}
					<div className="flex items-center space-x-2 md:space-x-4">
						<span className="font-semibold text-gray-700 whitespace-nowrap">
							Filter by Difficulty
						</span>
						<div className="flex flex-wrap space-x-2 md:space-x-2 space-y-2 md:space-y-0">
							{[
								'Beginner',
								'Intermediate',
								'Advanced'
							].map((level) => (
								<button
									key={level}
									onClick={() =>
										setDifficulty(
											difficulty.includes(level)
												? difficulty.filter((d) => d !== level)
												: [
														...difficulty,
														level
													]
										)}
									className={`bg-white px-2 md:px-3 py-1 rounded-full border ${difficulty.includes(
										level
									)
										? 'bg-blue-500 text-white'
										: 'border-gray-400 text-gray-700'}`}
								>
									{level}
								</button>
							))}
						</div>
					</div>

					{/* Filter by Topic */}
					<div className="flex items-center space-x-2 md:space-x-4">
						<span className="font-semibold text-gray-700 whitespace-nowrap">
							Filter by Topic
						</span>
						<div className="flex flex-wrap space-x-2 md:space-x-2 space-y-2 md:space-y-0">
							{[
								'Best Ball',
								'Dynasty',
								'Redraft',
								'Scoring'
							].map((topic) => (
								<button
									key={topic}
									onClick={() =>
										setTopics(
											topics.includes(topic)
												? topics.filter((t) => t !== topic)
												: [
														...topics,
														topic
													]
										)}
									className={`bg-white px-2 md:px-3 py-1 rounded-full border ${topics.includes(
										topic
									)
										? 'bg-blue-500 text-white'
										: 'border-gray-400 text-gray-700'}`}
								>
									{topic}
								</button>
							))}
						</div>
					</div>
				</div>
			</div>

			{/* Course Tiles */}
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
				{courses.map((course) => (
					<Link
						key={course.id}
						to={`/courses/${course.id}`}
						className="p-6 bg-white shadow rounded-xl border border-gray-300 hover:shadow-lg transition-shadow duration-300 ease-in-out transform hover:scale-101 block"
					>
						<h2 className="text-xl font-bold text-gray-800 mb-2">
							{course.title}
						</h2>
						<p className="text-gray-600 mb-4">{course.description}</p>

						{/* Delineation Line touching the edges of the card */}
						<hr className="border-gray-300 -mx-6 my-4" />

						<div className="space-y-4">
							{course.level !== undefined && (
								<p className="flex items-center text-gray-700">
									<i className="fa-solid fa-stairs mr-2" />
									<span className="pr-2">Difficulty level:</span>
									<span>{getDifficultyLevel(course.level)}</span>
								</p>
							)}
							{course.total_duration && (
								<p className="flex items-center text-gray-700">
									<i className="fa-solid fa-clock mr-2" />
									<span>Total duration: {course.total_duration} minutes</span>
								</p>
							)}
							{course.updated_at && (
								<p className="flex items-center text-gray-700">
									<i className="fa-solid fa-circle-exclamation mr-2" />
									<span>
										Last updated:{' '}
										{new Date(course.updated_at).toLocaleDateString('en-US', {
											year: 'numeric',
											month: 'long',
											day: 'numeric'
										})}
									</span>
								</p>
							)}
						</div>
					</Link>
				))}
			</div>
		</main>
	);
}
