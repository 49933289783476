import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import yahoo_sports_logo from '../../../../../assets/images/yahoo_sports_logo.png';
import espn_logo from '../../../../../assets/images/espn_fantasy_football_logo.png';

export default function NewsCenterIndexCard({ get_articles_endpoint }) {
	const [
		articles,
		setArticles
	] = useState([]);
	const [
		loading,
		setLoading
	] = useState(true);
	const [
		error,
		setError
	] = useState(null);

	useEffect(
		() => {
			window.scrollTo(0, 0); // Scroll to the top of the window when the component mounts

			const fetchData = async () => {
				try {
					const url = `${get_articles_endpoint}?article_type=News`;

					const response = await fetch(url);
					if (!response.ok) {
						throw new Error('Failed to fetch articles');
					}

					const result = await response.json();
					setArticles(result.data || []);
				} catch (error) {
					setError(
						error.message || 'An error occurred while fetching articles.'
					);
				} finally {
					setLoading(false);
				}
			};

			fetchData();
		},
		[
			get_articles_endpoint
		]
	);

	return (
		<div>
			<h1 className="text-5xl mb-4 text-center">News Center</h1>
			{loading && <LoadingSpinner />}
			{error && <div>Error: {error}</div>}
			{!loading &&
			!error && (
				<div className="py-6 md:py-14">
					<div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
						{articles.map((article) => (
							<div
								key={article.id}
								className="rounded-lg border border-gray-300 bg-white shadow-lg hover:shadow-xl transition-shadow p-6 flex flex-col"
							>
								<div>
									{/* Title */}
									<Link to={`${article.url}`} target="_blank" className="block">
										<h3 className="text-lg font-semibold text-gray-800 hover:text-gray-800 hover:underline pb-1">
											{article.title}
										</h3>
									</Link>

									{/* Author */}
									{article.author && (
										<p className="text-sm text-gray-500 pb-3">
											By {article.author}
										</p>
									)}

									{/* Published Date */}
									{/* {article.published_date && (
										<p className="text-xs text-gray-400 pb-3">
											{new Date(article.published_date).toLocaleDateString()}
										</p>
									)} */}

									{/* Synopsis */}
									<p className="text-gray-700 text-sm line-clamp-3">
										{article.synopsis}
									</p>

									{/* Publisher */}
									{article.publisher && (
										<div className="text-sm text-gray-500 pt-3 flex items-center">
											<span className="font-medium mr-1">Published by</span>
											{article.publisher === 'Yahoo! Sports' ? (
												<span>{article.publisher}</span>
											) : // <img
											// 	src={yahoo_sports_logo}
											// 	alt="Yahoo Sports Logo"
											// 	className="ml-1 h-5"
											// />
											article.publisher === 'ESPN' ? (
												<span>{article.publisher}</span>
											) : (
												// <img src={espn_logo} alt="ESPN Logo" className="ml-1 h-10" />
												<span>{article.publisher}</span>
											)}
										</div>
									)}
								</div>

								{/* Action Section */}
								<div className="mt-auto flex justify-end">
									<Link
										to={`${article.url}`}
										target="_blank"
										className="text-blue-500 font-medium text-sm hover:underline"
									>
										Read more
										<i className="fa-solid fa-arrow-up-right-from-square ml-2" />
									</Link>
								</div>
							</div>
						))}
					</div>
				</div>
			)}

			{/* Disclaimer Section */}
			{loading || error ? null : (
				<div className="mt-4 text-sm text-gray-500 px-6">
					<p className="font-medium">Disclaimer: Content Aggregation</p>
					<p className="mt-2">
						The articles featured in our "News Center" are curated and
						aggregated from publicly available sources such as Yahoo Sports,
						ESPN, CBS Sports, and other publishers. We do not host or control
						the content of these articles, and we are not affiliated with or
						endorsed by the article creators or platforms. Links direct users to
						the original sources where the full articles can be accessed.
					</p>
					<p className="mt-2">
						We strive to provide accurate and up-to-date content, but we cannot
						be responsible for any discrepancies or changes made by the original
						publishers. For any concerns regarding content, please contact us
						directly.
					</p>
				</div>
			)}
		</div>
	);
}

NewsCenterIndexCard.propTypes = {
	get_articles_endpoint: PropTypes.string.isRequired
};
