import React, { useState, useEffect } from 'react';
import getWeek17Opponents from '../../../utils/getWeek17Oppoments';
import InjuryStatusCard from '../RosterMetrics/InjuryStatusCard/InjuryStatusCard';
import ByeWeekCountCard from '../RosterMetrics/ByeWeekCountCard/ByeWeekCountCard';
import Week17GameStacksCard from '../RosterMetrics/Week17GameStacksCard/Week17GameStacksCard';
import Week17TeamStacksCard from '../RosterMetrics/Week17TeamStacksCard/Week17TeamStacksCard';
import TeamPlayerCountCard from '../RosterMetrics/TeamPlayerCountCard/TeamPlayerCountCard';
import PositionPlayerCountCard from '../RosterMetrics/PositionPlayerCountCard/PositionPlayerCountCard';
import FantasyFitnessSlides from '../RosterMetrics/FantasyFitnessSlides/FantasyFitnessSlides';

const RosterMetricsCard = ({
	roster,
	isDraftModeEnabled,
	allSectionsCollapsed
}) => {
	const players = roster.players || {};
	const quarterbacks = players.quarterbacks || [];
	const runningBacks = players.running_backs || [];
	const tightEnds = players.tight_ends || [];
	const wideReceivers = players.wide_receivers || [];

	const allPlayers = [
		...quarterbacks,
		...runningBacks,
		...tightEnds,
		...wideReceivers
	];

	const playerCounts = {
		QB: quarterbacks.length,
		RB: runningBacks.length,
		TE: tightEnds.length,
		WR: wideReceivers.length
	};

	const calculateAverage = (key) => {
		if (allPlayers.length === 0) return 0;
		const total = allPlayers.reduce(
			(sum, player) => sum + parseFloat(player[key] || 0),
			0
		);
		return (total / allPlayers.length).toFixed(2);
	};

	const countRookies = () => {
		return allPlayers.filter((player) => player.is_rookie).length;
	};

	const calculateAverageExperience = () => {
		if (allPlayers.length === 0) return 0;
		const currentYear = new Date().getFullYear();
		const totalExperience = allPlayers.reduce(
			(sum, player) => sum + (currentYear - player.draft_year),
			0
		);
		return (totalExperience / allPlayers.length).toFixed(1);
	};

	const calculateAverageAge = () => {
		if (allPlayers.length === 0) return 0;
		const totalAge = allPlayers.reduce(
			(sum, player) => sum + (player.age || 0),
			0
		);
		return (totalAge / allPlayers.length).toFixed(1);
	};

	const getPlayerTeamCount = () => {
		const teamCount = {};
		allPlayers.forEach((player) => {
			const teamName =
				player.team && player.team.name ? player.team.name : 'Unknown';
			if (!teamCount[teamName]) {
				teamCount[teamName] = 0;
			}
			teamCount[teamName]++;
		});
		return teamCount;
	};

	const calculateGameStacksCount = () => {
		const week17Opponents = getWeek17Opponents();
		const teamPlayerCounts = {};
		const seenMatches = new Set();

		const getLastWord = (teamName) => {
			if (!teamName) return 'Unknown';
			const words = teamName.split(' ');
			return words[words.length - 1];
		};

		// Count the number of players for each team
		for (let i = 0; i < allPlayers.length; i++) {
			const player = allPlayers[i];
			const teamName = player.team && player.team.name;

			if (teamName) {
				const displayTeam = getLastWord(teamName);
				if (!teamPlayerCounts[displayTeam]) {
					teamPlayerCounts[displayTeam] = 0;
				}
				teamPlayerCounts[displayTeam]++;
			}
		}

		// Combine counts with player counts for each matchup
		const combinedTeamCounts = {};
		for (let i = 0; i < allPlayers.length; i++) {
			const player1 = allPlayers[i];
			const team1 = player1.team && player1.team.name;

			for (let j = i + 1; j < allPlayers.length; j++) {
				const player2 = allPlayers[j];
				const team2 = player2.team && player2.team.name;

				if (team1 && team2 && week17Opponents[team1] === team2) {
					const displayTeam1 = getLastWord(team1);
					const displayTeam2 = getLastWord(team2);
					const [
						sortedTeam1,
						sortedTeam2
					] = [
						displayTeam1,
						displayTeam2
					].sort();
					const key = `${sortedTeam1}/${sortedTeam2}`;

					// Only process if the match has not been seen before
					if (!seenMatches.has(key)) {
						// Calculate total player count for the matchup
						const totalCount =
							(teamPlayerCounts[displayTeam1] || 0) +
							(teamPlayerCounts[displayTeam2] || 0);
						combinedTeamCounts[key] = totalCount;

						// Mark the match as seen
						seenMatches.add(key);
					}
				}
			}
		}

		// Filter out zero counts and return
		const result = {};
		for (const [
			key,
			count
		] of Object.entries(combinedTeamCounts)) {
			if (count > 0) {
				result[key] = count;
			}
		}

		return result;
	};

	// Calculate bye week player counts
	const calculateByeWeekPlayerCount = () => {
		const byeWeeks = {};
		allPlayers.forEach((player) => {
			const byeWeek = player.bye_week;
			if (byeWeek) {
				if (!byeWeeks[byeWeek]) {
					byeWeeks[byeWeek] = 0;
				}
				byeWeeks[byeWeek]++;
			}
		});
		return byeWeeks;
	};

	// Calculate injury statuses
	const calculateInjuryStatuses = () => {
		const injuryStatuses = {};
		allPlayers.forEach((player) => {
			// console.log(player); // Check the structure of each player object
			const status = player.status; // Access the status field
			if (status) {
				if (!injuryStatuses[status]) {
					injuryStatuses[status] = 0;
				}
				injuryStatuses[status]++;
			}
		});
		return injuryStatuses;
	};

	const [
		isMetricsVisible,
		setIsMetricsVisible
	] = useState(true);

	const toggleMetricsVisibility = () => {
		setIsMetricsVisible(!isMetricsVisible);
	};

	useEffect(
		() => {
			if (allSectionsCollapsed) {
				setIsMetricsVisible(false);
			} else {
				setIsMetricsVisible(true);
			}
		},
		[
			allSectionsCollapsed
		]
	);

	const avgFantasyFitnessScore = calculateAverage('fantasy_fitness_grade');
	const avgFantasyFitnessTalentScore = calculateAverage('talent_grade');
	const avgFantasyFitnessTeamScore = calculateAverage('team_grade');
	const numberOfRookies = countRookies();
	const avgPlayerExperience = calculateAverageExperience();
	const avgPlayerAge = calculateAverageAge();
	const playerTeamCount = getPlayerTeamCount();
	const gamesStacksCount = calculateGameStacksCount();
	const byeWeekPlayerCount = calculateByeWeekPlayerCount();
	const injuryStatuses = calculateInjuryStatuses();

	return (
		<div className="flex flex-col justify-between">
			<div className="flex flex-row items-center">
				<h2 className="text-xl text-gray-800 font-bold">ROSTER METRICS</h2>
				<i
					className={`fas fa-chevron-${isMetricsVisible
						? 'up'
						: 'down'} text-gray-500 cursor-pointer ml-2`}
					onClick={toggleMetricsVisibility}
				/>
			</div>

			{isMetricsVisible && (
				<div
					className={`mt-4 grid ${isDraftModeEnabled
						? 'grid-cols-1 w-44 xl:w-56 h-96'
						: 'sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6'} gap-4`}
					// style={isDraftModeEnabled ? { width: 230 } : {}}
				>
					{/* Position Player Count Card */}
					<PositionPlayerCountCard
						playerCounts={playerCounts}
						rookieCount={numberOfRookies}
						avgPlayerAge={avgPlayerAge}
						avgPlayerExperience={avgPlayerExperience}
						players={allPlayers}
						isDraftModeEnabled={isDraftModeEnabled}
					/>

					{/* Team Team Player Count Card */}
					<TeamPlayerCountCard
						playerTeamCount={playerTeamCount}
						players={allPlayers}
						isDraftModeEnabled={isDraftModeEnabled}
					/>

					{/* Bye Week Player Count Card */}
					<ByeWeekCountCard
						byeWeekPlayerCount={byeWeekPlayerCount}
						players={allPlayers}
						isDraftModeEnabled={isDraftModeEnabled}
					/>

					{/* Week 17 Game Stack Card */}
					<Week17GameStacksCard
						gamesStacksCount={gamesStacksCount}
						players={allPlayers}
						isDraftModeEnabled={isDraftModeEnabled}
					/>

					{/* Week 17 Team Stack Card */}
					{/* <Week17TeamStacksCard
						players={allPlayers}
						isDraftModeEnabled={isDraftModeEnabled}
					/> */}

					{/* Fantasy Fitness Slides */}
					<FantasyFitnessSlides
						avgFantasyFitnessScore={avgFantasyFitnessScore}
						avgFantasyFitnessTalentScore={avgFantasyFitnessTalentScore}
						avgFantasyFitnessTeamScore={avgFantasyFitnessTeamScore}
						players={allPlayers}
						isDraftModeEnabled={isDraftModeEnabled}
					/>

					{/* Injury Status Card */}
					<InjuryStatusCard
						injuryStatuses={injuryStatuses}
						players={allPlayers}
						isDraftModeEnabled={isDraftModeEnabled}
					/>
				</div>
			)}
		</div>
	);
};

export default RosterMetricsCard;
