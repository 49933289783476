import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import VideoCarousel from '../../VideoCarousel/VideoCarousel';

const DashboardVideoCarousel = ({ get_player_endpoint }) => {
	const [
		spotlightPlayer,
		setSpotlightPlayer
	] = useState(null);
	const [
		videos,
		setVideos
	] = useState([]);

	useEffect(() => {
		const fetchSpotlightData = async () => {
			try {
				const spotlightResponse = await fetch('/api/v1/spotlights');
				if (!spotlightResponse.ok) {
					throw new Error('Failed to fetch spotlight data');
				}

				const { data: spotlightData } = await spotlightResponse.json();

				if (spotlightData) {
					const { player_id, player_position } = spotlightData;

					if (player_id && player_position) {
						const playerResponse = await fetch(
							`${get_player_endpoint.replace(
								'id',
								player_id
							)}?position=${player_position}`
						);
						if (playerResponse.ok) {
							const playerResult = await playerResponse.json();
							setSpotlightPlayer([
								playerResult.data
							]);
							setVideos(
								[
									playerResult.data.video_url_1,
									playerResult.data.video_url_2,
									playerResult.data.video_url_3
								].filter((url) => url)
							);
						}
					}
				} else {
					console.error('Spotlight data is missing or malformed');
				}
			} catch (error) {
				console.error('Error fetching spotlight data:', error);
			}
		};

		fetchSpotlightData();
	}, []);

	if (videos.length === 0) {
		return null;
	}

	return (
		<div className="bg-white border border-gray-200 shadow-lg rounded-xl">
			<div className="mx-4 py-4 border-b border-gray-200">
				<h2 className="text-lg font-bold text-gray-800">
					<i className="fa-solid fa-film text-lg mr-3" />
					Tale of the Tape:{' '}
					<Link
						to={
							spotlightPlayer &&
							`/players/${spotlightPlayer[0].id}?position=${spotlightPlayer[0]
								.position}`
						}
						className="hover:underline"
						replace
					>
						{spotlightPlayer &&
							`${spotlightPlayer[0].first_name} ${spotlightPlayer[0]
								.last_name}`}
					</Link>
				</h2>
			</div>
			<Fragment>
				<VideoCarousel videos={videos} isDashboard={true} />
			</Fragment>
		</div>
	);
};

export default DashboardVideoCarousel;
