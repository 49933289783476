export default function ProspectAgeValueToColorHelper(age) {
	if (age <= 20) {
		return '#1ABE10';
	} else if (age <= 21) {
		return '#4BC818';
	} else if (age <= 21.5) {
		return '#7DD220';
	} else if (age <= 22) {
		return '#AFDC28';
	} else if (age <= 22.5) {
		return '#E1E630';
	} else if (age <= 23.5) {
		return '#EDCC2E';
	} else if (age <= 24) {
		return '#E9A227';
	} else if (age <= 24.5) {
		return '#E57820';
	} else if (age <= 25.5) {
		return '#E04E1A';
	} else {
		return '#DC2413';
	}
}
