import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import getRosterPlatformUrl from '../../../utils/getRosterPlatformUrl';
import RosterNotesCard from '../RosterNotesCard/RosterNotesCard';
import RosterDraftNotesCard from '../RosterDraftNotesCard/RosterDraftNotesCard';
import RosterLessonsLearnedNotesCard from '../RosterLessonsLearnedNotesCard/RosterLessonsLearnedNotesCard';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';
import StatusWidget from '../../StatusWidget/StatusWidget';
import FilterByPosition from '../../Filters/FilterByPosition';

function RosterPortraitCard({
	roster,
	isDraftModeEnabled,
	handleEditClick,
	handleOpenRosterPlayersSection,
	isRosterPlayersSectionOpen,
	get_notes_by_roster_endpoint,
	update_notes_by_roster_endpoint,
	get_draft_notes_by_roster_endpoint,
	update_draft_notes_by_roster_endpoint,
	get_lessons_learned_notes_by_roster_endpoint,
	update_lessons_learned_notes_by_roster_endpoint,
	get_prospects_endpoint
}) {
	const { user } = useAuth();
	const modalRef = useRef();

	const [
		rosterNotes,
		setRosterNotes
	] = useState([]);
	const [
		isAllPlayerNotesModalOpen,
		setIsAllPlayerNotesModalOpen
	] = useState(false);
	const platformUrl = getRosterPlatformUrl(roster.platform, roster.roster_url);
	const [
		prospectData,
		setProspectData
	] = useState([]);
	const [
		faWaiverPlayers,
		setFaWaiverPlayers
	] = useState([]);
	const [
		showNotesSection,
		setShowNotesSection
	] = useState(true);
	const [
		showProspectsSection,
		setShowProspectsSection
	] = useState(false);
	const [
		isProspectsTableExpanded,
		setIsProspectsTableExpanded
	] = useState(false);
	const [
		showLessonsLearnedSection,
		setShowLessonsLearnedSection
	] = useState(false);
	const [
		showFaWaiverPlayersSection,
		setShowFaWaiverPlayersSection
	] = useState(false);
	const [
		isFaWaiverTableExpanded,
		setIsFaWaiverTableExpanded
	] = useState(false);

	const toggleFaWaiverTableExpansion = () => {
		setIsFaWaiverTableExpanded(!isFaWaiverTableExpanded);
	};

	const toggleProspectsTableExpansion = () => {
		setIsProspectsTableExpanded(!isProspectsTableExpanded);
	};

	// Filter state for prospects
	const [
		filter,
		setFilter
	] = useState({ position: [] });

	const handleFilterChange = (filterKey, value) => {
		setFilter((prevFilter) => ({ ...prevFilter, [filterKey]: value }));
	};

	useEffect(
		() => {
			fetchProspects();
		},
		[
			filter
		]
	);

	// Filter state for FaWaiverPlayers
	const [
		faWaiverFilter,
		setFaWaiverFilter
	] = useState({ position: [] });

	const positionMap = {
		quarterback: 'QB',
		'running back': 'RB',
		'wide receiver': 'WR',
		'tight end': 'TE'
	};

	const handleFaWaiverFilterChange = (filterKey, value) => {
		if (filterKey === 'position') {
			const positions = value.map((pos) => positionMap[pos] || pos);
			setFaWaiverFilter((prevFilter) => ({
				...prevFilter,
				[filterKey]: positions
			}));
		} else {
			setFaWaiverFilter((prevFilter) => ({
				...prevFilter,
				[filterKey]: value
			}));
		}
	};

	// Fetch FaWaiverPlayers whenever the filter changes
	useEffect(
		() => {
			// Check the condition before calling fetchFaWaiverPlayers
			if (roster.platform === 'Yahoo' && roster.roster_url) {
				fetchFaWaiverPlayers();
			}
		},
		[
			faWaiverFilter,
			roster.platform,
			roster.roster_url
		]
	);

	const updatedFilter = {
		...faWaiverFilter,
		position: faWaiverFilter.position.map(
			(shortValue) => positionMap[shortValue] || shortValue
		)
	};

	useEffect(
		() => {
			if (isAllPlayerNotesModalOpen) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'auto';
			}
		},
		[
			isAllPlayerNotesModalOpen
		]
	);

	const closeAllPlayerNotesModalOnClickOutside = (event) => {
		if (!modalRef.current.contains(event.target)) {
			setIsAllPlayerNotesModalOpen(false);
		}
	};

	useEffect(
		() => {
			if (isAllPlayerNotesModalOpen) {
				document.addEventListener(
					'mousedown',
					closeAllPlayerNotesModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeAllPlayerNotesModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeAllPlayerNotesModalOnClickOutside
				);
			};
		},
		[
			isAllPlayerNotesModalOpen
		]
	);

	const fetchRosterPlayerNotes = () => {
		const endpoint = `/api/v1/users/${user.id}/rosters/${roster.id}/get_aggregate_roster_player_notes`;

		fetch(endpoint)
			.then((response) => {
				if (!response.ok) throw new Error('Failed to fetch notes');
				return response.json();
			})
			.then((data) => {
				setRosterNotes(data);
				setIsAllPlayerNotesModalOpen(true);
			})
			.catch((error) => console.error('Error fetching notes:', error));
	};

	const fetchProspects = () => {
		fetch(`${get_prospects_endpoint}?position=${filter.position.join(',')}`)
			.then((response) => response.json())
			.then((data) => {
				// Sort the players by prospect_grade in descending order
				const sortedProspects = data.players.sort(
					(a, b) =>
						(parseFloat(b.prospect_grade) || 0) -
						(parseFloat(a.prospect_grade) || 0)
				);
				setProspectData(sortedProspects);
			})
			.catch((error) => console.error('Error fetching prospects:', error));
	};

	const fetchFaWaiverPlayers = () => {
		const rosterUrl = roster.roster_url;
		const urlParts = rosterUrl.match(/f1\/(\d+)\/(\d+)/);

		const leagueId = urlParts[1];
		const teamId = urlParts[2];

		const formattedLeagueId = `nfl.l.${leagueId}`;
		const formattedTeamId = `nfl.l.${leagueId}.t.${teamId}`;

		fetch(`/api/v1/rosters/${roster.id}/fetch_yahoo_league_fa_waiver_players`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
			},
			body: JSON.stringify({
				roster_url: roster.roster_url,
				roster_id: roster.id,
				league_id: formattedLeagueId,
				team_id: formattedTeamId,
				positions: faWaiverFilter.position.join(',')
			})
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error('Failed to fetch FA/Waiver players');
				}
				return response.json();
			})
			.then((data) => {
				// Sort the players by fantasy_fitness_grade in descending order
				const sortedPlayers = data.players.sort(
					(a, b) => b.fantasy_fitness_grade - a.fantasy_fitness_grade
				);
				setFaWaiverPlayers(sortedPlayers);
			})
			.catch((error) => {
				console.error('Error fetching FA/Waiver players:', error);
			});
	};

	const handleNotesClick = () => {
		setShowNotesSection(true);
		setShowProspectsSection(false);
		setShowLessonsLearnedSection(false);
		setShowFaWaiverPlayersSection(false);
	};

	const handleProspectsClick = () => {
		setShowProspectsSection(true);
		setShowNotesSection(false);
		setShowLessonsLearnedSection(false);
		setShowFaWaiverPlayersSection(false);
		fetchProspects();
	};

	const handleLessonsLearnedClick = () => {
		setShowLessonsLearnedSection(true);
		setShowNotesSection(false);
		setShowProspectsSection(false);
		setShowFaWaiverPlayersSection(false);
	};

	const handleFaWaiverPlayersClick = () => {
		setShowFaWaiverPlayersSection(true);
		setShowNotesSection(false);
		setShowProspectsSection(false);
		setShowLessonsLearnedSection(false);
		fetchFaWaiverPlayers();
	};

	return (
		<div className="md:col-span-6">
			<div className="border border-gray-300 rounded-xl shadow-lg bg-white relative">
				{/* Icon Bar */}
				<div className="flex flex-row md:flex-col absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:right-0 md:top-1/2 md:space-y-4 text-center md:left-auto md:bottom-auto space-x-4 md:space-x-0 mr-0.5">
					<i
						className={`fa-solid fa-note-sticky text-gray-500 cursor-pointer hover:text-blue-500 transition duration-300 ${showNotesSection &&
							'text-blue-500'}`}
						onClick={handleNotesClick}
						title="Notes"
					/>
					<i
						className={`fa-solid fa-book text-gray-500 cursor-pointer hover:text-blue-500 transition duration-300q ${showLessonsLearnedSection &&
							'text-blue-500'}`}
						onClick={handleLessonsLearnedClick}
						title="Lessons Learned"
					/>
					{/* Only show the binoculars icon if format is Dynasty */}
					{roster.format === 'Dynasty' && (
						<i
							className={`fa-solid fa-binoculars text-gray-500 cursor-pointer hover:text-blue-500 transition duration-300 ${showProspectsSection &&
								'text-blue-500'}`}
							onClick={handleProspectsClick}
							title="Prospects"
						/>
					)}
					{/* Only show the FA/Waiver Players icon if the platform is Yahoo */}
					{roster.platform === 'Yahoo' &&
					roster.roster_url && (
						<i
							className={`fa-solid fa-users text-gray-500 cursor-pointer hover:text-blue-500 transition duration-300 ${showFaWaiverPlayersSection &&
								'text-blue-500'}`}
							onClick={handleFaWaiverPlayersClick}
							title="FA/Waiver Players"
						/>
					)}
				</div>

				<div className="mb-6 lg:mb-0 mx-4 px-4 sm:px-6 lg:px-8 py-4 lg:py-10">
					<div className="flex flex-col lg:flex-row items-start justify-between">
						{/* Roster details */}
						<div className="flex flex-col sm:flex-row lg:w-1/2">
							{roster.image_url && (
								<img
									className="h-48 md:60 lg:h-80 rounded mb-4 sm:mb-0 sm:mr-8"
									src={roster.image_url}
									alt="Roster Logo"
								/>
							)}
							<div
								className={`text-center sm:text-left ${isDraftModeEnabled
									? 'lg:pt-2.5'
									: 'lg:pt-4'}`}
							>
								<div className="flex flex-col sm:flex-row sm:justify-between items-center">
									<div className="flex items-center justify-center sm:justify-start relative">
										<h1 className="text-3xl lg:text-4xl font-bold mt-4 mb-2">
											{roster.team_name}
										</h1>
										{!isDraftModeEnabled && ( // Only show edit button if draft mode is disabled - eventually make this feature available in draft mode
											<button
												onClick={() => handleEditClick(roster)}
												className="text-blue-500 text-sm hover:underline absolute"
												style={{ top: '26px', right: '-38px' }}
											>
												Edit
											</button>
										)}
									</div>
								</div>
								<p className="text-lg text-gray-500">{roster.league_name}</p>
								<div
									className={`flex flex-wrap items-start text-base text-gray-500 mt-3 ${isDraftModeEnabled
										? 'pb-2.5'
										: 'pb-4'}`}
								>
									{/* Additional attributes */}
									{roster.platform && (
										<div className="flex items-center mr-6 mb-2">
											<span className="font-bold uppercase">PLATFORM</span>
											<span className="ml-2">
												{platformUrl ? (
													<a
														href={platformUrl}
														target="_blank"
														rel="noopener noreferrer"
														className="text-blue-500 hover:underline"
													>
														{roster.platform}
														<i className="fa-solid fa-arrow-up-right-from-square ml-2 text-blue-500" />
													</a>
												) : (
													<span>{roster.platform}</span>
												)}
											</span>
										</div>
									)}
									{roster.format && (
										<div className="flex items-center mr-6 mb-2">
											<span className="font-bold uppercase">FORMAT</span>
											<span className="ml-2">{roster.format}</span>
										</div>
									)}
									{roster.scoring && (
										<div className="flex items-center mr-6 mb-2">
											<span className="font-bold uppercase">SCORING</span>
											<span className="ml-2">{roster.scoring}</span>
										</div>
									)}
									{roster.users && (
										<div className="flex items-center mr-0 mb-2">
											<span className="font-bold uppercase">MANAGERS</span>
											<span className="ml-2">
												{roster.users.map((user) => user.email).join(', ')}
											</span>
										</div>
									)}
								</div>
								<button
									onClick={fetchRosterPlayerNotes}
									className="mb-6 lg:mb-0 bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600"
								>
									View roster player notes
								</button>
							</div>
						</div>

						{/* Prospects Section */}
						{showProspectsSection && (
							<div className="flex flex-col md:flex-row items-center md:items-start justify-center w-full md:w-auto">
								<div className="md:w-1/12 md:mt-6 ml-2 md:ml-0 mr-2 md-mr-0">
									<FilterByPosition
										filter={filter}
										onChange={handleFilterChange}
										hideLabel={true}
										isRosterProspectsTable={true}
										positionButtons={[
											{ label: 'All', value: 'all' },
											{ label: 'QB', value: 'quarterback' },
											{ label: 'RB', value: 'running back' },
											{ label: 'WR', value: 'wide receiver' },
											{ label: 'TE', value: 'tight end' }
										]}
									/>
								</div>
								<div className="relative md:w-11/12">
									{/* Prospects Table */}
									<div className="hidden sm:contents">
										<div
											className="w-full overflow-x-auto overflow-y-auto rounded-lg border border-gray-200 text-gray-800 mt-2"
											style={{
												maxHeight: isProspectsTableExpanded ? '627px' : '237px',
												transition: 'max-height 0.3s ease'
											}}
										>
											{/* Desktop */}
											<table className="min-w-full bg-white">
												<thead className="bg-gray-100 sticky top-0 z-10">
													<tr>
														<th className="py-2 px-8 border-b text-left">
															Position
														</th>
														<th className="py-2 px-8 border-b text-left">
															Player
														</th>
														<th className="py-2 px-8 border-b text-left">
															Prospect Score™
														</th>
													</tr>
												</thead>
												<tbody>
													{prospectData.map((prospect, index) => (
														<tr key={index} className="hover:bg-gray-50">
															<td className="py-2 px-8 border-b">
																{getPositionAbbreviation(prospect.position)}
															</td>
															<td className="py-2 px-8 border-b">
																<Link
																	to={`/prospects/${prospect.id}?position=${prospect.position}`}
																	className="text-blue-500 hover:underline"
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<div className="flex items-center">
																		<div className="relative mr-3 flex-shrink-0">
																			<img
																				src={`${prospect.image_url}?w=50&h=50`}
																				alt={`${prospect.first_name} ${prospect.last_name}`}
																				className="w-12 h-12 rounded-full border border-gray-300 object-cover"
																			/>
																			{prospect.status && (
																				<div className="absolute top-0 right-0">
																					<StatusWidget
																						status={prospect.status}
																						description={
																							prospect.injury_description
																						}
																						hideLabel={true}
																						is50pXThumbnail={true}
																					/>
																				</div>
																			)}
																		</div>
																		<span>
																			{`${prospect.first_name} ${prospect.last_name}`}
																		</span>
																	</div>
																</Link>
															</td>
															<td className="py-2 px-8 border-b">
																{prospect.prospect_grade}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
										{/* Expand/Collapse Button */}
										<button
											onClick={toggleProspectsTableExpansion}
											className="absolute right-1 p-2 text-blue-500 hover:underline text-sm"
											aria-label={
												isProspectsTableExpanded ? 'Collapse' : 'Expand'
											}
										>
											{isProspectsTableExpanded ? (
												<span>Collapse</span>
											) : (
												<span>Expand</span>
											)}
										</button>
									</div>

									{/* Mobile */}
									<div className="contents sm:hidden">
										<div className="max-h-80 overflow-y-auto">
											{prospectData.map((prospect, index) => (
												<div
													key={index}
													className="border-b border-gray-200 py-4"
												>
													<div className="flex items-center mb-2">
														<Link
															to={`/prospects/${prospect.id}?position=${prospect.position}`}
															className="relative mr-4 flex-shrink-0"
															target="_blank"
															rel="noopener noreferrer"
														>
															<img
																src={`${prospect.image_url}?w=50&h=50`}
																alt={`${prospect.first_name} ${prospect.last_name}`}
																className="w-12 h-12 rounded-full border border-gray-300 object-cover"
															/>
															{prospect.status && (
																<div className="absolute top-0 right-0">
																	<StatusWidget
																		status={prospect.status}
																		description={prospect.injury_description}
																		hideLabel={true}
																		is50pXThumbnail={true}
																	/>
																</div>
															)}
														</Link>
														<div>
															<Link
																to={`/prospects/${prospect.id}?position=${prospect.position}`}
																className="text-blue-500 hover:underline"
																target="_blank"
																rel="noopener noreferrer"
															>
																<span className="block text-blue-500">
																	{`${prospect.first_name} ${prospect.last_name}`}
																</span>
															</Link>
															<p className="text-sm text-gray-500">
																Position:{' '}
																{getPositionAbbreviation(prospect.position)}
															</p>
														</div>
													</div>
													<div className="text-sm text-gray-700">
														<span className="font-semibold">
															Prospect Score™:{' '}
														</span>
														{prospect.prospect_grade}
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						)}

						{/* FA/Waiver Section */}
						{showFaWaiverPlayersSection && (
							<div className="flex flex-col md:flex-row items-center md:items-start justify-center w-full md:w-auto">
								<div className="md:w-1/12 md:mt-6 ml-2 md:ml-0 mr-2 md-mr-0">
									<FilterByPosition
										filter={updatedFilter} // Pass the updated filter with long names
										onChange={handleFaWaiverFilterChange}
										hideLabel={true}
										isRosterProspectsTable={true} // Need to update this prop
										positionButtons={[
											{ label: 'All', value: 'all' },
											{ label: 'QB', value: 'QB' },
											{ label: 'RB', value: 'RB' },
											{ label: 'WR', value: 'WR' },
											{ label: 'TE', value: 'TE' }
										]}
									/>
								</div>
								<div className="relative md:w-11/12">
									{/* FA/Waiver  Table */}
									<div className="hidden sm:contents">
										<div
											className="w-full overflow-x-auto overflow-y-auto rounded-lg border border-gray-200 text-gray-800 mt-2"
											style={{
												maxHeight: isFaWaiverTableExpanded ? '627px' : '237px',
												transition: 'max-height 0.3s ease'
											}}
										>
											{/* Desktop */}
											<table className="min-w-full bg-white">
												<thead className="bg-gray-100 sticky top-0 z-10">
													<tr>
														<th className="py-2 px-8 border-b text-left">
															Position
														</th>
														<th className="py-2 px-8 border-b text-left">
															Player
														</th>
														<th className="py-2 px-8 border-b text-left">
															Overall Score™
														</th>
													</tr>
												</thead>
												<tbody>
													{faWaiverPlayers.map((player, index) => (
														<tr key={index} className="hover:bg-gray-50">
															<td className="py-2 px-8 border-b">
																{getPositionAbbreviation(player.position)}
															</td>
															<td className="py-2 px-8 border-b">
																<Link
																	to={`/players/${player.id}?position=${player.position}`}
																	className="text-blue-500 hover:underline"
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	<div className="flex items-center">
																		<div className="relative mr-3 flex-shrink-0">
																			<img
																				src={`${player.image_url}?w=50&h=50`}
																				alt={`${player.first_name} ${player.last_name}`}
																				className="w-12 h-12 rounded-full border border-gray-300 object-cover"
																			/>
																			{player.status && (
																				<div className="absolute top-0 right-0">
																					<StatusWidget
																						status={player.status}
																						description={
																							player.injury_description
																						}
																						hideLabel={true}
																						is50pXThumbnail={true}
																					/>
																				</div>
																			)}
																		</div>
																		<span>
																			{`${player.first_name} ${player.last_name}`}
																		</span>
																	</div>
																</Link>
															</td>
															<td className="py-2 px-8 border-b">
																{player.fantasy_fitness_grade}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
										{/* Expand/Collapse Button */}
										<button
											onClick={toggleFaWaiverTableExpansion}
											className="absolute right-1 p-2 text-blue-500 hover:underline text-sm"
											aria-label={
												isFaWaiverTableExpanded ? 'Collapse' : 'Expand'
											}
										>
											{isFaWaiverTableExpanded ? (
												<span>Collapse</span>
											) : (
												<span>Expand</span>
											)}
										</button>
									</div>

									{/* Mobile */}
									<div className="contents sm:hidden">
										<div className="max-h-80 overflow-y-auto">
											{faWaiverPlayers.map((player, index) => (
												<div
													key={index}
													className="border-b border-gray-200 py-4"
												>
													<div className="flex items-center mb-2">
														<Link
															to={`/players/${player.id}?position=${player.position}`}
															className="relative mr-4 flex-shrink-0"
															target="_blank"
															rel="noopener noreferrer"
														>
															<img
																src={`${player.image_url}?w=50&h=50`}
																alt={`${player.first_name} ${player.last_name}`}
																className="w-12 h-12 rounded-full border border-gray-300 object-cover"
															/>
															{player.status && (
																<div className="absolute top-0 right-0">
																	<StatusWidget
																		status={player.status}
																		description={player.injury_description}
																		hideLabel={true}
																		is50pXThumbnail={true}
																	/>
																</div>
															)}
														</Link>
														<div>
															<Link
																to={`/players/${player.id}?position=${player.position}`}
																className="text-blue-500 hover:underline"
																target="_blank"
																rel="noopener noreferrer"
															>
																<span className="block text-blue-500">
																	{`${player.first_name} ${player.last_name}`}
																</span>
															</Link>
															<p className="text-sm text-gray-500">
																Position:{' '}
																{getPositionAbbreviation(player.position)}
															</p>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						)}

						{/* Notes and Draft Notes Cards */}
						{showNotesSection && (
							<div className="flex flex-col sm:flex-row lg:w-1/2 lg:ml-4 space-y-6 sm:space-y-0 sm:space-x-6 w-full pb-3 md:py-3">
								<div className="flex-1">
									<RosterNotesCard
										user={user}
										roster={roster}
										get_notes_by_roster_endpoint={get_notes_by_roster_endpoint}
										update_notes_by_roster_endpoint={
											update_notes_by_roster_endpoint
										}
										isDraftModeEnabled={isDraftModeEnabled}
									/>
								</div>
								<div className="flex-1">
									<RosterDraftNotesCard
										user={user}
										roster={roster}
										get_draft_notes_by_roster_endpoint={
											get_draft_notes_by_roster_endpoint
										}
										update_draft_notes_by_roster_endpoint={
											update_draft_notes_by_roster_endpoint
										}
										isDraftModeEnabled={isDraftModeEnabled}
										displayLocation="rosterProfile"
									/>
								</div>
							</div>
						)}

						{/* Lessons Learned Notes Card */}
						{showLessonsLearnedSection && (
							<div className="flex flex-col sm:flex-row lg:w-1/2 lg:ml-4 space-y-6 sm:space-y-0 sm:space-x-6 w-full pb-3 md:py-3">
								<div className="flex-1">
									<RosterLessonsLearnedNotesCard
										user={user}
										roster={roster}
										get_lessons_learned_notes_by_roster_endpoint={
											get_lessons_learned_notes_by_roster_endpoint
										}
										update_lessons_learned_notes_by_roster_endpoint={
											update_lessons_learned_notes_by_roster_endpoint
										}
										isDraftModeEnabled={isDraftModeEnabled}
									/>
								</div>
							</div>
						)}
					</div>
				</div>

				{/* Show More Button */}
				{isDraftModeEnabled && (
					<div
						className="absolute bottom-1 left-1/2 transform -translate-x-1/2 text-center cursor-pointer hover:underline px-4"
						onClick={handleOpenRosterPlayersSection}
					>
						{isRosterPlayersSectionOpen ? (
							<i className="fa-solid fa-chevron-up rounded-full hover:bg-gray-200 transition duration-300 p-2" />
						) : (
							<i className="fa-solid fa-chevron-down rounded-full hover:bg-gray-200 transition duration-300 p-2" />
						)}
					</div>
				)}
			</div>

			{/* Desktop modal for displaying player notes */}
			{isAllPlayerNotesModalOpen && (
				<div className="hidden lg:contents">
					<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
						<div
							className="relative bg-white rounded-lg border border-gray-200 text-gray-800 p-6 w-full max-w-5xl"
							style={{ maxHeight: '90vh' }}
						>
							{/* Add relative class to the modal for absolute positioning */}
							<h2 className="text-xl font-bold mb-4">Roster Player Notes</h2>
							{/* Close Button in Top-Right Corner */}
							<button
								className="absolute top-3 right-3 p-2 rounded-full hover:bg-gray-200 transition duration-300"
								onClick={() => setIsAllPlayerNotesModalOpen(false)}
							>
								<svg
									className="h-5 w-5"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
							{/* Wrapper div for table with max height and scroll */}
							<div
								className="overflow-y-auto"
								style={{ maxHeight: 'calc(90vh - 100px)' }}
							>
								<table className="min-w-full bg-white rounded-lg border border-gray-200 text-gray-800">
									<thead className="bg-gray-100 sticky top-0 z-10">
										<tr>
											<th className="py-3 px-4 border-b text-left font-semibold">
												Position
											</th>
											<th className="py-3 px-4 border-b text-left font-semibold">
												Player
											</th>
											<th className="py-3 px-4 border-b text-left font-semibold">
												Notes
											</th>
											<th className="py-3 px-4 border-b text-left font-semibold">
												Last Updated
											</th>
										</tr>
									</thead>

									{/* Display message if no notes exist */}
									{rosterNotes.length === 0 && (
										<tbody>
											<tr>
												<td
													colSpan="4"
													className="py-4 px-4 border-b text-center"
												>
													No roster player notes exist at this time.
												</td>
											</tr>
										</tbody>
									)}

									{/* Display notes if they exist */}
									<tbody>
										{rosterNotes.map(
											(note, index) =>
												note.note.content ? (
													<tr key={index} className="hover:bg-gray-50">
														<td className="py-2 px-4 border-b text-left">
															{getPositionAbbreviation(note.position)}
														</td>
														<td className="py-2 px-4 border-b text-left">
															<div className="flex items-center">
																<div
																	className="relative mr-2"
																	style={{
																		width: '50px',
																		height: '50px',
																		overflow: 'hidden'
																	}}
																>
																	<img
																		src={`${note.image_url}?w=50&h=50`}
																		alt={`${note.first_name} ${note.last_name}`}
																		style={{
																			width: '100%',
																			height: '100%',
																			objectFit: 'cover',
																			border: '1px solid #ddd',
																			borderRadius: '50%'
																		}}
																	/>
																	{note.status && (
																		<div
																			style={{
																				position: 'absolute',
																				top: '0',
																				right: '0'
																			}}
																		>
																			<StatusWidget
																				status={note.status}
																				description={
																					note.injury_description || null
																				}
																				hideLabel={true}
																				is50pXThumbnail={true}
																			/>
																		</div>
																	)}
																</div>
																<span className="text-gray-800">{`${note.first_name} ${note.last_name}`}</span>
															</div>
														</td>
														<td className="py-2 px-4 border-b text-left">
															{note.note.content}
														</td>
														<td className="py-2 px-4 border-b text-left">
															{new Date(
																note.note.updated_at
															).toLocaleDateString('en-US', {
																year: '2-digit',
																month: '2-digit',
																day: '2-digit'
															})}
														</td>
													</tr>
												) : null
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* Mobile modal for displaying player notes */}
			{isAllPlayerNotesModalOpen && (
				<div className="contents lg:hidden">
					<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
						<div className="bg-white rounded-lg border border-gray-200 text-gray-800 p-6 w-full max-w-md relative">
							<h2 className="text-xl font-bold">Roster Player Notes</h2>
							<button
								className="absolute top-3 right-3 p-2 rounded-full hover:bg-gray-200 transition duration-300"
								onClick={() => setIsAllPlayerNotesModalOpen(false)}
							>
								<svg
									className="h-5 w-5"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>

							{/* Display message if no notes exist otherwise display notes */}
							{rosterNotes.length === 0 ? (
								<div>
									<p className="pt-6">
										No roster player notes exist at this time.
									</p>
								</div>
							) : (
								<div
									className="overflow-y-auto flex flex-wrap items-start text-base text-gray-500 px-2 py-4"
									style={{ maxHeight: '70vh' }}
								>
									{rosterNotes.map(
										(note, index) =>
											note.note.content ? (
												<div key={index} className="w-full mb-4 border-b pb-4">
													<div className="flex items-center mb-2">
														{/* Image and Name */}
														<div
															className="relative mr-4 mb-2"
															style={{
																width: '40px',
																height: '40px',
																overflow: 'hidden'
															}}
														>
															<img
																src={`${note.image_url}?w=40&h=40`}
																alt={`${note.first_name} ${note.last_name}`}
																className="w-full h-full object-cover rounded-full border border-gray-300"
															/>
															{note.status && (
																<div
																	style={{
																		position: 'absolute',
																		top: '0',
																		right: '0'
																	}}
																>
																	<StatusWidget
																		status={note.status}
																		description={note.injury_description || ''}
																		hideLabel={true}
																		is50pXThumbnail={true}
																	/>
																</div>
															)}
														</div>
														<span className="text-gray-800">
															{`${note.first_name} ${note.last_name}, ${getPositionAbbreviation(
																note.position
															)}`}
														</span>
													</div>

													{/* Player Details */}
													<div className="flex flex-wrap">
														<div className="flex flex-col mr-6 mb-2">
															<span className="font-bold uppercase">Notes</span>
															<span className="ml-2">{note.note.content}</span>
														</div>
														<div className="flex flex-col mr-6 mb-2">
															<span className="font-bold uppercase">
																Last Updated
															</span>
															<span className="ml-2">
																{new Date(
																	note.note.updated_at
																).toLocaleDateString('en-US', {
																	year: '2-digit',
																	month: '2-digit',
																	day: '2-digit'
																})}
															</span>
														</div>
													</div>
												</div>
											) : null
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default RosterPortraitCard;
