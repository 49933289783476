import React from 'react';
import Dashboard from '../../Dashboard/Dashboard';

export default function DashboardPage({
	get_articles_endpoint,
	get_player_endpoint,
	get_team_endpoint,
	get_prospect_endpoint,
	get_rosters_by_user_endpoint,
	get_courses_endpoint,
	get_social_posts_endpoint,
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) {
	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="mt-4">
				<div className="grid grid-cols-1">
					<Dashboard
						get_articles_endpoint={get_articles_endpoint}
						get_player_endpoint={get_player_endpoint}
						get_team_endpoint={get_team_endpoint}
						get_prospect_endpoint={get_prospect_endpoint}
						get_rosters_by_user_endpoint={get_rosters_by_user_endpoint}
						get_courses_endpoint={get_courses_endpoint}
						get_social_posts_endpoint={get_social_posts_endpoint}
						create_checkout_session_endpoint={create_checkout_session_endpoint}
						stripe_public_key={stripe_public_key}
						stripe_product_essential_id={stripe_product_essential_id}
						stripe_product_advanced_id={stripe_product_advanced_id}
						stripe_product_ultimate_id={stripe_product_ultimate_id}
					/>
				</div>
			</div>
		</div>
	);
}
