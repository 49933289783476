import React from 'react';
import FormSection from '../FormElements/FormSection';
import FormSubmit from '../FormElements/FormSubmit';
import Label from '../FormElements/Label';
import TextInput from '../FormElements/TextInput';
import TextArea from '../FormElements/TextArea';
import EmailInput from '../FormElements/EmailInput';

const IssueForm = ({
	name,
	email,
	description,
	errors,
	isSubmitted,
	onChange,
	onSubmit
}) => {
	return (
		<div className="w-96 p">
			{!isSubmitted && (
				<div className="text-2xl font-bold text-gray-800 pb-6">
					Report an issue
				</div>
			)}
			{isSubmitted ? (
				<div className="flex flex-col items-center justify-center h-36">
					{/* Success Icon */}
					<i className="fa-regular fa-circle-check text-green-500 text-3xl" />
					{/* Success Message */}
					<h2 className="text-xl text-green-600">Saved.</h2>
					<p className="text-gray-600 text-center mt-6">
						Thank you! We'll be in touch with you soon.
					</p>
				</div>
			) : (
				<FormSection>
					<Label htmlFor="name">Name *</Label>
					<TextInput
						id="name"
						name="name"
						value={name}
						onChange={onChange}
						fieldErrors={errors}
					/>
					<Label htmlFor="email">Email *</Label>
					<EmailInput
						id="email"
						name="email"
						value={email}
						onChange={onChange}
						fieldErrors={errors}
					/>
					<Label htmlFor="description">Description of the issue *</Label>
					<TextArea
						id="description"
						name="description"
						value={description}
						onChange={onChange}
						fieldErrors={errors}
					/>
				</FormSection>
			)}
			{!isSubmitted && (
				<div className="grid">
					<div className="mt-4">
						<FormSubmit onSubmit={onSubmit} label="Submit" />
					</div>
				</div>
			)}
		</div>
	);
};

export default IssueForm;
