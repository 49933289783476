import React from 'react';
import { Link } from 'react-router-dom';
import AdvancedDataTable from '../../../DataTables/AdvancedDataTable';
import MobileDataTableCard from '../../../DataTables/MobileDataTableCard';
import StatusWidget from '../../../StatusWidget/StatusWidget';
import convertInchesToFeetAndInches from '../../../../utils/convertInchesToFeetAndInches';
import GradesValueToColorHelper from '../../../Helpers/GradesValueToColorHelper';
import ProspectAgeValueToColorHelper from '../../../Helpers/ProspectAgeValueToColorHelper';
import QbFortyTimeValueToColorHelper from '../../../Helpers/QbFortyTimeValueToColorHelper';
import RbFortyTimeValueToColorHelper from '../../../Helpers/RbFortyTimeValueToColorHelper';
import WrFortyTimeValueToColorHelper from '../../../Helpers/WrFortyTimeValueToColorHelper';
import TeFortyTimeValueToColorHelper from '../../../Helpers/TeFortyTimeValueToColorHelper';

const ProspectsProfileStatsTable = ({
	prospects,
	sortBy,
	sortOrder,
	handleSortOrderChange,
	isHeatmapEnabled,
	isHeatmapAgeCategoryEnabled,
	isHeatmap40TimeCategoryEnabled,
	isHeatmapFFScoresCategoryEnabled
}) => {
	return (
		<div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={prospects}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Player',
								apiName: 'player',
								alignment: 'left'
							},
							{
								/*{
								displayName: 'Team',
								apiName: 'team',
								alignment: 'left'
							},
							{
								displayName: 'Age (years)',
								apiName: 'age',
								alignment: 'left'
							},
							{
								displayName: 'Height (ft/in)',
								apiName: 'height',
								alignment: 'left'
							},
							{
								displayName: 'Weight (lbs)',
								apiName: 'weight',
								alignment: 'left'
							},
							{
								displayName: '40 Time (sec)',
								apiName: 'forty_time',
								alignment: 'left'
							},
							{
								displayName: 'Team Score™',
								apiName: 'team_grade',
								alignment: 'left'
							},
							{
								displayName: 'Talent Score™',
								apiName: 'talent_grade',
								alignment: 'left'
							},
							{
								displayName: 'Overall Score™',
								apiName: 'fantasy_fitness_grade',
								alignment: 'left'
							} */
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - ProspectsProfileStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div>
									<Link
										to={`/prospects/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:underline"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</div>
								{/* <div className="mt-4 font-semibold">
									{labels[1].displayName}
								</div>
								<div>
									<Link
										to={`/teams/${row.team_id}`}
										className="text-blue-500 hover:underline"
									>
										{row.team.name}
									</Link>
								</div>
								<div className="mt-4 font-semibold">
									{labels[2].displayName}
								</div>
								<div>{row.age}</div>
								<div className="mt-4 font-semibold">
									{labels[3].displayName}
								</div>
								<div>{convertInchesToFeetAndInches(row.height)}</div>
								<div className="mt-4 font-semibold">
									{labels[4].displayName}
								</div>
								<div>{row.weight}</div>
								<div className="mt-4 font-semibold">
									{labels[5].displayName}
								</div>
								<div>{row.forty_time}</div>
								<div className="mt-4 font-semibold">
									{labels[6].displayName}
								</div>
								<div>{row.team_grade}</div>
								<div className="mt-4 font-semibold">
									{labels[7].displayName}
								</div>
								<div>{row.talent_grade}</div>
								<div className="mt-4 font-semibold">
									{labels[8].displayName}
								</div>
								<div>{row.fantasy_fitness_grade}</div> */}
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={prospects}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					sortingEnabled={true}
					sortOrder={sortOrder}
					sortColumn={sortBy ? sortBy : ''}
					onChangeSortOrder={handleSortOrderChange}
					columns={[
						{
							displayName: 'Prospect',
							apiName: 'prospect',
							alignment: 'left'
						},
						{
							displayName: 'Height (ft/in)',
							apiName: 'height',
							alignment: 'left'
						},
						{
							displayName: 'Weight (lbs)',
							apiName: 'weight',
							alignment: 'left'
						},
						{
							displayName: 'Age (years)',
							apiName: 'age',
							alignment: 'left'
						},
						{
							displayName: '40 Time (sec)',
							apiName: 'forty_time',
							alignment: 'left'
						},
						{
							displayName: 'Prospect Score™',
							apiName: 'prospect_grade',
							alignment: 'left'
						}
					]}
					rowRenderer={(row) => {
						return (
							<tr
								className="pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60 border-t border-gray-200"
								key={`${row.first_name} ${row.last_name} - ProspectsProfileStatsTable - AdvancedDataTable`}
							>
								{/* Rendering Player's name and link */}
								<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60">
									<Link
										to={`/prospects/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:underline"
										target="_self"
										rel="noopener noreferrer"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
												{/* No reliable source to get the status of a prospect... hiding for now */}
												{/*}
												<div
													style={{
														position: 'absolute',
														top: '15px',
														left: '70px'
													}}
												>
													<StatusWidget
														status={row.status}
														description={row.injury_description}
														hideLabel={true}
														is50pXThumbnail={true}
													/>
												</div>
												*/}
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</td>
								<td className="pl-8 py-3 text-left">
									{convertInchesToFeetAndInches(row.height)}
								</td>
								<td className="pl-8 py-3 text-left">{row.weight}</td>

								{/* Rendering Age status column */}
								<td>
									<div
										className="pl-8 py-1 text-left relative"
										style={{
											height: '75px',
											padding: '5px',
											boxSizing: 'border-box'
										}}
									>
										{/* Background color */}
										<div
											style={{
												backgroundColor:
													isHeatmapEnabled && isHeatmapAgeCategoryEnabled
														? ProspectAgeValueToColorHelper(row.age)
														: 'transparent',
												borderRadius: '10px',
												opacity: isHeatmapEnabled ? '0.7' : '1',
												height: '100%',
												width: '100%'
											}}
										/>

										{/* Text centered over the colored area */}
										<span
											className="text-black"
											style={{
												position: 'absolute',
												top: 0,
												left: 0,
												width: '100%',
												height: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												zIndex: 2
											}}
										>
											{row.age}
										</span>
									</div>
								</td>
								{/* Rendering Forty Time status column */}
								<td>
									<div
										className="pl-8 py-1 text-left relative"
										style={{
											height: '75px',
											padding: '5px',
											boxSizing: 'border-box'
										}}
									>
										{/* Background color */}
										<div
											style={{
												backgroundColor:
													isHeatmapEnabled && isHeatmap40TimeCategoryEnabled
														? row.position === 'quarterback'
															? QbFortyTimeValueToColorHelper(row.forty_time)
															: row.position === 'running back'
																? RbFortyTimeValueToColorHelper(row.forty_time)
																: row.position === 'wide receiver'
																	? WrFortyTimeValueToColorHelper(
																			row.forty_time
																		)
																	: row.position === 'tight end'
																		? TeFortyTimeValueToColorHelper(
																				row.forty_time
																			)
																		: 'transparent'
														: 'transparent',
												borderRadius: '10px',
												opacity: isHeatmapEnabled ? '0.7' : '1',
												height: '100%',
												width: '100%'
											}}
										/>

										{/* Text centered over the colored area */}
										<span
											className="text-black"
											style={{
												position: 'absolute',
												top: 0,
												left: 0,
												width: '100%',
												height: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												zIndex: 2
											}}
										>
											{row.forty_time}
										</span>
									</div>
								</td>

								{/* Rendering Prospect Score column */}
								<td>
									<div
										className="pl-8 py-1 text-left relative"
										style={{
											height: '75px',
											padding: '5px',
											boxSizing: 'border-box'
										}}
									>
										{/* Background color */}
										<div
											style={{
												backgroundColor:
													isHeatmapEnabled && isHeatmapFFScoresCategoryEnabled
														? GradesValueToColorHelper(row.prospect_grade)
														: 'transparent',
												borderRadius: '10px',
												opacity: isHeatmapEnabled ? '0.7' : '1',
												height: '100%',
												width: '100%'
											}}
										/>

										{/* Text centered over the colored area */}
										<span
											className="text-black"
											style={{
												position: 'absolute',
												top: 0,
												left: 0,
												width: '100%',
												height: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												zIndex: 2
											}}
										>
											{row.prospect_grade}
										</span>
									</div>
								</td>
							</tr>
						);
					}}
				/>
			</div>
		</div>
	);
};

export default ProspectsProfileStatsTable;
