import React, { useEffect } from 'react';

const PaginationTracker = ({ currentPage, setPage, totalPages }) => {
	useEffect(
		() => {
			// Reset the page to 1 if the total pages change
			setPage(1);
		},
		[
			totalPages
		]
	);

	window.scrollTo({
		top: 0,
		left: 0
	});

	const handlePrevious = () => {
		if (currentPage > 1) setPage(currentPage - 1);
	};

	const handleNext = () => {
		if (currentPage < totalPages) setPage(currentPage + 1);
	};

	const handleFirst = () => {
		setPage(1);
	};

	const handleLast = () => {
		setPage(totalPages);
	};

	// Calculate the range of pages to display
	const pageRange = () => {
		const rangeSize = 5; // Number of pages to show before and after the current page
		let start = Math.max(currentPage - Math.floor(rangeSize / 2), 1);
		let end = Math.min(start + rangeSize - 1, totalPages);

		// Adjust range if it overflows at the end
		if (end - start < rangeSize - 1) {
			start = Math.max(end - rangeSize + 1, 1);
		}

		const pages = [];
		for (let i = start; i <= end; i++) {
			pages.push(i);
		}
		return pages;
	};

	return (
		<div className="flex items-center gap-4">
			{/* First Page Button */}
			<button
				onClick={handleFirst}
				disabled={currentPage === 1}
				className={`h-10 w-10 flex items-center justify-center rounded-full ${currentPage ===
				1
					? 'bg-gray-200 text-gray-500 cursor-not-allowed'
					: 'text-gray-500 hover:bg-gray-200'}`}
			>
				<i className="fas fa-angles-left" />
			</button>

			{/* Previous Page Button */}
			<button
				onClick={handlePrevious}
				disabled={currentPage === 1}
				className={`h-10 w-10 flex items-center justify-center rounded-full ${currentPage ===
				1
					? 'bg-gray-200 text-gray-500 cursor-not-allowed'
					: 'text-gray-500 hover:bg-gray-200'}`}
			>
				<i className="fas fa-angle-left" />
			</button>

			{/* Page Number Buttons */}
			{pageRange().map((page) => (
				<button
					key={page}
					onClick={() => setPage(page)}
					className={`h-10 w-10 flex items-center justify-center rounded-full ${page ===
					currentPage
						? 'bg-blue-500 text-white'
						: 'text-gray-500 hover:bg-gray-200'}`}
				>
					{page}
				</button>
			))}

			{/* Next Page Button */}
			<button
				onClick={handleNext}
				disabled={currentPage === totalPages}
				className={`h-10 w-10 flex items-center justify-center rounded-full ${currentPage ===
				totalPages
					? 'bg-gray-200 text-gray-500 cursor-not-allowed'
					: 'text-gray-500 hover:bg-gray-200'}`}
			>
				<i className="fas fa-angle-right" />
			</button>

			{/* Last Page Button */}
			<button
				onClick={handleLast}
				disabled={currentPage === totalPages}
				className={`h-10 w-10 flex items-center justify-center rounded-full ${currentPage ===
				totalPages
					? 'bg-gray-200 text-gray-500 cursor-not-allowed'
					: 'text-gray-500 hover:bg-gray-200'}`}
			>
				<i className="fas fa-angles-right" />
			</button>
		</div>
	);
};

export default PaginationTracker;
