import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';
import getPositionCapitalization from '../../../utils/getPositionCapitalization';
import getBarPropsByPosition from '../../../utils/getBarPropsByPosition';

import PlayerPortraitCard from '../PlayerPortraitCard/PlayerPortraitCard';
import FixedPlayerBanner from '../FixedPlayerBanner/FixedPlayerBanner';
import DepthChartCard from '../DepthChartCard/DepthChartCard';
import FantasyFitnessScoreCard from '../../ScoreCards/FantasyFitnessScoreCard/FantasyFitnessScoreCard';
import AthleticismScoreCard from '../../ScoreCards/AthleticismScoreCard/AthleticismScoreCard';
import TeamEnvironmentScoreCard from '../../ScoreCards/TeamEnvironmentScoreCard/TeamEnvironmentScoreCard';
import VideoCarousel from '../../VideoCarousel/VideoCarousel';
import CoachesCard from '../../Team/CoachesCard/CoachesCard';

import ProCollegeButtons from '../../Buttons/ProCollegeButtons/ProCollegeButtons';
import StatsTypeButtons from '../../Buttons/StatsTypeButtons/StatsTypeButtons';
import QuarterbackCollegeBasicPassingStatsTable from '../../Player/DataTables/Quarterback/College/QuarterbackCollegeBasicPassingStatsTable/QuarterbackCollegeBasicPassingStatsTable';
import QuarterbackCollegeAdvancedPassingStatsTable from '../../Player/DataTables/Quarterback/College/QuarterbackCollegeAdvancedPassingStatsTable/QuarterbackCollegeAdvancedPassingStatsTable';
import QuarterbackCollegeAdvancedRushingStatsTable from '../../Player/DataTables/Quarterback/College/QuarterbackCollegeAdvancedRushingStatsTable/QuarterbackCollegeAdvancedRushingStatsTable';
import QuarterbackCollegeBasicRushingStatsTable from '../../Player/DataTables/Quarterback/College/QuarterbackCollegeBasicRushingStatsTable/QuarterbackCollegeBasicRushingStatsTable';
import RunningBackCollegeBasicRushingStatsTable from '../../Player/DataTables/RunningBack/College/RunningBackCollegeBasicRushingStatsTable/RunningBackCollegeBasicRushingStatsTable';
import RunningBackCollegeAdvancedRushingStatsTable from '../../Player/DataTables/RunningBack/College/RunningBackCollegeAdvancedRushingStatsTable/RunningBackCollegeAdvancedRushingStatsTable';
import RunningBackCollegeBasicReceivingStatsTable from '../../Player/DataTables/RunningBack/College/RunningBackCollegeBasicReceivingStatsTable/RunningBackCollegeBasicReceivingStatsTable';
import RunningBackCollegeAdvancedReceivingStatsTable from '../../Player/DataTables/RunningBack/College/RunningBackCollegeAdvancedReceivingStatsTable/RunningBackCollegeAdvancedReceivingStatsTable';
import WideReceiverCollegeBasicReceivingStatsTable from '../../Player/DataTables/WideReceiver/College/WideReceiverCollegeBasicReceivingStatsTable/WideReceiverCollegeBasicReceivingStatsTable';
import WideReceiverCollegeAdvancedReceivingStatsTable from '../../Player/DataTables/WideReceiver/College/WideReceiverCollegeAdvancedReceivingStatsTable/WideReceiverCollegeAdvancedReceivingStatsTable';
import TightEndCollegeBasicReceivingStatsTable from '../../Player/DataTables/TightEnds/College/TightEndCollegeBasicReceivingStatsTable/TightEndCollegeBasicReceivingStatsTable';
import TightEndCollegeAdvancedReceivingStatsTable from '../../Player/DataTables/TightEnds/College/TightEndCollegeAdvancedReceivingStatsTable/TightEndCollegeAdvancedReceivingStatsTable';
import QuarterbackProBasicPassingStatsTable from '../../Player/DataTables/Quarterback/Pro/QuarterbackProBasicPassingStatsTable/QuarterbackProBasicPassingStatsTable';
import QuarterbackProAdvancedPassingStatsTable from '../../Player/DataTables/Quarterback/Pro/QuarterbackProAdvancedPassingStatsTable/QuarterbackProAdvancedPassingStatsTable';
import QuarterbackProAdvancedRushingStatsTable from '../../Player/DataTables/Quarterback/Pro/QuarterbackProAdvancedRushingStatsTable/QuarterbacksProAdvancedRushingStatsTable';
import QuarterbackProBasicRushingStatsTable from '../../Player/DataTables/Quarterback/Pro/QuarterbackProBasicRushingStatsTable/QuarterbackProBasicRushingStatsTable';
import RunningBackProBasicRushingStatsTable from '../../Player/DataTables/RunningBack/Pro/RunningBackProBasicRushingStatsTable/RunningBackProBasicRushingStatsTable';
import RunningBackProAdvancedRushingStatsTable from '../DataTables/RunningBack/Pro/RunningBackProAdvancedRushingStatsTable/RunningBackProAdvancedRushingStatsTable';
import RunningBackProBasicReceivingStatsTable from '../../Player/DataTables/RunningBack/Pro/RunningBackProBasicReceivingStatsTable/RunningBackProBasicReceivingStatsTable';
import RunningBackProAdvancedReceivingStatsTable from '../../Player/DataTables/RunningBack/Pro/RunningBackProAdvancedReceivingStatsTable/RunningBackProAdvancedReceivingStatsTable';
import WideReceiverProBasicReceivingStatsTable from '../../Player/DataTables/WideReceiver/Pro/WideReceiverProBasicReceivingStatsTable/WideReceiverProBasicReceivingStatsTable';
import WideReceiverProAdvancedReceivingStatsTable from '../../Player/DataTables/WideReceiver/Pro/WideReceiverProAdvancedReceivingStatsTable/WideReceiverProAdvancedReceivingStatsTable';
import TightEndProBasicReceivingStatsTable from '../../Player/DataTables/TightEnds/Pro/TightEndProBasicReceivingStatsTable/TightEndProBasicReceivingStatsTable';
import TightEndProAdvancedReceivingStatsTable from '../../Player/DataTables/TightEnds/Pro/TightEndProAdvancedReceivingStatsTable/TightEndProAdvancedReceivingStatsTable';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

export default function PlayerProfileCard(props) {
	const { id: paramId } = useParams();
	const { search } = useLocation();
	const params = new URLSearchParams(search);

	// Determine ID and position from props or URL parameters
	const id = (props.player && props.player.id) || paramId;
	const position =
		(props.player && props.player.position) || params.get('position');

	const [
		player,
		setPlayer
	] = useState([]);
	const rankModalRef = useRef(null);
	const currentPlayerRef = useRef(null);
	const [
		gradeRanks,
		setGradeRanks
	] = useState([]);
	const [
		visibleRankModal,
		setVisibleRankModal
	] = useState(null);
	const [
		loading,
		setLoading
	] = useState(false);

	// Fetch player data on component mount
	useEffect(
		() => {
			window.scrollTo(0, 0); // Scroll to the top of the window when the component mounts

			fetch(
				`${props.get_player_endpoint.replace('id', id)}?position=${position}`
			)
				.then((response) => response.json())
				.then((result) => {
					if (result) {
						setPlayer([
							result.data
						]);
						// console.log('Player data:', result.data);
					} else {
						console.error('Data is not available:', result);
					}
				})
				.catch((error) => {
					console.error('Error fetching data:', error);
				});
		},
		[
			props.get_player_endpoint,
			id,
			position
		]
	);

	// Handle rank modal click
	const handleRankClick = async (gradeType, isCollegePlayer = false) => {
		if (visibleRankModal === gradeType) {
			setVisibleRankModal(null);
		} else {
			setLoading(true);
			setVisibleRankModal(gradeType);

			try {
				// Include isCollegePlayer as a query parameter, defaulting to false if not provided
				const response = await fetch(
					`/api/v1/players/position-ranks/${encodeURIComponent(
						position
					)}?is_college_player=${isCollegePlayer}`
				);
				if (!response.ok) throw new Error('Network response was not ok');
				const data = await response.json();
				setLoading(false);
				setGradeRanks(data);
			} catch (error) {
				console.error('Failed to fetch grade ranks:', error);
			}
		}
	};

	// Handle player click
	const handlePlayerClick = (playerId, playerPosition) => {
		setVisibleRankModal(null);
		window.location.href = `/players/${playerId}?position=${playerPosition}`;
	};

	// Scroll to the selected player in the modal
	useEffect(
		() => {
			setTimeout(() => {
				if (visibleRankModal && currentPlayerRef.current) {
					currentPlayerRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					});
				}
			}, 500);
		},
		[
			visibleRankModal,
			currentPlayerRef
		]
	);

	// Handle clicks outside the rank modal to close it
	const handleClickOutside = (event) => {
		if (rankModalRef.current && !rankModalRef.current.contains(event.target)) {
			setVisibleRankModal(null);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const [
		selectedPlayingLevelType,
		setSelectedPlayingLevelType
	] = useState('pro');

	const handleProStatsButtonClick = () => {
		setSelectedPlayingLevelType(
			(prevType) => (prevType === 'pro' ? 'pro' : 'pro')
		);
	};

	const handleCollegeStatsButtonClick = () => {
		setSelectedPlayingLevelType(
			(prevType) => (prevType === 'college' ? 'college' : 'college')
		);
	};

	const playerPosition = (player[0] && player[0].position) || '';

	const [
		selectedStatType,
		setSelectedStatType
	] = useState('');

	useEffect(
		() => {
			if (playerPosition === 'running back') {
				setSelectedStatType('rushing');
			} else {
				setSelectedStatType('passing');
			}
		},
		[
			playerPosition
		]
	);

	const handlePassingStatsButtonClick = () => {
		setSelectedStatType('passing');
	};

	const handleRushingStatsButtonClick = () => {
		setSelectedStatType('rushing');
	};

	const handleReceivingStatsButtonClick = () => {
		setSelectedStatType('receiving');
	};

	// Get bar2Label, bar2Value, lowerRange, and upperRange based on player position.
	// Used for HorizontalBarChart in AthleticismScoreCard
	const {
		label: bar2Label,
		value: bar2Value,
		lowerRange: lowerRange,
		upperRange: upperRange
	} = getBarPropsByPosition(playerPosition);

	// Create Video Array for VideoCarousel
	const videos = player[0]
		? [
				player[0].video_url_1,
				player[0].video_url_2,
				player[0].video_url_3
			].filter((url) => url)
		: [];

	const pVideos = player[0]
		? [
				player[0].fitness_video_url_1,
				player[0].fitness_video_url_2
			].filter((url) => url)
		: [];

	const [
		scrolledPastBanner,
		setScrolledPastBanner
	] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const bannerHeight = document.querySelector('.banner-section')
				.offsetHeight;
			const scrolled = window.scrollY > bannerHeight;
			setScrolledPastBanner(scrolled);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (!player[0]) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<div>
			{/* Player Portrait Card Section */}
			<div className="banner-section">
				<PlayerPortraitCard
					player={player}
					getPlayersEndpoint={props.get_players_endpoint}
					create_checkout_session_endpoint={
						props.create_checkout_session_endpoint
					}
					stripe_public_key={props.stripe_public_key}
					stripe_product_essential_id={props.stripe_product_essential_id}
					stripe_product_advanced_id={props.stripe_product_advanced_id}
					stripe_product_ultimate_id={props.stripe_product_ultimate_id}
				/>
			</div>

			{/* Fixed Player Banner  - Displays after scrolling past the Player Portrait Card Section */}
			{scrolledPastBanner && (
				<FixedPlayerBanner
					scrolledPastBanner={scrolledPastBanner}
					player={player}
					getPositionCapitalization={getPositionCapitalization}
					getPositionAbbreviation={getPositionAbbreviation}
				/>
			)}

			{/* FantastFitnessScoreCard Section */}
			<div className="text-3xl font-bold mt-14 mb-3">
				<h4>Fantasy Fitness™ Scores</h4>
			</div>
			<FantasyFitnessScoreCard
				loading={loading}
				player={player}
				gradeRanks={gradeRanks}
				handleRankClick={handleRankClick}
				visibleRankModal={visibleRankModal}
				handlePlayerClick={handlePlayerClick}
				rankModalRef={rankModalRef}
				currentPlayerRef={currentPlayerRef}
			/>

			{/* Production Section */}
			<div className="text-3xl font-bold mt-14 mb-3">
				<h4>Production</h4>
			</div>
			{/* Button Sets */}
			<div className="flex flex-col lg:flex-row justify-start items-start space-y-0 lg:space-x-6">
				{/* NFL & College Stats Buttons */}
				<div>
					<ProCollegeButtons
						selectedPlayingLevelType={selectedPlayingLevelType}
						setSelectedPlayingLevelType={setSelectedPlayingLevelType}
						handleCollegeStatsButtonClick={handleCollegeStatsButtonClick}
						handleProStatsButtonClick={handleProStatsButtonClick}
					/>
				</div>

				{/* Conditional Button Sets */}
				{playerPosition === 'running back' && (
					<div>
						{/* Rushing & Receiving Stats Buttons */}
						<StatsTypeButtons
							statsType1="Rushing"
							statsType2="Receiving"
							selectedStatType={selectedStatType}
							handleStatsType1ButtonClick={handleRushingStatsButtonClick}
							handleStatsType2ButtonClick={handleReceivingStatsButtonClick}
						/>
					</div>
				)}

				{playerPosition === 'quarterback' && (
					<div>
						{/* Passing & Rushing Stats Buttons */}
						<StatsTypeButtons
							statsType1="Passing"
							statsType2="Rushing"
							selectedStatType={selectedStatType}
							handleStatsType1ButtonClick={handlePassingStatsButtonClick}
							handleStatsType2ButtonClick={handleRushingStatsButtonClick}
						/>
					</div>
				)}
			</div>

			{/* Display QB Stats */}
			{playerPosition === 'quarterback' && (
				<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg p-6 lg:p-8">
					{/* NFL Stats Section */}
					{selectedPlayingLevelType === 'pro' && (
						<div>
							{selectedStatType === 'passing' && (
								<div>
									<div className="mb-8">
										<QuarterbackProBasicPassingStatsTable
											title="Basic NFL Passing Stats"
											data={player}
										/>
									</div>
									<div className="mb-4">
										<QuarterbackProAdvancedPassingStatsTable
											title="Advanced NFL Passing Metrics"
											data={player}
										/>
									</div>
								</div>
							)}
							{selectedStatType === 'rushing' && (
								<div>
									<div className="mb-8">
										<QuarterbackProBasicRushingStatsTable
											title="Basic NFL Rushing Stats"
											data={player}
										/>
									</div>
									<div className="mb-4">
										<QuarterbackProAdvancedRushingStatsTable
											title="Advanced NFL Rushing Metrics"
											data={player}
										/>
									</div>
								</div>
							)}
						</div>
					)}
					{/* College Stats Section */}
					{selectedPlayingLevelType === 'college' && (
						<div>
							{selectedStatType === 'passing' && (
								<div>
									<div className="mb-8">
										<QuarterbackCollegeBasicPassingStatsTable
											title="Basic College Passing Stats"
											data={player}
										/>
									</div>
									<div className="mb-4">
										<QuarterbackCollegeAdvancedPassingStatsTable
											title="Advanced College Passing Metrics"
											data={player}
										/>
									</div>
								</div>
							)}
							{selectedStatType === 'rushing' && (
								<div>
									<div className="mb-8">
										<QuarterbackCollegeBasicRushingStatsTable
											title="Basic College Rushing Stats"
											data={player}
										/>
									</div>
									<div className="mb-4">
										<QuarterbackCollegeAdvancedRushingStatsTable
											title="Advanced College Rushing Metrics"
											data={player}
										/>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			)}

			{/* Display RB Stats */}
			{playerPosition === 'running back' && (
				<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg p-6 lg:p-8">
					{/* NFL Stats Section */}
					{selectedPlayingLevelType === 'pro' && (
						<div>
							{selectedStatType === 'rushing' && (
								<div>
									<div className="mb-8">
										<RunningBackProBasicRushingStatsTable
											title="Basic NFL Rushing Stats"
											data={player}
										/>
									</div>
									<div className="mb-4">
										<RunningBackProAdvancedRushingStatsTable
											title="Advanced NFL Rushing Metrics"
											data={player}
										/>
									</div>
								</div>
							)}
							{selectedStatType === 'receiving' && (
								<div>
									<div className="mb-8">
										<RunningBackProBasicReceivingStatsTable
											title="Basic NFL Receiving Stats"
											data={player}
										/>
									</div>
									<div className="mb-4">
										<RunningBackProAdvancedReceivingStatsTable
											title="Advanced NFL Receiving Metrics"
											data={player}
										/>
									</div>
								</div>
							)}
						</div>
					)}

					{/* College Stats Section */}
					{selectedPlayingLevelType === 'college' && (
						<div>
							{selectedStatType === 'rushing' && (
								<div>
									<div className="mb-8">
										<RunningBackCollegeBasicRushingStatsTable
											title="Basic College Rushing Stats"
											data={player}
										/>
									</div>
									<div className="mb-4">
										<RunningBackCollegeAdvancedRushingStatsTable
											title="Advanced College Rushing Metrics"
											data={player}
										/>
									</div>
								</div>
							)}
							{selectedStatType === 'receiving' && (
								<div>
									<div className="mb-8">
										<RunningBackCollegeBasicReceivingStatsTable
											title="Basic College Receiving Stats"
											data={player}
										/>
									</div>
									<div className="mb-4">
										<RunningBackCollegeAdvancedReceivingStatsTable
											title="Advanced College Receiving Metrics"
											data={player}
										/>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			)}

			{/* Display WR Stats */}
			{playerPosition === 'wide receiver' && (
				<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg p-6 lg:p-8">
					{/* NFL Stats Section */}
					{selectedPlayingLevelType === 'pro' && (
						<div>
							<div className="mb-8">
								<WideReceiverProBasicReceivingStatsTable
									title="Basic NFL Receiving Stats"
									data={player}
								/>
							</div>
							<div className="mb-4">
								<WideReceiverProAdvancedReceivingStatsTable
									title="Advanced NFL Receiving Metrics"
									data={player}
								/>
							</div>
						</div>
					)}
					{/* College Stats Section */}
					{selectedPlayingLevelType === 'college' && (
						<div>
							<div className="mb-8">
								<WideReceiverCollegeBasicReceivingStatsTable
									title="Basic College Receiving Stats"
									data={player}
								/>
							</div>
							<div className="mb-4">
								<WideReceiverCollegeAdvancedReceivingStatsTable
									title="Advanced College Receiving Metrics"
									data={player}
								/>
							</div>
						</div>
					)}
				</div>
			)}

			{/* Display TE Stats */}
			{playerPosition === 'tight end' && (
				<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg p-6 lg:p-8">
					{/* NFL Stats Section */}
					{selectedPlayingLevelType === 'pro' && (
						<div>
							<div className="mb-8">
								<TightEndProBasicReceivingStatsTable
									title="Basic NFL Receiving Stats"
									data={player}
								/>
							</div>
							<div className="mb-4">
								<TightEndProAdvancedReceivingStatsTable
									title="Advanced NFL Receiving Metrics"
									data={player}
								/>
							</div>
						</div>
					)}
					{/* College Stats Section */}
					{selectedPlayingLevelType === 'college' && (
						<div>
							<div className="mb-8">
								<TightEndCollegeBasicReceivingStatsTable
									title="Basic College Receiving Stats"
									data={player}
								/>
							</div>
							<div className="mb-4">
								<TightEndCollegeAdvancedReceivingStatsTable
									title="Advanced College Receiving Metrics"
									data={player}
								/>
							</div>
						</div>
					)}
				</div>
			)}

			{/* Athleticism Section */}
			<div className="text-3xl font-bold mt-14 mb-3">
				<h4>Athleticism</h4>
			</div>
			<AthleticismScoreCard
				player={player}
				playerPosition={playerPosition}
				bar2Label={bar2Label}
				bar2Value={bar2Value}
				lowerRange={lowerRange}
				upperRange={upperRange}
			/>

			{/* Team Environment */}
			<div className="text-3xl font-bold mt-14 mb-3">
				<h4>Team Environment</h4>
			</div>
			<TeamEnvironmentScoreCard
				player={player}
				gradeRanks={gradeRanks}
				handleRankClick={handleRankClick}
				visibleRankModal={visibleRankModal}
				handlePlayerClick={handlePlayerClick}
				currentPlayerRef={currentPlayerRef}
				rankModalRef={rankModalRef}
			/>

			{/* Player Videos */}
			{player[0] &&
			player[0].video_url_1 && (
				<div>
					<div className="text-3xl font-bold mt-14 mb-3">
						<h4>Tale of the Tape</h4>
					</div>
					<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg">
						<VideoCarousel videos={videos} />
					</div>
				</div>
			)}

			{player[0] &&
			player[0].same_team_position_peers.length > 0 && (
				<div>
					{/* Positional Depth Chart */}
					<div className="text-3xl font-bold mt-14 mb-3">
						<h4>Positional Competition</h4>
					</div>
					{/* Depth Chart Card Section */}
					{player[0].same_team_position_peers.map((sameTeamPositionPeer) => (
						<div className="mb-8" key={sameTeamPositionPeer.id}>
							<DepthChartCard
								loading={loading}
								player={sameTeamPositionPeer}
								gradeRanks={gradeRanks}
								handleRankClick={handleRankClick}
								visibleRankModal={visibleRankModal}
								handlePlayerClick={handlePlayerClick}
								rankModalRef={rankModalRef}
								currentPlayerRef={currentPlayerRef}
							/>
						</div>
					))}
				</div>
			)}

			{player[0] &&
			player[0].team &&
			player[0].team.head_coach_summary && (
				<div>
					{/* Coaching Information */}
					<div className="text-3xl font-bold mt-14 mb-3">
						<h4>Coaches</h4>
					</div>
					{/* Coaches Card Section */}
					<CoachesCard team={player[0].team} />
				</div>
			)}

			{/* Player Fitness Videos */}
			{player[0] &&
			player[0].fitness_video_url_1 && (
				<div>
					<div className="text-3xl font-bold mt-14 mb-3">
						<h4>
							Fantasy Fitness Meets Physical Fitness: Train Like{' '}
							{player[0] && player[0].first_name}
						</h4>
					</div>
					<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg">
						<VideoCarousel videos={pVideos} />
					</div>
				</div>
			)}
		</div>
	);
}
