import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';
import getPositionCapitalization from '../../../utils/getPositionCapitalization';
import getBarPropsByPosition from '../../../utils/getBarPropsByPosition';

import PlayerPortraitCard from '../../Player/PlayerPortraitCard/PlayerPortraitCard';
import FixedPlayerBanner from '../../Player/FixedPlayerBanner/FixedPlayerBanner';
import ProspectScoreCard from '../../ScoreCards/ProspectScoreCard/ProspectScoreCard';
import AthleticismScoreCard from '../../ScoreCards/AthleticismScoreCard/AthleticismScoreCard';
import VideoCarousel from '../../VideoCarousel/VideoCarousel';
import PricingTableModal from '../../PricingTableModal/PricingTableModal';

import StatsTypeButtons from '../../Buttons/StatsTypeButtons/StatsTypeButtons';
import QuarterbackCollegeBasicPassingStatsTable from '../../Player/DataTables/Quarterback/College/QuarterbackCollegeBasicPassingStatsTable/QuarterbackCollegeBasicPassingStatsTable';
import QuarterbackCollegeAdvancedPassingStatsTable from '../../Player/DataTables/Quarterback/College/QuarterbackCollegeAdvancedPassingStatsTable/QuarterbackCollegeAdvancedPassingStatsTable';
import QuarterbackCollegeAdvancedRushingStatsTable from '../../Player/DataTables/Quarterback/College/QuarterbackCollegeAdvancedRushingStatsTable/QuarterbackCollegeAdvancedRushingStatsTable';
import QuarterbackCollegeBasicRushingStatsTable from '../../Player/DataTables/Quarterback/College/QuarterbackCollegeBasicRushingStatsTable/QuarterbackCollegeBasicRushingStatsTable';
import RunningBackCollegeBasicRushingStatsTable from '../../Player/DataTables/RunningBack/College/RunningBackCollegeBasicRushingStatsTable/RunningBackCollegeBasicRushingStatsTable';
import RunningBackCollegeAdvancedRushingStatsTable from '../../Player/DataTables/RunningBack/College/RunningBackCollegeAdvancedRushingStatsTable/RunningBackCollegeAdvancedRushingStatsTable';
import RunningBackCollegeBasicReceivingStatsTable from '../../Player/DataTables/RunningBack/College/RunningBackCollegeBasicReceivingStatsTable/RunningBackCollegeBasicReceivingStatsTable';
import RunningBackCollegeAdvancedReceivingStatsTable from '../../Player/DataTables/RunningBack/College/RunningBackCollegeAdvancedReceivingStatsTable/RunningBackCollegeAdvancedReceivingStatsTable';
import WideReceiverCollegeBasicReceivingStatsTable from '../../Player/DataTables/WideReceiver/College/WideReceiverCollegeBasicReceivingStatsTable/WideReceiverCollegeBasicReceivingStatsTable';
import WideReceiverCollegeAdvancedReceivingStatsTable from '../../Player/DataTables/WideReceiver/College/WideReceiverCollegeAdvancedReceivingStatsTable/WideReceiverCollegeAdvancedReceivingStatsTable';
import TightEndCollegeBasicReceivingStatsTable from '../../Player/DataTables/TightEnds/College/TightEndCollegeBasicReceivingStatsTable/TightEndCollegeBasicReceivingStatsTable';
import TightEndCollegeAdvancedReceivingStatsTable from '../../Player/DataTables/TightEnds/College/TightEndCollegeAdvancedReceivingStatsTable/TightEndCollegeAdvancedReceivingStatsTable';

import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

export default function ProspectProfileCard(props) {
	const { subscription } = useAuth();
	const modalRef = useRef(null);
	const { id: paramId } = useParams();
	const { search } = useLocation();
	const params = new URLSearchParams(search);

	// Determine ID and position from props or URL parameters
	const id = (props.player && props.player.id) || paramId;
	const position =
		(props.player && props.player.position) || params.get('position');

	const [
		prospect,
		setProspect
	] = useState([]);
	const rankModalRef = useRef(null);
	const currentPlayerRef = useRef(null);
	const [
		gradeRanks,
		setGradeRanks
	] = useState([]);
	const [
		visibleRankModal,
		setVisibleRankModal
	] = useState(null);
	const [
		isPricingTableModalOpen,
		setIsPricingTableModalOpen
	] = useState(false);
	const [
		loading,
		setLoading
	] = useState(false);

	useEffect(
		() => {
			window.scrollTo(0, 0); // Scroll to the top of the window when the component mounts

			// Fetch prospect data
			fetch(
				`${props.get_prospect_endpoint.replace('id', id)}?position=${position}`
			)
				.then((response) => response.json())
				.then((result) => {
					if (result) {
						setProspect([
							result.data
						]);
						console.log('Prospect data:', result.data);
					} else {
						console.error('Data is not available:', result);
					}
				})
				.catch((error) => {
					console.error('Error fetching data:', error);
				});

			if (!subscription || subscription.status !== 'active') {
				setIsPricingTableModalOpen(true);
			}
		},
		[
			props.get_prospect_endpoint,
			id,
			position,
			subscription
		]
	);

	// Handle rank modal click
	const handleRankClick = async (gradeType, isCollegePlayer = true) => {
		if (visibleRankModal === gradeType) {
			setVisibleRankModal(null);
		} else {
			setLoading(true);
			setVisibleRankModal(gradeType);

			try {
				// Include isCollegePlayer as a query parameter, defaulting to false if not provided
				const response = await fetch(
					`/api/v1/players/position-ranks/${encodeURIComponent(
						position
					)}?is_college_player=${isCollegePlayer}`
				);
				if (!response.ok) throw new Error('Network response was not ok');
				const data = await response.json();
				setLoading(false);
				setGradeRanks(data);
			} catch (error) {
				console.error('Failed to fetch grade ranks:', error);
			}
		}
	};

	// Handle player click
	const handlePlayerClick = (playerId, playerPosition) => {
		setVisibleRankModal(null);
		window.location.href = `/prospects/${playerId}?position=${playerPosition}`;
	};

	// Scroll to the selected player in the modal
	useEffect(
		() => {
			setTimeout(() => {
				if (visibleRankModal && currentPlayerRef.current) {
					currentPlayerRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					});
				}
			}, 500);
		},
		[
			visibleRankModal,
			currentPlayerRef
		]
	);

	// Handle clicks outside the rank modal to close it
	const handleClickOutside = (event) => {
		if (rankModalRef.current && !rankModalRef.current.contains(event.target)) {
			setVisibleRankModal(null);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const playerPosition = (prospect[0] && prospect[0].position) || '';

	const [
		selectedStatType,
		setSelectedStatType
	] = useState('');

	useEffect(
		() => {
			if (playerPosition === 'running back') {
				setSelectedStatType('rushing');
			} else {
				setSelectedStatType('passing');
			}
		},
		[
			playerPosition
		]
	);

	const handlePassingStatsButtonClick = () => {
		setSelectedStatType('passing');
	};

	const handleRushingStatsButtonClick = () => {
		setSelectedStatType('rushing');
	};

	const handleReceivingStatsButtonClick = () => {
		setSelectedStatType('receiving');
	};

	// Get bar2Label, bar2Value, lowerRange, and upperRange based on player position.
	// Used for HorizontalBarChart in AthleticismScoreCard
	const {
		label: bar2Label,
		value: bar2Value,
		lowerRange: lowerRange,
		upperRange: upperRange
	} = getBarPropsByPosition(playerPosition);

	const videos = prospect[0]
		? [
				prospect[0].video_url_1,
				prospect[0].video_url_2,
				prospect[0].video_url_3
			].filter((url) => url)
		: [];

	const [
		scrolledPastBanner,
		setScrolledPastBanner
	] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const bannerHeight = document.querySelector('.banner-section')
				.offsetHeight;
			const scrolled = window.scrollY > bannerHeight;
			setScrolledPastBanner(scrolled);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (!prospect[0]) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<div>
			{/* Player Portrait Card Section */}
			<div className="banner-section">
				<PlayerPortraitCard
					player={prospect}
					get_prospects_endpoint={props.get_prospects_endpoint}
					isProspectProfileCard={true}
					create_checkout_session_endpoint={
						props.create_checkout_session_endpoint
					}
					stripe_public_key={props.stripe_public_key}
					stripe_product_essential_id={props.stripe_product_essential_id}
					stripe_product_advanced_id={props.stripe_product_advanced_id}
					stripe_product_ultimate_id={props.stripe_product_ultimate_id}
				/>
			</div>

			{/* Fixed Player Banner  - Displays after scrolling past the Player Portrait Card Section */}
			{scrolledPastBanner && (
				<FixedPlayerBanner
					scrolledPastBanner={scrolledPastBanner}
					player={prospect}
					getPositionCapitalization={getPositionCapitalization}
					getPositionAbbreviation={getPositionAbbreviation}
				/>
			)}
			{/* FantastFitnessScoreCard Section */}
			<div className="text-3xl font-bold mt-14 mb-3">
				<h4>Fantasy Fitness™ Scores</h4>
			</div>
			<ProspectScoreCard
				loading={loading}
				player={prospect}
				gradeRanks={gradeRanks}
				handleRankClick={handleRankClick}
				visibleRankModal={visibleRankModal}
				handlePlayerClick={handlePlayerClick}
				rankModalRef={rankModalRef}
				currentPlayerRef={currentPlayerRef}
			/>
			{/* Production Section */}
			<div className="text-3xl font-bold mt-14 mb-3">
				<h4>Production</h4>
			</div>
			{/* NFL & College Stats Buttons */}
			<div className="flex justify-middle space-x-4" />
			{playerPosition === 'running back' && (
				<div>
					{/* Rushing & Receiving Stats Buttons */}
					<StatsTypeButtons
						statsType1="Rushing"
						statsType2="Receiving"
						selectedStatType={selectedStatType}
						handleStatsType1ButtonClick={handleRushingStatsButtonClick}
						handleStatsType2ButtonClick={handleReceivingStatsButtonClick}
					/>
				</div>
			)}
			{playerPosition === 'quarterback' && (
				<div>
					{/* Passing & Rushing Stats Buttons */}
					<StatsTypeButtons
						statsType1="Passing"
						statsType2="Rushing"
						selectedStatType={selectedStatType}
						handleStatsType1ButtonClick={handlePassingStatsButtonClick}
						handleStatsType2ButtonClick={handleRushingStatsButtonClick}
					/>
				</div>
			)}

			{/* Display QB Stats */}
			{playerPosition === 'quarterback' && (
				<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg p-6 lg:p-8">
					{/* College Stats Section */}
					<div>
						{selectedStatType === 'passing' && (
							<div>
								<div className="mb-8">
									<QuarterbackCollegeBasicPassingStatsTable
										title="Basic College Passing Stats"
										data={prospect}
									/>
								</div>
								<div className="mb-4">
									<QuarterbackCollegeAdvancedPassingStatsTable
										title="Advanced College Passing Metrics"
										data={prospect}
									/>
								</div>
							</div>
						)}
						{selectedStatType === 'rushing' && (
							<div>
								<div className="mb-8">
									<QuarterbackCollegeBasicRushingStatsTable
										title="Basic College Rushing Stats"
										data={prospect}
									/>
								</div>
								<div className="mb-4">
									<QuarterbackCollegeAdvancedRushingStatsTable
										title="Advanced College Rushing Metrics"
										data={prospect}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			)}

			{/* Display RB Stats */}
			{playerPosition === 'running back' && (
				<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg p-6 lg:p-8">
					{/* College Stats Section */}
					<div>
						{selectedStatType === 'rushing' && (
							<div>
								<div className="mb-8">
									<RunningBackCollegeBasicRushingStatsTable
										title="Basic College Rushing Stats"
										data={prospect}
									/>
								</div>
								<div className="mb-4">
									<RunningBackCollegeAdvancedRushingStatsTable
										title="Advanced College Rushing Metrics"
										data={prospect}
									/>
								</div>
							</div>
						)}
						{selectedStatType === 'receiving' && (
							<div>
								<div className="mb-8">
									<RunningBackCollegeBasicReceivingStatsTable
										title="Basic College Receiving Stats"
										data={prospect}
									/>
								</div>
								<div className="mb-4">
									<RunningBackCollegeAdvancedReceivingStatsTable
										title="Advanced College Receiving Metrics"
										data={prospect}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			)}

			{/* Display WR Stats */}
			{playerPosition === 'wide receiver' && (
				<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg p-6 lg:p-8">
					{/* College Stats Section */}
					<div>
						<div className="mb-8">
							<WideReceiverCollegeBasicReceivingStatsTable
								title="Basic College Receiving Stats"
								data={prospect}
							/>
						</div>
						<div className="mb-4">
							<WideReceiverCollegeAdvancedReceivingStatsTable
								title="Advanced College Receiving Metrics"
								data={prospect}
							/>
						</div>
					</div>
				</div>
			)}

			{/* Display TE Stats */}
			{playerPosition === 'tight end' && (
				<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg p-6 lg:p-8">
					{/* College Stats Section */}
					<div>
						<div className="mb-8">
							<TightEndCollegeBasicReceivingStatsTable
								title="Basic College Receiving Stats"
								data={prospect}
							/>
						</div>
						<div className="mb-4">
							<TightEndCollegeAdvancedReceivingStatsTable
								title="Advanced College Receiving Metrics"
								data={prospect}
							/>
						</div>
					</div>
				</div>
			)}

			{/* Athleticism Section */}
			<div className="text-3xl font-bold mt-14 mb-3">
				<h4>Athleticism</h4>
			</div>
			<AthleticismScoreCard
				player={prospect}
				playerPosition={playerPosition}
				bar2Label={bar2Label}
				bar2Value={bar2Value}
				lowerRange={lowerRange}
				upperRange={upperRange}
			/>

			{/* Player Videos */}
			{prospect[0] &&
			prospect[0].video_url_1 && (
				<div>
					<div className="text-3xl font-bold mt-14 mb-3">
						<h4>Tale of the Tape</h4>
					</div>
					<div className="grid grid-cols-1 gap-6 bg-white border border-gray-300 rounded-xl shadow-lg">
						<VideoCarousel videos={videos} />
					</div>
				</div>
			)}

			{/* Pricing Table Modal */}
			<PricingTableModal
				isOpen={isPricingTableModalOpen}
				ref={modalRef}
				create_checkout_session_endpoint={
					props.create_checkout_session_endpoint
				}
				stripe_public_key={props.stripe_public_key}
				stripe_product_essential_id={props.stripe_product_essential_id}
				stripe_product_advanced_id={props.stripe_product_advanced_id}
				stripe_product_ultimate_id={props.stripe_product_ultimate_id}
				isProspectProfileCard={true}
			/>
		</div>
	);
}
