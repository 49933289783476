import React from 'react';
import yahooSignInButton from '../../../../assets/images/yahoo_round_primary.png';

const SyncWithYahooModal = ({ show, onClose, authUrl, rosterId }) => {
	if (!show) return null;

	const handleSyncClick = () => {
		window.location.href = `${authUrl}?rosterId=${rosterId}`;
	};

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
			<div className="fixed inset-0 flex items-center justify-center z-50">
				<div className="bg-white p-6 rounded shadow-lg w-full sm:w-1/4">
					<h2 className="text-xl mb-4">Sync Roster with Yahoo</h2>
					<p className="mb-4">
						Do you allow us to sign in and sync your roster with Yahoo?
					</p>
					<div className="flex justify-end">
						<button
							onClick={onClose}
							className="bg-gray-500 text-white px-4 py-2 rounded-xl mr-4"
						>
							Cancel
						</button>
						<button onClick={handleSyncClick} className="w-48">
							<img src={yahooSignInButton} alt="Sync with Yahoo" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SyncWithYahooModal;
