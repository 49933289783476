import React, { Fragment } from 'react';
import safeDivideHelper from '../../../../../Helpers/SafeDivideHelper';
import MobileDataTableCard from '../../../../../DataTables/MobileDataTableCard';
import AdvancedDataTable from '../../../../../DataTables/AdvancedDataTable';

export default function QuarterbackProAdvancedPassingStatsTable(props) {
	return (
		<div>
			<div className="text-xl py-2">
				<h4>{props.title}</h4>
			</div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={props.data}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Category',
								apiName: 'category',
								alignment: 'left'
							},
							{
								displayName: 'Completions Per Game',
								apiName: 'pro_pass_completions_per_game',
								alignment: 'left'
							},
							{
								displayName: 'Attempts Per Game',
								apiName: 'pro_pass_attempts_per_game',
								alignment: 'left'
							},
							{
								displayName: 'Yards Per Game',
								apiName: 'pro_passing_yards_per_game',
								alignment: 'left'
							},
							{
								displayName: 'TDs  Per Game',
								apiName: 'pro_passing_touchdowns_per_game',
								alignment: 'left'
							},
							{
								displayName: 'Interceptions Per Game',
								apiName: 'pro_interceptions_per_game',
								alignment: 'left'
							},
							{
								displayName: 'Yards Per Completion',
								apiName: 'pro_passing_yards_per_completion',
								alignment: 'left'
							},
							{
								displayName: 'Yards Per Attempt',
								apiName: 'pro_passing_yards_per_attempt',
								alignment: 'left'
							},
							{
								displayName: 'Completions Per TD',
								apiName: 'pro_pass_completions_per_touchdown',
								alignment: 'left'
							},
							{
								displayName: 'Attempts Per TD',
								apiName: 'pro_pass_attempts_per_touchdown',
								alignment: 'left'
							},
							{
								displayName: 'Yards Per TD',
								apiName: 'pro_passing_yards_per_touchdown',
								alignment: 'left'
							},
							{
								displayName: 'Completions Per INT',
								apiName: 'pro_pass_completions_per_interception',
								alignment: 'left'
							},
							{
								displayName: 'Attempts Per INT',
								apiName: 'pro_pass_attempts_per_interception',
								alignment: 'left'
							},
							{
								displayName: 'Yards Per INT',
								apiName: 'pro_passing_yards_per_interception',
								alignment: 'left'
							},
							{
								displayName: 'TDs Per INT',
								apiName: 'pro_passing_touchdowns_per_interception',
								alignment: 'left'
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div className="flex flex-wrap">
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[1].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_pass_completions_per_game}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[2].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_pass_attempts_per_game}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[3].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_passing_yards_per_game}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[4].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_passing_touchdowns_per_game}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[5].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_interceptions_per_game}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[6].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_passing_yards_per_completion}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[7].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_passing_yards_per_attempt}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[8].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_pass_completions_per_touchdown}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[9].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_pass_attempts_per_touchdown}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[10].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_passing_yards_per_touchdown}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[11].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_pass_completions_per_interception}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[12].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_pass_attempts_per_interception}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[13].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_passing_yards_per_interception}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[14].displayName}
									</div>
									<div className="w-1/2">
										{row.pro_passing_touchdowns_per_interception}
									</div>
								</div>
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={props.data}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					columns={[
						{
							displayName: 'Category',
							apiName: 'category',
							alignment: 'left'
						},
						{
							displayName: 'Completions Per Game',
							apiName: 'pro_pass_completions_per_game',
							alignment: 'left'
						},
						{
							displayName: 'Attempts Per Game',
							apiName: 'pro_pass_attempts_per_game',
							alignment: 'left'
						},
						{
							displayName: 'Yards Per Game',
							apiName: 'pro_passing_yards_per_game',
							alignment: 'left'
						},
						{
							displayName: 'TDs  Per Game',
							apiName: 'pro_passing_touchdowns_per_game',
							alignment: 'left'
						},
						{
							displayName: 'Interceptions Per Game',
							apiName: 'pro_interceptions_per_game',
							alignment: 'left'
						},
						{
							displayName: 'Yards Per Completion',
							apiName: 'pro_passing_yards_per_completion',
							alignment: 'left'
						},
						{
							displayName: 'Yards Per Attempt',
							apiName: 'pro_passing_yards_per_attempt',
							alignment: 'left'
						},
						{
							displayName: 'Pass Completions Per TD',
							apiName: 'pro_pass_completions_per_touchdown',
							alignment: 'left'
						},
						{
							displayName: 'Pass Attempts Per TD',
							apiName: 'pro_pass_attempts_per_touchdown',
							alignment: 'left'
						},
						{
							displayName: 'Yards Per TD',
							apiName: 'pro_passing_yards_per_touchdown',
							alignment: 'left'
						},
						{
							displayName: 'Completions Per INT',
							apiName: 'pro_pass_completions_per_interception',
							alignment: 'left'
						},
						{
							displayName: 'Attempts Per INT',
							apiName: 'pro_pass_attempts_per_interception',
							alignment: 'left'
						},
						{
							displayName: 'Yards Per INT',
							apiName: 'pro_passing_yards_per_interception',
							alignment: 'left'
						},
						{
							displayName: 'Passing TDs Per INT',
							apiName: 'pro_passing_touchdowns_per_interception',
							alignment: 'left'
						}
					]}
					rowRenderer={(row) => {
						return (
							<Fragment>
								<tr key={row.id} className="bg-white border-t text-gray-800">
									<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10">
										2024
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_pass_completions,
											row.current_year_pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_pass_attempts,
											row.current_year_pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_passing_yards,
											row.current_year_pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_passing_touchdowns,
											row.current_year_pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_interceptions,
											row.current_year_pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_passing_yards,
											row.current_year_pro_pass_completions
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_passing_yards,
											row.current_year_pro_pass_attempts
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_pass_completions,
											row.current_year_pro_passing_touchdowns
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_pass_attempts,
											row.current_year_pro_passing_touchdowns
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_passing_yards,
											row.current_year_pro_passing_touchdowns
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_pass_completions,
											row.current_year_pro_interceptions
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_pass_attempts,
											row.current_year_pro_interceptions
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_passing_yards,
											row.current_year_pro_interceptions
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_passing_touchdowns,
											row.current_year_pro_interceptions
										)}
									</td>
								</tr>
								<tr key={row.id} className="bg-white border-t text-gray-800">
									<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10">
										Career
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_pass_completions,
											row.pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_pass_attempts,
											row.pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_passing_yards,
											row.pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_passing_touchdowns,
											row.pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_interceptions,
											row.pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_passing_yards,
											row.pro_pass_completions
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_passing_yards,
											row.pro_pass_attempts
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_pass_completions,
											row.pro_passing_touchdowns
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_pass_attempts,
											row.pro_passing_touchdowns
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_passing_yards,
											row.pro_passing_touchdowns
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_pass_completions,
											row.pro_interceptions
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_pass_attempts,
											row.pro_interceptions
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_passing_yards,
											row.pro_interceptions
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_passing_touchdowns,
											row.pro_interceptions
										)}
									</td>
								</tr>
							</Fragment>
						);
					}}
				/>
			</div>
		</div>
	);
}
