import React from 'react';
import PageTitle from '../../FormElements/PageTitle';
import PlayersIndexCard from '../../Players/PlayersIndexCard/PlayersIndexCard';

export default function PlayersIndexPage({
	get_player_endpoint,
	get_players_endpoint,
	get_team_endpoint,
	get_roster_by_user_endpoint,
	create_roster_by_user_endpoint,
	update_roster_by_user_endpoint,
	delete_roster_by_user_endpoint,
	create_roster_player_by_roster_endpoint,
	delete_roster_player_by_roster_endpoint,
	get_roster_player_note_endpoint,
	create_roster_player_note_endpoint,
	update_roster_player_note_endpoint,
	get_notes_by_roster_endpoint,
	update_notes_by_roster_endpoint,
	get_draft_notes_by_roster_endpoint,
	update_draft_notes_by_roster_endpoint,
	get_lessons_learned_notes_by_roster_endpoint,
	update_lessons_learned_notes_by_roster_endpoint,
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) {
	// Handle loading and error states
	if (!get_players_endpoint) {
		return (
			<div>
				Error: Missing endpoints required for PlayersIndexPage component
			</div>
		);
	}

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="mt-4">
				<PageTitle title="NFL Players" />
				<div className="grid grid-cols-1">
					<PlayersIndexCard
						get_player_endpoint={get_player_endpoint}
						get_players_endpoint={get_players_endpoint}
						get_team_endpoint={get_team_endpoint}
						get_roster_by_user_endpoint={get_roster_by_user_endpoint}
						create_roster_by_user_endpoint={create_roster_by_user_endpoint}
						update_roster_by_user_endpoint={update_roster_by_user_endpoint}
						delete_roster_by_user_endpoint={delete_roster_by_user_endpoint}
						create_roster_player_by_roster_endpoint={
							create_roster_player_by_roster_endpoint
						}
						delete_roster_player_by_roster_endpoint={
							delete_roster_player_by_roster_endpoint
						}
						get_roster_player_note_endpoint={get_roster_player_note_endpoint}
						create_roster_player_note_endpoint={
							create_roster_player_note_endpoint
						}
						update_roster_player_note_endpoint={
							update_roster_player_note_endpoint
						}
						get_notes_by_roster_endpoint={get_notes_by_roster_endpoint}
						update_notes_by_roster_endpoint={update_notes_by_roster_endpoint}
						get_draft_notes_by_roster_endpoint={
							get_draft_notes_by_roster_endpoint
						}
						update_draft_notes_by_roster_endpoint={
							update_draft_notes_by_roster_endpoint
						}
						get_lessons_learned_notes_by_roster_endpoint={
							get_lessons_learned_notes_by_roster_endpoint
						}
						update_lessons_learned_notes_by_roster_endpoint={
							update_lessons_learned_notes_by_roster_endpoint
						}
						create_checkout_session_endpoint={create_checkout_session_endpoint}
						stripe_public_key={stripe_public_key}
						stripe_product_essential_id={stripe_product_essential_id}
						stripe_product_advanced_id={stripe_product_advanced_id}
						stripe_product_ultimate_id={stripe_product_ultimate_id}
					/>
				</div>
			</div>
		</div>
	);
}
