import React from 'react';

const ClearFiltersButton = ({ onClick }) => {
	return (
		<div className="flex items-center">
			<button onClick={onClick} className="text-gray-800 hover:underline">
				Clear All
			</button>
		</div>
	);
};

export default ClearFiltersButton;
