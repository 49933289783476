import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import getInjuryStatusColor from '../../../../utils/getInjuryStatusColor';

const InjuryStatusCard = ({ injuryStatuses, players, isDraftModeEnabled }) => {
	// Function to determine injury status based on injury_description
	const getInjuryStatus = (status) => {
		if (status.includes('Active')) return 'Active';
		if (status.includes('Probable')) return 'Probable';
		if (status.includes('Questionable')) return 'Questionable';
		if (status.includes('Doubtful')) return 'Doubtful';
		if (status.includes('Out')) return 'Out';

		if (status.includes('IR')) return 'IR';
		return 'Unknown'; // Default to Unknown for other cases
	};

	// Group players by injury status
	const playersByStatus = players.reduce((acc, player) => {
		const status = getInjuryStatus(player.status); // Get the injury status
		// console.log(
		// 	`Player: ${player.first_name} ${player.last_name}, Injury Status: ${status}`
		// ); // Log each player's status
		if (!acc[status]) acc[status] = [];
		acc[status].push(`${player.first_name} ${player.last_name}`);
		return acc;
	}, {});

	// Log playersByStatus to ensure it's populated correctly
	// console.log('playersByStatus:', playersByStatus);

	// Filter data to include only statuses with counts > 0
	const injuryData = Object.entries(injuryStatuses).filter(
		(
			[
				,
				count
			]
		) => count > 0
	);

	// Log injuryData to verify injury statuses with counts > 0
	// console.log('injuryData:', injuryData);

	// Define the desired order of injury statuses
	const injuryStatusOrder = [
		'Active',
		'Probable',
		'Questionable',
		'Doubtful',
		'Out',
		'IR'
	];

	// Sort injuryData by the predefined order
	const sortedInjuryData = injuryData.sort(
		(
			[
				statusA
			],
			[
				statusB
			]
		) => injuryStatusOrder.indexOf(statusA) - injuryStatusOrder.indexOf(statusB)
	);

	const chartData = {
		series: injuryData.map(
			(
				[
					,
					count
				]
			) => count
		),
		options: {
			chart: {
				type: 'donut',
				toolbar: { show: false }
			},
			labels: injuryData.map(
				(
					[
						status
					]
				) => status
			),
			dataLabels: {
				enabled: true,
				formatter: (val, opts) => {
					const count = opts.w.globals.series[opts.seriesIndex];
					return `${count}`;
				},
				style: {
					fontSize: '12px',
					fontWeight: 'normal',
					colors: [
						'#FFF'
					]
				}
			},
			plotOptions: {
				pie: {
					dataLabels: { offset: -1 },
					donut: { size: '65%' }
				}
			},
			tooltip: !isDraftModeEnabled
				? {
						custom: ({ seriesIndex, w }) => {
							const status = w.config.labels[seriesIndex]; // Get the injury status from the label
							const playerList = playersByStatus[status] || []; // Get the player names for that status

							// console.log('Tooltip players for status:', status); // Log the players in the tooltip

							return `
      <div class="px-2 py-1 bg-white shadow-lg border border-gray-300 rounded text-black">
        <strong>${status}</strong>
        <ul>
          ${playerList
						.map((player) => `<li style="color: black;">${player}</li>`)
						.join('')}
        </ul>
      </div>
    `;
						}
					}
				: {}, // Default behavior if !isDraftModeEnabled is true
			colors: injuryData.map(
				(
					[
						status
					]
				) => getInjuryStatusColor(status)
			),
			legend: { show: false }
		}
	};

	return (
		<div className="relative bg-white p-4 rounded-lg shadow-lg border border-gray-300">
			{/* Title */}
			<h3 className="text-sm font-medium text-gray-800 mb-3 text-center">
				Injury Summary
			</h3>

			{/* Card Content */}
			<div className="flex justify-center items-center">
				<div className="flex flex-col items-center">
					<Chart
						options={chartData.options}
						series={chartData.series}
						type="donut"
						width="100%"
					/>
					<div className="grid grid-cols-2 gap-x-2 gap-y-2 mt-4">
						{sortedInjuryData.map(([ status
						]) => (
							<div key={status} className="flex items-center space-x-1">
								<span
									className="inline-block w-2.5 h-2.5 rounded-full"
									style={{ backgroundColor: getInjuryStatusColor(status) }}
								/>
								<span className="text-xs text-gray-800">{status}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default InjuryStatusCard;
