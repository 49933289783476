import React from 'react';
import { Link } from 'react-router-dom';
import StatusWidget from '../../StatusWidget/StatusWidget';

const FixedPlayerBanner = ({
	scrolledPastBanner,
	player,
	getPositionCapitalization,
	getPositionAbbreviation
}) => {
	console.log('FixedPlayerBanner.js: player:', player);

	if (!scrolledPastBanner || player.length === 0) return null;

	const {
		image_url,
		first_name,
		last_name,
		is_college_player,
		team,
		jersey_number,
		position,
		status,
		injury_description
	} = player[0];

	return (
		<div className="fixed top-0 left-0 right-0 h-28 bg-white text-black flex items-center justify-center border border-gray-300 shadow-lg z-50">
			<div className="flex items-center">
				<div
					onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
					style={{ cursor: 'pointer' }}
					className="flex items-center"
				>
					<div className="relative mr-4">
						<img
							src={`${image_url}?w=75&h=75`}
							alt={`${first_name} ${last_name}`}
							style={{
								width: '75px',
								height: '75px',
								objectFit: 'cover',
								border: '1px solid #ddd',
								borderRadius: '50%',
								backgroundColor: 'white'
							}}
						/>
						<div className="absolute top-2 left-12 transform translate-x-1/2 -translate-y-1/2">
							{!is_college_player && ( // If the player is not a college player, display the status widget
								<StatusWidget
									status={status}
									description={injury_description}
									hideLabel={true}
									is75pxThumbnail={true}
								/>
							)}
						</div>
					</div>
					<div>
						<div className="text-2xl font-bold">
							{first_name} {last_name}
						</div>
						<div>
							<div className="text-lg text-gray-500">
								{team && (
									<span>
										{team.name} <span className="hidden md:contents"> • </span>
									</span>
								)}
								<span className="contents md:hidden">
									{jersey_number && !team && `#${jersey_number}`}
								</span>
								<span className="hidden md:contents">
									{jersey_number && `#${jersey_number}`}
								</span>
								<span className="hidden md:contents">
									{position && ` • ${getPositionCapitalization(position)}`}
								</span>
								<span className="contents md:hidden">
									{position && ` • ${getPositionAbbreviation(position)}`}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FixedPlayerBanner;
