import React, { Fragment } from 'react';
import safeDivideHelper from '../../../../../Helpers/SafeDivideHelper';
import MobileDataTableCard from '../../../../../DataTables/MobileDataTableCard';
import AdvancedDataTable from '../../../../../DataTables/AdvancedDataTable';

export default function TightEndProAdvancedReceivingStatsTable(props) {
	return (
		<div>
			<div className="text-xl py-2">
				<h4>{props.title}</h4>
			</div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={props.data}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Category',
								apiName: 'category',
								alignment: 'left'
							},
							{
								displayName: 'Receptions Per Game',
								apiName: 'pro_receptions_per_game',
								alignment: 'left'
							},
							{
								displayName: 'Receiving Yards Per Game',
								apiName: 'pro_receiving_yards_per_game',
								alignment: 'left'
							},
							{
								displayName: 'Receiving TDs Per Game',
								apiName: 'pro_receiving_touchdowns_per_game',
								alignment: 'left'
							},
							{
								displayName: 'Receptions Per TD',
								apiName: 'pro_receptions_per_touchdown',
								alignment: 'left'
							},
							{
								displayName: 'Receiving Yards Per TD',
								apiName: 'pro_receiving_yards_per_touchdown',
								alignment: 'left'
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div className="flex flex-wrap">
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[1].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_receptions_per_game}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[2].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_receiving_yards_per_game}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[3].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_receiving_touchdowns_per_game}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[4].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.pro_receptions_per_touchdown}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '175px' }}
									>
										{labels[5].displayName}
									</div>
									<div className="w-1/2">
										{row.pro_receiving_yards_per_touchdown}
									</div>
								</div>
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={props.data}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					columns={[
						{
							displayName: 'Category',
							apiName: 'category',
							alignment: 'left'
						},
						{
							displayName: 'Receptions Per Game',
							apiName: 'pro_receptions_per_game',
							alignment: 'left'
						},
						{
							displayName: 'Receiving Yards Per Game',
							apiName: 'pro_receiving_yards_per_game',
							alignment: 'left'
						},
						{
							displayName: 'Receiving TDs Per Game',
							apiName: 'pro_receiving_touchdowns_per_game',
							alignment: 'left'
						},
						{
							displayName: 'Receptions Per TD',
							apiName: 'pro_receptions_per_touchdown',
							alignment: 'left'
						},
						{
							displayName: 'Receiving Yards Per TD',
							apiName: 'pro_receiving_yards_per_touchdown',
							alignment: 'left'
						}
					]}
					rowRenderer={(row) => {
						return (
							<Fragment>
								<tr key={row.id} className="bg-white border-t text-gray-800">
									<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10">
										2024
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_receptions,
											row.current_year_pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_receiving_yards,
											row.current_year_pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_receiving_touchdowns,
											row.current_year_pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_receptions,
											row.current_year_pro_receiving_touchdowns
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.current_year_pro_receiving_yards,
											row.current_year_pro_receiving_touchdowns
										)}
									</td>
								</tr>
								<tr key={row.id} className="bg-white border-t text-gray-800">
									<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10">
										Career
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(row.pro_receptions, row.pro_games_played)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_receiving_yards,
											row.pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_receiving_touchdowns,
											row.pro_games_played
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_receptions,
											row.pro_receiving_touchdowns
										)}
									</td>
									<td className="pl-8 py-3 text-left">
										{safeDivideHelper(
											row.pro_receiving_yards,
											row.pro_receiving_touchdowns
										)}
									</td>
								</tr>
							</Fragment>
						);
					}}
				/>
			</div>
		</div>
	);
}
