import React from 'react';
import { Link } from 'react-router-dom';
import AdvancedDataTable from '../../../../DataTables/AdvancedDataTable';
import MobileDataTableCard from '../../../../DataTables/MobileDataTableCard';
import getFormattedNumberWithComma from '../../../../../utils/getFormattedNumberWithComma';

const QuarterbacksCollegeBasicStatsTable = ({
	players,
	sortBy,
	sortOrder,
	handleSortOrderChange
}) => {
	return (
		<div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={players}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Player',
								apiName: 'player',
								alignment: 'left'
							},
							{
								displayName: 'College',
								apiName: 'college',
								alignment: 'left'
							},
							{
								displayName: 'Games Played',
								apiName: 'college_games_played',
								alignment: 'left'
							},
							{
								displayName: 'Completions',
								apiName: 'college_pass_completions',
								alignment: 'left'
							},
							{
								displayName: 'Attempts',
								apiName: 'college_pass_attempts',
								alignment: 'left'
							},
							{
								displayName: 'Comp %',
								apiName: 'college_pass_completion_percentage',
								alignment: 'left'
							},
							{
								displayName: 'Passing Yards',
								apiName: 'college_passing_yards',
								alignment: 'left'
							},
							{
								displayName: 'Passing TDs',
								apiName: 'college_passing_touchdowns',
								alignment: 'left'
							},
							{
								displayName: 'INTs',
								apiName: 'college_interceptions',
								alignment: 'left'
							},
							{
								displayName: 'Rush Attempts',
								apiName: 'college_rush_attempts',
								alignment: 'left'
							},
							{
								displayName: 'Rushing Yards',
								apiName: 'college_rushing_yards',
								alignment: 'left'
							},
							{
								displayName: 'Rushing TDs',
								apiName: 'college_rushing_touchdowns',
								alignment: 'left'
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - QuarterbacksCollegeBasicStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div>
									<Link
										to={`/players/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:text-blue-700"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</div>
								<div className="mt-4 font-semibold">
									{labels[1].displayName}
								</div>
								<div>{row.college}</div>
								<div className="mt-4 font-semibold">
									{labels[2].displayName}
								</div>
								<div>{row.college_games_played}</div>
								<div className="mt-4 font-semibold">
									{labels[3].displayName}
								</div>
								<div>
									{getFormattedNumberWithComma(row.college_pass_completions)}
								</div>
								<div className="mt-4 font-semibold">
									{labels[4].displayName}
								</div>
								<div>
									{getFormattedNumberWithComma(row.college_pass_attempts)}
								</div>

								<div className="mt-4 font-semibold">
									{labels[5].displayName}
								</div>
								<div>{row.college_pass_completion_percentage}</div>

								<div className="mt-4 font-semibold">
									{labels[6].displayName}
								</div>
								<div>
									{getFormattedNumberWithComma(row.college_passing_yards)}
								</div>

								<div className="mt-4 font-semibold">
									{labels[7].displayName}
								</div>
								<div>{row.college_passing_touchdowns}</div>

								<div className="mt-4 font-semibold">
									{labels[8].displayName}
								</div>
								<div>{row.college_interceptions}</div>

								<div className="mt-4 font-semibold">
									{labels[9].displayName}
								</div>
								<div>
									{getFormattedNumberWithComma(row.college_rush_attempts)}
								</div>
								<div className="mt-4 font-semibold">
									{labels[10].displayName}
								</div>
								<div>
									{getFormattedNumberWithComma(row.college_rushing_yards)}
								</div>

								<div className="mt-4 font-semibold">
									{labels[11].displayName}
								</div>
								<div>{row.college_rushing_touchdowns}</div>
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={players}
					style="text-gray-800 border-b border-gray-300 font-semibold"
					sortOrder={sortOrder}
					sortColumn={sortBy ? sortBy : ''}
					onChangeSortOrder={handleSortOrderChange}
					sortingEnabled={true}
					columns={[
						{
							displayName: 'Player',
							apiName: 'player',
							alignment: 'left'
						},
						{
							displayName: 'College',
							apiName: 'college',
							alignment: 'left'
						},
						{
							displayName: 'Games Played',
							apiName: 'college_games_played',
							alignment: 'left'
						},
						{
							displayName: 'Completions',
							apiName: 'college_pass_completions',
							alignment: 'left'
						},
						{
							displayName: 'Attempts',
							apiName: 'college_pass_attempts',
							alignment: 'left'
						},
						{
							displayName: 'Comp %',
							apiName: 'college_pass_completion_percentage',
							alignment: 'left'
						},
						{
							displayName: 'Passing Yards',
							apiName: 'college_passing_yards',
							alignment: 'left'
						},
						{
							displayName: 'Passing TDs',
							apiName: 'college_passing_touchdowns',
							alignment: 'left'
						},
						{
							displayName: 'INTs',
							apiName: 'college_interceptions',
							alignment: 'left'
						},
						{
							displayName: 'Rush Attempts',
							apiName: 'college_rush_attempts',
							alignment: 'left'
						},
						{
							displayName: 'Rushing Yards',
							apiName: 'college_rushing_yards',
							alignment: 'left'
						},
						{
							displayName: 'Rushing TDs',
							apiName: 'college_rushing_touchdowns',
							alignment: 'left'
						}
					]}
					rowRenderer={(row) => {
						return (
							<tr
								className="bg-white pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60 border-t border-gray-200"
								key={`${row.first_name} ${row.last_name} - QuarterbacksCollegeBasicStatsTable - AdvancedDataTable`}
							>
								<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60 border-t border-gray-200">
									<Link
										to={`/players/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:text-blue-700"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</td>
								<td className="pl-8 py-3 text-left">{row.college}</td>
								<td className="pl-8 py-3 text-left">
									{row.college_games_played}
								</td>
								<td className="pl-8 py-3 text-left">
									{getFormattedNumberWithComma(row.college_pass_completions)}
								</td>
								<td className="pl-8 py-3 text-left">
									{getFormattedNumberWithComma(row.college_pass_attempts)}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_pass_completion_percentage}
								</td>
								<td className="pl-8 py-3 text-left">
									{getFormattedNumberWithComma(row.college_passing_yards)}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_passing_touchdowns}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_interceptions}
								</td>
								<td className="pl-8 py-3 text-left">
									{getFormattedNumberWithComma(row.college_rush_attempts)}
								</td>
								<td className="pl-8 py-3 text-left">
									{getFormattedNumberWithComma(row.college_rushing_yards)}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_rushing_touchdowns}
								</td>
							</tr>
						);
					}}
				/>
			</div>
		</div>
	);
};

export default QuarterbacksCollegeBasicStatsTable;
