import React from 'react';
import { Fragment } from 'react';

const ComparePlayerPassingStats = ({
	selectedPlayers,
	isProspectProfileCard
}) => {
	return (
		<Fragment>
			{/* Completions Per Game */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Completions Per Game
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`completions-${index}`} className="px-6 py-4">
						{isProspectProfileCard ? (
							player.college_pass_completions_per_game || '-'
						) : (
							player.pro_pass_completions_per_game || '-'
						)}
					</td>
				))}
			</tr>
			{/* Attempts Per Game */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Attempts Per Game
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`attempts-${index}`} className="px-6 py-4">
						{isProspectProfileCard ? (
							player.college_pass_attempts_per_game || '-'
						) : (
							player.pro_pass_attempts_per_game || '-'
						)}
					</td>
				))}
			</tr>
			{/* Yards Per Game */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">Yards Per Game</td>
				{selectedPlayers.map((player, index) => (
					<td key={`yards-${index}`} className="px-6 py-4">
						{isProspectProfileCard ? (
							player.college_passing_yards_per_game || '-'
						) : (
							player.pro_passing_yards_per_game || '-'
						)}
					</td>
				))}
			</tr>
			{/* Passing TDs Per Game */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Passing TDs Per Game
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`tds-${index}`} className="px-6 py-4">
						{isProspectProfileCard ? (
							player.college_passing_touchdowns_per_game || '-'
						) : (
							player.pro_passing_touchdowns_per_game || '-'
						)}
					</td>
				))}
			</tr>
			{/* INTs Per Game */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">INTs Per Game</td>
				{selectedPlayers.map((player, index) => (
					<td key={`ints-${index}`} className="px-6 py-4">
						{isProspectProfileCard ? (
							player.college_interceptions_per_game || '-'
						) : (
							player.pro_interceptions_per_game || '-'
						)}
					</td>
				))}
			</tr>
		</Fragment>
	);
};

export default ComparePlayerPassingStats;
