import React, { Fragment } from 'react';
import VideoCarousel from '../../../VideoCarousel/VideoCarousel';

const ComparePlayerTape = ({ selectedPlayers }) => {
	console.log('selectedPlayers', selectedPlayers);
	return (
		<Fragment>
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">Tape</td>
				{selectedPlayers.map((player, index) => {
					// Create Video Array for each player
					const videos = player
						? [
								player.video_url_1,
								player.video_url_2,
								player.video_url_3
							].filter((url) => url)
						: [];

					return (
						<td key={index} className="px-6 whitespace-nowrap">
							<VideoCarousel videos={videos} />
						</td>
					);
				})}
			</tr>
		</Fragment>
	);
};

export default ComparePlayerTape;
